import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {CheckOutContainerAsync} from "../../actions/containerActions";
import ReactSelect from "react-select";
import AppStateModel from "../../models/appStateModel";
import useSound from "use-sound";
//@ts-ignore
import InvalidDestination from "../../res/InvalidDestination.mp3";

type Props = {
    onHide: () => void
    refreshData: () => void
}
const CheckOutContainerModal: React.FC<Props> = ({ onHide, refreshData }) => {

    const d = useDispatch()
    const [containerId, setContainerId] = useState("")
    const profile = useSelector((state: AppStateModel) => state.Profile)
    const [stationId, setStationId] = useState<string | undefined>(profile.stationId ?? "")
    const [playFail] = useSound(InvalidDestination);

    const stationsOptions = useSelector(
      (state: AppStateModel) => state.Meta?.stations
    );
    const stations = (() => {
        if (profile.stationId) {
            return [stationsOptions?.find((x) => x.value == profile.stationId)!]
        }
        return stationsOptions
    })()

    const onSubmit = () => {
        d(CheckOutContainerAsync(Number(containerId), Number(stationId), () => {
            refreshData();
            onHide()
        }, () => playFail()))
    }

    return <Modal
      show={true}
      onHide={() => onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                {'Check Out Container'}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Row>
                    <div style={{ width: '40%' }}>
                        <label>Container Id</label>
                        <Form.Control
                          placeholder="Container Id"
                          value={containerId}
                          onChange={(e: any) => setContainerId(e.target.value)}
                          autoFocus={true}
                        />
                        <ReactSelect
                          className={"mt-3"}
                          placeholder="Select station.."
                          value={stations?.find(i => i.value == stationId)}
                          onChange={(v) => setStationId(v?.value)}
                          options={stations as any}
                          isDisabled={profile.stationId != null}
                        />
                    </div>

                </Form.Row>

                <Form.Row>
                    <Col>
                        <div
                          style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                          }}
                        >
                            <Button size="lg" onClick={onSubmit}
                                    variant="light">
                                {'Submit'}
                            </Button>
                        </div>
                    </Col>
                </Form.Row>
            </Form>
        </Modal.Body>
    </Modal>
}
export default CheckOutContainerModal

import React, {useCallback, useEffect, useState} from 'react';
import LookupModel from "../../../models/lookupModel";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../actions/common";
import {Button, Card, Col, Row} from "react-bootstrap";
import Select from "react-select";
import AppStateModel from "../../../models/appStateModel";
import DynamicDataTable from "../../../components/dynamicDataTable";
import {GetMemberMultipleCodData} from "../../../api/data";

const MultipleCouriers = () => {
  const dispatch = useDispatch()
  const members = useSelector((state: AppStateModel) => state.Meta?.employees);
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.shipments);
  const [data, setData] = useState<any>()
  const [multipleCouriers, setMultipleCouriers] = useState<LookupModel[]>([])

  const refreshData = useCallback(async (isExport?: boolean, exportAll?: boolean) => {
    dispatch(setLoading(true))
    const res = await GetMemberMultipleCodData({
      exportToExcel: isExport,
      exportAll: exportAll,
      couriersIds: multipleCouriers.map(x => x.value),
    })

    if (res.succeeded) {
      if (isExport && res.data.location) {
        window.open(res.data.location, "_blank", "noreferrer");
      } else {
        setData(res.data)
      }
    }
    dispatch(setLoading(false))
  }, [multipleCouriers])
  const onClear = () => {
    setMultipleCouriers([])

  }
  useEffect(() => {
    refreshData(false, (multipleCouriers.length == 0)).then()
  }, [multipleCouriers, refreshData])


  return (
    <div style={{ margin: '10px 40px 0px' }}>
      <Card>
        <Card.Header>
          <Row>
            <Col lg={2} className="px-md-4">

              <Select
                isMulti
                isClearable={false}
                value={multipleCouriers}
                key={`selectedStatuses${multipleCouriers}`}
                className="basic-multi-select"
                escapeClearsValue={true}
                options={members}
                placeholder={content.model.courierId}
                onChange={(selectedOptions) => {
                  let customersModel: LookupModel[] = [];
                  if (Array.isArray(selectedOptions)) {
                    selectedOptions?.forEach((element: any) => {
                      customersModel.push({
                        value: element.value,
                        label: element.label,
                      });
                    });
                  }
                  setMultipleCouriers(customersModel);
                }}
              />
            </Col>

            <div className="d-flex justify-content-end w-100 px-2">
              <div className="p-2"/>
              <Button
                onClick={() => refreshData(false, true)}>
                Refresh
              </Button>
              <div className="p-2"/>
              <Button disabled={multipleCouriers.length == 0}
                      onClick={() => refreshData(true, false)}>
                Export
              </Button>
              <div className="p-2"/>

              <Button
                onClick={() => refreshData(true, true)}>
                Export all
              </Button>
              <div className="p-2"/>

              <Button variant={"danger"}
                      onClick={onClear}>Clear</Button>
            </div>
          </Row>
        </Card.Header>
        <Card.Body>
          <DynamicDataTable data={{
            matches: data?.data ?? [],
            total: data?.count
          }}/>
        </Card.Body>
      </Card>
    </div>
  )
}

export default MultipleCouriers;

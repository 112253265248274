export const SET_ROUTES = "SET_ROUTES"
export const SET_LOCALE = "SET_LOCALE";
export const SET_JOURNEYS = "SET_JOURNEYS"
export const SET_AMS_CITIES = "SET_AMS_CITIES"

export const SET_LOADING = "SET_IS_LOADING";

export const SET_SHIPMENTS_CRITERIA = "SET_SHIPMENTS_CRITERIA";

export const SET_SHIPMENTS = "SET_SHIPMENTS";


export const SET_DRAFTS_CRITERIA = "SET_DRAFTS_CRITERIA";

export const SET_DRAFTS = "SET_DRAFTS";

export const SET_STATIONS = "SET_STATIONS";

export const SET_STATIONS_CRITERIA = "SET_STATIONS_CRITERIA";

export const SET_CITIES = "SET_CITIES";

export const SET_CITIES_CRITERIA = "SET_CITIES_CRITERIA";
export const SET_AMS_CRITERIA = "SET_AMS_CRITERIA";

export const SET_SELECTED_SHIPMENT = "SET_SELECTED_SHIPMENT";

export const UNAUTHENTICATE = "UNAUTHENTICATE";

export const AUTHENTICATION_SUCCEEDED = "AUTHENTICATION_SUCCEEDED";

export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";

export const MEMBER_INFO_RETRIEVED = "MEMBER_INFO_RETRIEVED";

export const SET_META = "SET_META";
export const SET_ALERT = "SET_ALERT";
export const SET_COURIER_RUNSHEET = "SET_COURIER_RUNSHEET";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMERS_CRITERIA = "SET_CUSTOMERS_CRITERIA";
export const SET_MEMBERS = "SET_MEMBERS";
export const SET_MEMBERS_CRITERIA = "SET_MEMBERS_CRITERIA";
export const SET_CONTAINERS = "SET_CONTAINERS";
export const SET_CONTAINERS_CRITERIA = "SET_CONTAINERS_CRITERIA";
export const SET_CITY_TIERS ="SET_CITY_TIERS "
export const SET_OTP = "SET_OTP";
export const SET_PARTNER = "SET_PARTNER";
export const SET_OTP_CRITERIA = "SET_OTP_CRITERIA";
export const SET_PARTNER_CRITERIA = "SET_PARTNER_CRITERIA";
export const SET_NOTIFICATONS = "SET_NOTIFICATONS";
export const SET_NOTIFICATONS_REQUEST = "SET_NOTIFICATONS_REQUEST";
export const SET_CUSTOMER_NOTIFICATONS = 'SET_CUSTOMER_NOTIFICATONS';


import React, {useState} from "react";
import {PartyModel} from "../../../models/common/commonModels";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CreateEditModal from "../../ams/components/createEditContactModal";

type Props = {
    consignee: PartyModel
    shipmentId: string | undefined
}
const AddressAMSPortal: React.FC<Props> = ({consignee, shipmentId}) => {

    const [openModal, setOpenModal] = useState<"NONE" | "EDIT">("NONE")
    const getAmsAddress = async () => {
        setOpenModal("EDIT")

    }
    return <div>
        {
            openModal === "EDIT" &&
            <CreateEditModal
                onClose={() => setOpenModal("NONE")}
                // mode={"EDIT"}
                shipmentId={shipmentId}
                contactRef={consignee.contact?.phone}
                // refreshData={getAmsAddress}
            />
        }
        <Col>
            {/*<Row>*/}
            {/*    xx*/}
            {/*    <Col>*/}
            {/*        {*/}
            {/*            JSON.stringify(consignee.address)*/}
            {/*        }*/}
            {/*    </Col>*/}

            {/*</Row>*/}
            {/*x*/}
            {/*{ consignee.address?.amsReferenceId}*/}
            {/*x*/}
            {
                consignee.address?.amsReferenceId ? <Row>
                    Has ams address
                </Row> : <Row>
                    -
                </Row>
            }

            <Row>
                <Button variant={"outline-primary"} size={"sm"} onClick={() => getAmsAddress()}>
                    AMS
                </Button>
            </Row>
        </Col>
    </div>
}
export default AddressAMSPortal
import {CreateJourneyModel, CreateRouteModel} from "../models/routes";
import {ApiResponse} from "@simplify9/simplyapiclient";
import {client} from "./index";


export const CreateRoute = async (data: CreateRouteModel): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`Routes/`, data);
}
export const SearchRoutes = async (): Promise<ApiResponse> => {
    return await client.SimplyGetAsync(`Routes?limit=100`);
}

export const SearchJourneys = async (): Promise<ApiResponse> => {
    return await client.SimplyGetAsync(`Journeys?limit=100`);
}

export const CreateJourney = async (data: CreateJourneyModel): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`Journeys/`, data);
}

export const DeactivateJourney = async (id: number): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`Journeys/${id}/Deactivate`, {});
}

export const DeactivateRoute = async (id: number): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`Routes/${id}/Deactivate`, {});
}

export const UpdateRoute = async (data: CreateRouteModel): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`Routes/${data.id}`, data);
}

export const UpdateJourney = async (data: CreateJourneyModel): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`Journeys/${data.id}`, data);
}





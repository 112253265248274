import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ScanListWithScan from "../shipments/components/shipmentListWithScan";
import ShipmentModel from "../../models/shipmentModel";
import {useDispatch, useSelector} from "react-redux";
import {CreateContainerAsync} from "../../actions/containerActions";
import Select from "react-select";
import AppStateModel from "../../models/appStateModel";
import {SearchJourneysAsync} from "../../actions/routesActions";
import {ValidateAddContainment} from "../../api/data";

interface IProps {
    onHide: () => any;
}

const CreateContainerModel: React.FC<IProps> = ({ onHide }) => {

    const d = useDispatch()
    const [shipments, setShipments] = useState<ShipmentModel[]>([])
    const [transferTo, setTransferTo] = useState(null)
    const journeys = useSelector((i: AppStateModel) => i.Journeys)
    useEffect(() => {
        d(SearchJourneysAsync())
    }, [])
    const onSubmit = () => {
        d(CreateContainerAsync(shipments.map(i => i.id), Number(transferTo), onHide))
    }
    const getSelectedJourney = () => {
        const j = journeys?.matches?.find(i => (i.id as unknown as number) == transferTo)
        if (!j)
            return undefined
        return {
            value: j.id,
            label: j.name
        }
    }

    const validateContainment = async (props: ShipmentModel) => {
        const res = await ValidateAddContainment(Number(transferTo), [props.id])
        return res.succeeded
    }
    return <Modal
      show={true}
      onHide={() => onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                {'Create Container'}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Row>
                    <Col className={"mb-3"}>
                        <Select
                          key={`selectedStation__${transferTo}`}
                          value={getSelectedJourney()}
                          options={journeys.matches?.map(i => ({
                              value: i.id,
                              label: i.name
                          })) as any ?? []}
                          isClearable
                          placeholder={"Transfer To"}
                          onChange={(selectedOption: any) => {
                              if (Array.isArray(selectedOption) || !selectedOption) {
                                  throw new Error(
                                    "Unexpected type passed to ReactSelect onChange handler"
                                  );
                              }
                              setTransferTo(selectedOption.value)
                          }}
                        />
                    </Col>
                </Form.Row>

                {
                  transferTo && <Form.Row>
                    <Col>
                      <ScanListWithScan
                          setShipments={setShipments}
                          validate={validateContainment}
                          allowedStatuses={['20']}
                          customValidateError={"Invalid destination or source"}
                      />
                    </Col>

                  </Form.Row>
                }


                <Form.Row>

                    <Col>
                        <div
                          style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                          }}
                        >
                            <Button size="lg" onClick={onSubmit}
                                    disabled={shipments.length === 0 || !transferTo}
                                    >
                                {'Submit'}
                            </Button>
                        </div>
                    </Col>
                </Form.Row>
            </Form>
        </Modal.Body>
    </Modal>

}
export default CreateContainerModel

export const deleteAllCookies = () => {
	document.cookie.split(";").forEach(function (c) {
		document.cookie = c
			.replace(/^ +/, "")
			.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
	});
};

const accessTokenName = "access_token";
const refreshTokenName = "refresh_token";
const tenantName = "tenant";

export const setTenantToken = (tenant: 'ksa' | 'jor') => {
	document.cookie = tenantName + "=" + tenant;
};

export const getTenantToken = () => {
	let name = tenantName + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};

export const setAccessToken = (token: string) => {
	document.cookie = accessTokenName + "=" + token;
};

export const setRefreshToken = (token: string) => {
	document.cookie = refreshTokenName + "=" + token;
};

export const getAccessToken = () => {
	const name = accessTokenName + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	//return  decodeURIComponent(ca[0]?.split("=")[1]);

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return null;
};

export const getRefreshToken = () => {
	const name = refreshTokenName + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};

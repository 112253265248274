import * as React from "react";
import {Route} from "react-router-dom";

import history from "../history";
import AppStateModel from "../models/appStateModel";

import {useSelector} from "react-redux";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

const LoggedOutRoute = ({
                            component: Component,
                            ...otherProps
                        }: IProps) => {
    const isAuthenticated = useSelector(
        (state: AppStateModel) => state.Auth.isAuthenticated
    );
    const isProfileComplete = useSelector(
        (state: AppStateModel) => state.Profile.isComplete
    );


    if (isAuthenticated && isProfileComplete) {
        history.push("/");
    }
    return (
        <>
            <Route
                render={(otherProps) => (
                    <>
                        <Component {...otherProps} />
                    </>
                )}
            />
        </>
    );
};

export default React.memo(LoggedOutRoute);

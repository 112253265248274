import React, {FunctionComponent} from "react";
import LookupModel from "../../../models/lookupModel";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Row from "react-bootstrap/Row";

interface IProps{

    value:string|undefined
    onChange:(i:string)=>void
}
const DynamicSort:FunctionComponent<IProps>=(props:IProps):JSX.Element=>{

    const sortOptions: LookupModel[] = [
        {
            value: "CreatedOn",
            label: "Creation Date"
        },
        {
            value: "40",
            label: "Delivery Date"
        },
        {
            value: "101",
            label: "COD Collection Date"
        },
        {
            value: "Payment.Value.Amount",
            label: "COD Amount"
        },
    ]


    return (<Row className={"w-100"}>
        <Col>
            <h4>{"Sort By"}</h4>
            <Select
                value={sortOptions?.find(x => x.value == props.value)}
                className="basic-multi-select"
                escapeClearsValue={true}
                options={sortOptions}
                isMulti={false}
                onChange={(selectedOption) => {
                    props.onChange(selectedOption?.value ??"")
                }}
            />
        </Col>

    </Row>)
}
export default DynamicSort

import React from 'react';
import {Button, Modal} from "react-bootstrap";

interface IProps {
    handleClose: () => void;
    show: boolean
    onSubmit: () => void;

}

const SubmitModal: React.FC<IProps> = (props) => {


    return (
        <>

            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to proceed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.onSubmit()
                        props.handleClose()
                    }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SubmitModal;

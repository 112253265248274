import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import { useSelector } from "react-redux";
import ReactSelect from 'react-select';

interface IProps {
	visible: boolean;
    onHide: () => any;
    onDone: (courierId: string) => any
    options?:any[]
}
const Component = (props: IProps) => {
	const content = useSelector(
		(state: AppStateModel) => state.AppContent.shipments.selectCourier
	);
	const [courier, setCourier] = React.useState<any>(null)

	return (
		<Modal
			show={props.visible}
			onHide={() => props.onHide()}
			dialogClassName="modal-90w"
			aria-labelledby="example-custom-modal-styling-title"
		>
			<Modal.Header closeButton>
				<Modal.Title id="example-custom-modal-styling-title">
					{content.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.options && <ReactSelect value={courier} onChange={(v) => setCourier(v)} options={props.options} />}
			</Modal.Body>
			<Modal.Footer>
				<Button
					disabled={courier === null}
					onClick={()=> props.onDone(courier!.value!)}
				>
					{content.button}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default Component;

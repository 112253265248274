import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/esm/Modal";
import {useDispatch, useSelector} from 'react-redux';
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import {GetCustomer, UploadFile} from "../../../api/data";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import AppStateModel from '../../../models/appStateModel';
import {ImportFromExcelAsync} from '../../../actions/shipmentsActions';
import {Col, Form} from "react-bootstrap";
import SelectInput from "../../components/SelectInput";

interface IProps {
    visible: boolean;
    onHide: () => any;
}

const Component = (props: IProps) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [fileUploaded, setFileUploaded] = React.useState('');
    const [pickupStoreId, setPickupStoreId] = useState<string>("")
    const [singlePdf, setSinglePdf] = useState(true);
    const [printShipments, setPrintShipments] = useState(true);
    const [
        selectedCustomerStoresOptions,
        setSelectedCustomerStoresOptions,
    ] = useState([]);
    const profile = useSelector((state: AppStateModel) => state.Profile)
    const dispatch = useDispatch();
    const handleFile = async (event: any) => {
        setIsLoading(true);
        event.preventDefault();
        let files = event.target.files;

        if (files && files.length > 0) {
            let file = files[0];

            let res = await UploadFile(file.name, file);
            if (res.succeeded) {
                setFileUploaded(res.data.location);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (profile.jobRole === "CustomerAdmin") {
            getStoresOptions(profile.customerId).then();
        } else {
            getStoresOptions(profile.id).then()
        }
    }, []);

    const getStoresOptions = async (id: any) => {
        if (id) {
            let options: any = [];
            let res = await GetCustomer(id);
            if (res.succeeded && res.data) {
                res.data.stores?.forEach((store: any) => {
                    options.push({value: store.id, label: store.name});
                });
            }
            setSelectedCustomerStoresOptions(options);
        }
    };
    const submit = async () => {
        dispatch(ImportFromExcelAsync(fileUploaded, pickupStoreId, props.onHide, singlePdf, printShipments));
    }
    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Upload excel filex
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <>
                        <Row>
                            <Col>
                                Download{'  '}
                                <a
                                    className="px-2 "
                                    target={"_blank"}
                                    href={
                                        profile.currentTenant?.countryCode == "SA"
                                            ? "https://kwickbox.ams3.digitaloceanspaces.com/templates/UploadTemplateKSAv2.xlsx"
                                            : "https://kwickbox.ams3.digitaloceanspaces.com/templates/UploadTemplateJORv2.xlsx"
                                    }
                                >
                                    Template
                                </a>
                            </Col>

                        </Row>
                        <Row className="pt-3 align-items-center">
                            <Col className=" px-2">
                                <FormGroup>
                                    <label>File:{' '}</label>
                                    <FormControl name="file" type="file" onChange={handleFile}/>
                                </FormGroup></Col>
                            <Col className="justify-content-center align-items-center d-flex flex-row px-2">
                                <Row className="justify-content-center  align-items-center">
                                    <Form.Check size={50} className="px-1" type="checkbox" checked={singlePdf}
                                                onChange={(e) => setSinglePdf(e.target.checked)}/>
                                    <h5>{' Single PDF File '}</h5>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-3 align-items-center">
                            <Col className="justify-content-center align-items-center d-flex flex-row px-2">
                            </Col>
                            <Col className="justify-content-center align-items-center d-flex flex-row px-2">
                                <Row className="justify-content-center align-items-center">
                                    <Form.Check size={50} className="px-1" type="checkbox" checked={printShipments}
                                                onChange={(e) => setPrintShipments(e.target.checked)}/>
                                    <h5>{'Print Shipments'}</h5>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SelectInput
                                    value={pickupStoreId}
                                    onChange={(id) => setPickupStoreId(id)}
                                    options={selectedCustomerStoresOptions}
                                    placeholder={"Pickup Store"}

                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button disabled={(fileUploaded === "") || (pickupStoreId === "")} onClick={submit}>
                                    Submit
                                </Button>
                            </Col>

                        </Row>
                    </>
                    {isLoading && (
                        <>
                            <Spinner animation="border"/>
                        </>
                    )}
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default Component;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import Button from "react-bootstrap/Button";
import {CityTiersModel} from "../../../models/cityTiersModel ";
import {CreateCityTierAsync} from "../../../actions/cityTiersAction";

interface IProps {
  visible: boolean;
  onHide: () => any;
  submitButton: string;
  title: string;
}
const Component = (props: IProps) => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.cityTier
  );

  const [newTier, setNewTier] = useState<CityTiersModel>({name:'',description:''});
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const onSubmit = () => {
    let validations: string[] = [];

    !newTier.name && newTier.name == null && validations.push("name");
    !newTier.description && newTier.description == null && validations.push("description");
    if (validations.length > 0) {
      setValidationErrors(validations);
    } else {

      dispatch(CreateCityTierAsync(newTier, ()=>props.onHide()));
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <TextInput
                value={newTier.name!}
                onChange={(val) => setNewTier({ ...newTier, name: val })}
                placeholder={content.namePlaceHolder}
                required={true}
                invalidMessage={content.nameRequiredMessage}
                isInvalid={
                  validationErrors.find((e) => e === "name") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <TextInput
                  value={newTier.description!}
                  onChange={(val) => setNewTier({ ...newTier, description: val })}
                  placeholder={content.descriptionPlaceholder}
                  required={true}
                  invalidMessage={content.nameRequiredMessage}
                  isInvalid={
                    validationErrors.find((e) => e === "description") ? true : false
                  }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={onSubmit} variant="light">
                  {props.submitButton}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

import {Button, Card, Col} from "react-bootstrap";
import {
    IActionsSearchModel,
    ISystemActionModel
} from "../../models/systemActions";
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState
} from "react";
import Row from "react-bootstrap/Row";
import Pager from "../components/Pager";
import {getSystemActionsAsync} from "../../api/data";
import Select from "react-select";
import {useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import CitiesSelect from "../components/CitiesSelect";
import DynamicDataTable from "../../components/dynamicDataTable";
import PagedModel from "../../models/pagedModel";
import Graph from "./graph";
import {Admin, CustomerAdmin, OpsManager, OpsSupervisor} from "../../models/jobRole";
import Authorized from "../../components/Authorized";

type GraphItem = { month: string, count: number }


const SystemActions: FunctionComponent = () => {

    const [data, setData] = useState<PagedModel<Array<ISystemActionModel>>>()
    const [graphData, setGraphData] = useState<GraphItem[]>()
    const [search, setSearch] = useState<IActionsSearchModel>({ pageNumber: 1 })
    const meta = useSelector((state: AppStateModel) => state.Meta);
    const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState<any>();
    const [live, setLive] = useState(false)

    useEffect(() => {
        refresh().then()
    }, [search])

    const refresh = useCallback(async () => {
        const res = await getSystemActionsAsync(search)
        if (res.succeeded) {
            setData({ total: res.data!.total, matches: res?.data!.matches })
            setGraphData(res.data.graphData)
        }
    }, [search])

    const jobRole = useSelector((state: AppStateModel) => state.Profile).jobRole;
    console.log(jobRole, 'jobRole')
    const exportToExcel = async () => {
        const res = await getSystemActionsAsync(search, true)
        if (res.succeeded) {
            window.open(res.data.location, "_blank")
        }
    }
    const onLiveView = () => {
        setSearch({ pageNumber: 1 })
        setLive(true)
        setInterval(async () => {
            if (live) {
                await refresh()

            }
        }, 7000)
    }
    const onClear = () => {
        setLive(false)
        setSearch({ pageNumber: 1 })

    }

    return (<div style={{ padding: "1vw" }}>

          {live &&
            <div style={{ position: "sticky", zIndex: 21, top: 81, right: 50 }}>
              <div style={{
                  backgroundColor: "#ec2029",
                  position: "sticky",

                  height: 33,
                  width: 121,
                  borderRadius: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
              }}>
                <h2 style={{ color: "white" }}>LIVE</h2>
              </div>
            </div>
          }
          <Card className={"shadow"}>
              <Card.Body>
                  <Card className={"shadow"}>
                      <Card.Body>
                          <Row
                            className={"p-3 border shadow rounded-lg mt-1 mx-1"}>
                              <Authorized
                                allowedRoles={[Admin.label, OpsManager.label, OpsSupervisor.label, CustomerAdmin.label]}>

                                  <Col md={2}>
                                      <div>
                                          <Select
                                            key={`_key_${search.actionType?.toString()}`}
                                            isMulti={false}
                                            value={search.actionType ? meta?.traceStatuses?.find(x => x.value == search.actionType) : undefined}
                                            className="basic-multi-select"
                                            escapeClearsValue={true}
                                            options={meta?.traceStatuses}
                                            placeholder={"Action Type"}
                                            onChange={(e) => {
                                                setSearch((search) => ({
                                                    ...search,
                                                    actionType: e?.value
                                                }))
                                            }}
                                          />
                                      </div>
                                  </Col>
                              </Authorized>
                              <Authorized
                                  allowedRoles={[Admin.label, OpsManager.label, OpsSupervisor.label]}>

                              <Col md={2}>
                                      <div>
                                          <Select
                                            key={`_key_${search.actionById?.toString()}`}
                                            isMulti={false}
                                            value={search.actionById ? meta?.employees?.find(x => x.value == search.actionById) : undefined}

                                            className="basic-multi-select"
                                            escapeClearsValue={true}
                                            options={meta?.employees}
                                            placeholder={"Action by"}
                                            onChange={(e) => {
                                                setSearch((search) => ({
                                                    ...search,
                                                    actionById: e?.value
                                                }))
                                            }}
                                          />
                                      </div>
                                  </Col>
                              </Authorized>
                              <Authorized
                                  allowedRoles={[Admin.label, OpsManager.label, OpsSupervisor.label]}>

                              <Col md={2}>
                                      <div>
                                          <Select
                                            key={`_key_${search.customerId?.toString()}`}
                                            isMulti={false}
                                            value={search.customerId ? meta?.customers?.find(x => x.value == search.customerId) : undefined}
                                            className="basic-multi-select"
                                            escapeClearsValue={true}
                                            options={meta?.customers}
                                            placeholder={"Customer"}
                                            onChange={(e) => {
                                                setSearch((search) => ({
                                                    ...search,
                                                    customerId: e?.value
                                                }))
                                            }}
                                          />
                                      </div>
                                  </Col>
                              </Authorized>
                              <Authorized
                                  allowedRoles={[Admin.label, OpsManager.label, OpsSupervisor.label, CustomerAdmin.label]}>

                              <Col md={2}>
                                      <div>
                                          <Select
                                            key={`selectedStation__${search.stationId}`}
                                            value={search.stationId ? meta?.stations?.find(x => x.value == search.stationId) : undefined}

                                            options={meta?.stations}
                                            isClearable
                                            placeholder={"Station"}
                                            onChange={(e) => {
                                                setSearch((search) => ({
                                                    ...search,
                                                    stationId: e?.value
                                                }))

                                            }}
                                          />
                                      </div>
                                  </Col>
                              </Authorized>
                              <Authorized
                                  allowedRoles={[Admin.label, OpsManager.label, OpsSupervisor.label, CustomerAdmin.label]}>

                              <Col lg={2}>
                                      <CitiesSelect
                                        value={String(search?.consigneeCity) ?? ""}
                                        onChange={(e: any) => {

                                            setSearch((search) => ({
                                                ...search,
                                                consigneeCity: e
                                            }))
                                        }}
                                        placeholder={"ConsigneeCity"}
                                      />
                                  </Col>
                              </Authorized>
                              <Authorized
                                  allowedRoles={[Admin.label, OpsManager.label, OpsSupervisor.label]}>

                              <Col lg={2}>
                                      <CitiesSelect
                                        value={String(search?.shipperCity) ?? ""}
                                        onChange={(e: any) => {
                                            setSearch((search) => ({
                                                ...search,
                                                shipperCity: e
                                            }))
                                        }}
                                        placeholder={"Shipper City"}
                                      />
                                  </Col>
                              </Authorized>
                              <Authorized
                                  allowedRoles={[Admin.label, OpsManager.label, OpsSupervisor.label]}>

                              <Col md={2}>
                                      <div>
                                          <Select
                                            key={`selectedStation__${search.isAutomatic}`}
                                            value={search.isAutomatic === undefined ? {
                                                label: "All",
                                                value: undefined
                                            } : search.isAutomatic ? {
                                                label: "Automatic",
                                                value: true

                                            } : {
                                                label: "Manual",
                                                value: false

                                            }}
                                            options={[
                                                {
                                                    label: "All",
                                                    value: undefined
                                                },
                                                {
                                                    label: "Automatic",
                                                    value: true

                                                },
                                                {
                                                    label: "Manual",
                                                    value: false
                                                },
                                            ]}
                                            isClearable
                                            placeholder={"Station"}
                                            onChange={(e) => {
                                                setSearch((search) => ({
                                                    ...search,
                                                    isAutomatic: e?.value
                                                }))

                                            }}
                                          />
                                      </div>
                                  </Col>
                              </Authorized>
                              <Col lg={2}>
                                  <div style={{
                                      height: 37,
                                      backgroundColor: "white",
                                      borderWidth: "1px",
                                      borderStyle: "solid",
                                      borderColor: "lightgrey",
                                      borderRadius: "3px"
                                  }}>
                                      <DateRangePicker
                                        noBorder={true}
                                        isOutsideRange={() => false}
                                        small
                                        isDayBlocked={() => false}
                                        minimumNights={0}
                                        startDatePlaceholderText="Action From"
                                        endDatePlaceholderText="Action To"
                                        startDate={
                                            search.from
                                              ? moment(search.from)
                                              : null
                                        }
                                        startDateId="123" // PropTypes.string.isRequired,
                                        endDate={
                                            search.to
                                              ? moment(search.to)
                                              : null
                                        }
                                        endDateId="321" // PropTypes.string.isRequired,
                                        onDatesChange={({
                                                            startDate,
                                                            endDate
                                                        }) => {
                                            setSearch((search) =>
                                              ({
                                                  ...search,
                                                  from: startDate?.toDate(),
                                                  to: endDate?.toDate()
                                              }))
                                        }}
                                        focusedInput={dateRangeFocusedInput}
                                        onFocusChange={(focusedInput) => {
                                            setDateRangeFocusedInput(focusedInput);
                                        }}
                                      />
                                  </div>
                              </Col>

                          </Row>


                          <Row>
                              <div
                                className={"d-flex flex-row  px-3 justify-content-between w-100 mx-4  p-3 border shadow rounded-lg mt-3"}>
                                  <div>
                                      <Button size={"lg"} variant={"danger"}
                                              className={"mx-2"}
                                              onClick={onClear}>Clear</Button>
                                      <Button size={"lg"} className={"mx-2"}
                                              onClick={exportToExcel}>Export</Button>
                                      <Button size={"lg"} className={"mx-2"}
                                              onClick={refresh}>Search</Button>

                                  </div>

                                  <div>
                                      <Button size={"lg"}
                                              variant={"outline-danger"}
                                              className={"mx-2 shadow"}
                                              onClick={onLiveView}>Live
                                          Mode</Button>

                                  </div>


                              </div>
                          </Row>
                      </Card.Body>
                  </Card>

                  <Card className={"shadow"}>
                      <Card.Body>
                          <div className="chart-area">
                              <Graph graphData={graphData}/>

                          </div>
                      </Card.Body>
                  </Card>
                  <Card className={"shadow"}>
                      <Card.Body>
                    <div>
                        <Row style={{marginInlineStart: '.25rem', gap: '.5rem'}}>
                            <Card.Title>Total: </Card.Title>
                            <Card.Title>{data?.total ?? 0}</Card.Title>
                        </Row>
                              <Row className={"p-2 shadow"}>
                                  <DynamicDataTable data={data}/>

                              </Row>

                    </div>
                          {data && data.total > 0 &&
                            <div
                              className={"mt-3"}
                              style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                              }}
                            >
                              <Pager
                                currentPageNumber={search.pageNumber}
                                numberOfPages={Math.ceil(
                                  data.total! / 20
                                )}
                                onChange={(e) => {
                                    setSearch((search) => ({
                                        ...search,
                                        pageNumber: e
                                    }))
                                }}
                              />
                            </div>
                          }
                      </Card.Body>
                  </Card>
              </Card.Body>

          </Card>

      </div>
    )


}

export default SystemActions

import React, { Fragment, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useDispatch, useSelector } from "react-redux";
import { LogOutAsync, SwitchTenantAsync } from "./actions/authActions";
import AppStateModel from "./models/appStateModel";
import { NavLink as RouterLink } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import Authorized from "./components/Authorized";
import ChangePasswordModal from './pages/members/components/changePassword'
import {
    Accountant,
    Admin,
    Cashier,
    CustomerAdmin,
    CustomerService,
    CustomerServiceManager,
    OpsManager,
    OpsOfficer,
    OpsSupervisor,
    Sales,
} from "./models/jobRole";
import { Badge } from "react-bootstrap";
import { SearchCustomerNotificationsAsync } from "./actions/notificationsActions";

const AppNav = () => {
    const content = useSelector((state: AppStateModel) => state.AppContent)
        .navigation;
    const profile = useSelector((state: AppStateModel) => state.Profile);
    const unreadNotifications = useSelector((state: AppStateModel) =>
        state.SearchCustomerNotifications)?.totalUnread

    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (CustomerAdmin.label == profile.jobRole)
            dispatch(SearchCustomerNotificationsAsync())
    }, [])

    return (
        <Navbar
            bg="light "
            className="bg-light justify-content-between"
            variant="light"
            expand="lg"
            // sticky="top"

            style={{
                padding: "0px 2vw 0px 2vw",
                //borderBottom: "0.5px #6d7882 solid",
            }}
        >
            <Navbar.Brand href="/">
                <img
                    src={profile.currentTenant?.theme?.logoUrl ?? "https://www.nicepng.com/png/detail/828-8281908_shipping-truck-png.png"}
                    alt="kwickbox" height="42" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Authorized
                        allowedRoles={[
                            Admin.label,
                            OpsManager.label,
                            OpsOfficer.label,
                            OpsSupervisor.label,
                        ]}
                    >
                        <RouterLink className="nav-link" exact={true}
                            to="/dashboard">
                            {content.dashboard}
                        </RouterLink>

                    </Authorized>
                    <Authorized all={true}>
                        <Fragment>


                            <RouterLink className="nav-link" exact={true}
                                to="/shipments">
                                {content.shipments}
                            </RouterLink>
                            <Authorized
                                allowedRoles={[Admin.label,
                                OpsManager.label,
                                OpsOfficer.label,
                                OpsSupervisor.label,
                                Accountant.label,
                                Cashier.label,
                                CustomerServiceManager.label,
                                CustomerService.label,
                                Sales.label]}>
                                <RouterLink className="nav-link" exact={true}
                                    to="/containers">
                                    {"Containers"}
                                </RouterLink>
                            </Authorized>
                            <Authorized
                                allowedRoles={[
                                    Admin.label,
                                    OpsManager.label,
                                    OpsOfficer.label,
                                    OpsSupervisor.label,
                                    Accountant.label,
                                    Cashier.label,
                                    CustomerServiceManager.label,
                                    CustomerService.label,
                                    Sales.label,
                                ]}>

                                <RouterLink className="nav-link" exact={true}
                                    to="/pickup-Dispatch">
                                    {"Pickup Dispatch"}
                                </RouterLink>
                            </Authorized>
                        </Fragment>
                    </Authorized>
                    <Authorized
                        allowedRoles={[
                            Admin.label,
                            OpsManager.label,
                            OpsOfficer.label,
                            OpsSupervisor.label,
                            Accountant.label,
                            Cashier.label,
                            CustomerServiceManager.label,
                            CustomerService.label,
                            Sales.label,
                        ]}
                    >
                        <RouterLink className="nav-link" exact={true} to="/draft">
                            {content.draft}
                        </RouterLink>
                    </Authorized>
                    <Authorized
                        allowedRoles={[
                            Admin.label,
                            OpsManager.label,
                            OpsOfficer.label,
                            OpsSupervisor.label,
                            Accountant.label,
                            Cashier.label,
                            CustomerServiceManager.label,
                            CustomerService.label,
                            Sales.label,
                        ]}
                    >
                        <RouterLink className="nav-link" exact={true}
                            to="/customers">
                            {content.customers}
                        </RouterLink>
                    </Authorized>
                    <Authorized
                        allowedRoles={[
                            Admin.label,
                            OpsManager.label,
                            OpsOfficer.label,
                            OpsSupervisor.label,
                            Accountant.label,
                            Cashier.label,
                            CustomerServiceManager.label,
                            CustomerService.label,
                            Sales.label,
                        ]}
                    >
                        <RouterLink className="nav-link" exact={true}
                            to="/members">
                            {content.members}
                        </RouterLink>
                    </Authorized>

                    <Authorized
                        allowedRoles={[
                            Admin.label,
                        ]}
                    >
                        <RouterLink className="nav-link" exact={true}
                            to="/stations">
                            {content.stations}
                        </RouterLink>

                    </Authorized>


                    <Authorized allowedRoles={[Admin.label, OpsManager.label]}>
                        <RouterLink className="nav-link" exact={true}
                            to="/reports">
                            {content.reports}
                        </RouterLink>

                    </Authorized>
                    <Authorized
                        allowedRoles={[Admin.label, OpsManager.label, CustomerAdmin.label, OpsSupervisor.label]}>

                        <RouterLink className="nav-link" exact={true}
                            to="/systemaction">
                            {"System Actions"}
                        </RouterLink>
                    </Authorized>
                    <Authorized allowedRoles={[Admin.label]}>
                        <RouterLink className="nav-link" exact={true}
                            to="/cities">
                            Cities
                        </RouterLink>
                    </Authorized>
                    <Authorized
                        allowedRoles={[
                            Admin.label,
                        ]}
                    >
                        <RouterLink className="nav-link" exact={true}
                            to="/cityTiers">
                            {content.cityTiers}
                        </RouterLink>

                    </Authorized>
                    <Authorized
                        allowedRoles={[
                            Admin.label,
                            OpsManager.label,
                            OpsOfficer.label,
                            OpsSupervisor.label,
                            Accountant.label,
                            Cashier.label,
                        ]}
                    >
                        <RouterLink className="nav-link" exact={true} to="/cod">
                            {"Cod"}
                        </RouterLink>
                        <RouterLink className="nav-link" exact={true}
                            to="/codReport">
                            {"Cod Report"}
                        </RouterLink>
                    </Authorized>
                    <Authorized allowedRoles={[
                        Admin.label,
                        OpsManager.label
                    ]}>
                        <RouterLink className="nav-link" exact={true}
                            to={'routes'}>
                            {'Routes'}
                        </RouterLink>
                    </Authorized>
                    <Authorized allowedRoles={[
                        Admin.label,
                        OpsManager.label
                    ]}>
                        <RouterLink className="nav-link" exact={true}
                            to={'ams'}>
                            {'Ams'}
                        </RouterLink>
                    </Authorized>
                    <Authorized allowedRoles={[
                        Admin.label
                    ]}>
                        <RouterLink className="nav-link" exact={true}
                            to={'otp'}>
                            {'Otp'}
                        </RouterLink>
                    </Authorized>
                    <Authorized allowedRoles={[
                        Admin.label
                    ]}>
                        <RouterLink className="nav-link" exact={true}
                                    to={'Partners'}>
                            {'Partners'}
                        </RouterLink>
                    </Authorized>
                    <Authorized allowedRoles={[
                        Admin.label
                    ]}>
                        <RouterLink className="nav-link" exact={true}
                            to={'notifications'}>
                            {'Notifications'}
                        </RouterLink>
                    </Authorized>
                    <Authorized allowedRoles={[
                        CustomerAdmin.label
                    ]}>
                        <RouterLink className="nav-link" exact={true}
                            to={'customerNotifications'}>
                            {'Notifications'} {unreadNotifications > 0 && <Badge style={{ backgroundColor: 'gray', color: 'white' }}>{unreadNotifications}</Badge>}
                        </RouterLink>
                    </Authorized>
                </Nav>
                <Nav.Item>
                    <DropdownButton
                        id="dropdown-basic-button"
                        title={
                            <>
                                {`${profile.name} (${profile.currentTenant?.name})`}
                                <span className={"px-1"}>
                                    {"-"}
                                </span>

                                {
                                    (profile?.balance?.cash?.amount ?? 0) +
                                    (profile?.balance?.cardReceiptWorth?.amount ?? 0)}
                                <span className={"px-1"}>
                                    {"SAR"}
                                </span>
                                <Image
                                    src="/avatar-placeholder.png"
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        marginLeft: 10
                                    }}
                                    roundedCircle
                                />
                            </>
                        }
                        variant="light"
                    >
                        <Dropdown.Header>{profile.jobRole}</Dropdown.Header>
                        <Dropdown.Divider />
                        {profile.tenants?.map((t) => (
                            <Dropdown.Item
                                disabled={profile.currentTenant?.id == t.id}
                                key={t.id}
                                onClick={() => dispatch(SwitchTenantAsync(t.id))}
                            >
                                {t.name}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => {
                            setShowUpdatePasswordModal(true)
                        }}>
                            {'Change password'}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => dispatch(LogOutAsync())}>
                            {content.logout}
                        </Dropdown.Item>
                    </DropdownButton>

                </Nav.Item>
                {showUpdatePasswordModal && <ChangePasswordModal
                    member={{ id: profile.id }}
                    visible={showUpdatePasswordModal}
                    onHide={() => {
                        setShowUpdatePasswordModal(false);
                    }}
                />}

            </Navbar.Collapse>
        </Navbar>
    );
};

export default React.memo(AppNav);

import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {CreateRouteModel} from "../../../models/routes";
import AppStateModel from "../../../models/appStateModel";
import {CreateRouteAsync, UpdateRouteAsync} from "../../../actions/routesActions";
import TextInput from "../../components/TextInput";

type Props = {
    onHide: () => void
    selectedRoute: CreateRouteModel
}
const UpdateRouteModal: React.FC<Props> = ({ onHide , selectedRoute}) => {

    const d = useDispatch()
    const [data, setData] = useState<CreateRouteModel>(selectedRoute)
    const stations = useSelector((state: AppStateModel) => state.Meta?.stations);
    const [validation, setValidation] = useState<{
        estimatedArrivalTime?: boolean;
        estimatedDepartureTime?: boolean;
    }>()
    const onChange = (value: string | number, key: keyof CreateRouteModel) => {
        setData((c) => ({ ...c, [key]: value }))
    }

    const onSubmit = () => {
        d(UpdateRouteAsync(data as CreateRouteModel, onHide))
    }

    useEffect(() => {
        if (Boolean(data.estimatedArrivalTime) && !data.estimatedArrivalTime?.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]$')) {
            setValidation((v) => ({ ...v, estimatedArrivalTime: true }))
        } else {
            setValidation((v) => ({ ...v, estimatedArrivalTime: false }))
        }
    }, [data.estimatedArrivalTime])
    useEffect(() => {
        if (Boolean(data.estimatedDepartureTime) && !data.estimatedDepartureTime?.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]$')) {
            setValidation((v) => ({ ...v, estimatedDepartureTime: true }))
        } else {
            setValidation((v) => ({ ...v, estimatedDepartureTime: false }))
        }
    }, [data.estimatedDepartureTime])

    return (<div>
        <Modal
            show={true}
            onHide={() => onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {"Update Route"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Select
                            key={`selectedStation__${data.sourceStationId}`}
                            options={stations as any ?? []}
                            value={stations?.find(i => (i.value as unknown as number) == data.sourceStationId)}
                            isClearable
                            placeholder={"Source Station"}
                            onChange={(selectedOption: any) => {
                                if (Array.isArray(selectedOption) || !selectedOption) {
                                    throw new Error(
                                        "Unexpected type passed to ReactSelect onChange handler"
                                    );
                                }
                                onChange(selectedOption.value, 'sourceStationId')

                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <Select
                            key={`selectedStation__${data.destinationStationId}`}
                            value={stations?.find(i => (i.value as unknown as number) == data.destinationStationId)}
                            options={stations as any ?? []}
                            isClearable
                            placeholder={"Destination Station"}
                            onChange={(selectedOption: any) => {
                                if (Array.isArray(selectedOption) || !selectedOption) {
                                    throw new Error(
                                        "Unexpected type passed to ReactSelect onChange handler"
                                    );
                                }
                                onChange(selectedOption.value, 'destinationStationId')

                            }}
                        />
                    </Col>
                    <Col md={6} className={"pt-3"}>
                        <TextInput
                            isInvalid={validation?.estimatedDepartureTime}
                            invalidMessage={"Not a valid time use 24-hour format"}
                            value={data.estimatedDepartureTime}
                            onChange={(e) => onChange(e, "estimatedDepartureTime")}
                            placeholder={"ETD"}
                        />
                    </Col>
                    <Col md={6} className={"pt-3"}>
                        <TextInput
                            invalidMessage={"Not a valid time use 24-hour format"}
                            isInvalid={validation?.estimatedArrivalTime}
                            value={data.estimatedArrivalTime}
                            onChange={(e) => onChange(e, "estimatedArrivalTime")}
                            placeholder={"ETA"}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={!Boolean(data.destinationStationId && data.sourceStationId)}
                    onClick={onSubmit}
                >
                    {"Submit"}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>)
}

export default UpdateRouteModal

import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import Select from "react-select";
import LookupModel from "../../../models/lookupModel";
import {DateRangePicker} from "react-dates";
import {GetCodData} from "../../../api/data";
import {reportResponse} from "../../../models/codReports";
import DynamicDataTable from "../../../components/dynamicDataTable";
import moment from "moment";
import {setLoading} from "../../../actions/common";



interface IProps {
    tab: string
    options: LookupModel | any
    placeHolder: string
}

const DynamicCodReportTab: React.FC<IProps> = (props) => {
    const [ReturnDateFromFocusedInput, setReturnDateFromFocusedInput] = useState<any>();
    const [selectedTab, setSelectedTab] = useState<LookupModel>();
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [data, setData] = useState<reportResponse>()
    const dispatch = useDispatch()
    const refreshData = async (isExport?: boolean, withTotal?: boolean) => {
        dispatch(setLoading(true))
        const res = await GetCodData({
            filterType: props.tab,
            filterId: selectedTab?.value || "",
            dateFrom: startDate,
            dateTo: endDate,
            exportToExcel: isExport,
            totalReport: withTotal
        })
        if (res.succeeded) {
            if (isExport || withTotal) {
                window.open(res.data.location, "_blank", "noreferrer");
            }
            else {
                setData(res.data)
            }
        }
        dispatch(setLoading(false))
    }
    const onClear = () => {
        setSelectedTab(undefined)
        setStartDate(undefined)
        setEndDate(undefined)
    }
    useEffect(() => {
        if (selectedTab != null) {
            refreshData().then()
        }
    }, [selectedTab, startDate, endDate])

    return (
        <div style={{margin: '10px 40px 0px'}}>
            <Card>
                <Card.Header>

                    <Row>
                        <Col lg={2} className="px-md-4">

                            <Select
                                key={`selectedCustomer__${selectedTab}`}
                                value={selectedTab}
                                styles={{control: customControlStyles}}
                                options={props.options}
                                placeholder={props.placeHolder}
                                onChange={(selectedOption) => {
                                    if (Array.isArray(selectedOption)) {
                                        throw new Error(
                                            "Unexpected type passed to ReactSelect onChange handler"
                                        );
                                    }
                                    setSelectedTab({
                                        label: selectedOption?.label!,
                                        value: selectedOption?.value!,
                                    });
                                }}
                            />
                        </Col>
                        <Col lg={2} className="px-md-4">
                            <DateRangePicker
                                noBorder={false}
                                isOutsideRange={() => false}
                                small
                                minimumNights={0}
                                startDatePlaceholderText="Date from"
                                endDatePlaceholderText="Date To"
                                startDate={
                                    startDate ? moment(startDate) : null
                                }
                                startDateId="123" // PropTypes.string.isRequired,
                                endDate={
                                    startDate ? moment(endDate) : null
                                }
                                endDateId="321" // PropTypes.string.isRequired,
                                onDatesChange={({startDate, endDate}) => {
                                    setStartDate(startDate?.toDate());
                                    setEndDate(endDate?.toDate())
                                }}
                                focusedInput={ReturnDateFromFocusedInput}
                                onFocusChange={(focusedInput: any) => {
                                    setReturnDateFromFocusedInput(focusedInput);
                                }}
                            />

                        </Col>
                        <Col lg={2} className="px-md-4 mx-3">
                            <h5>Total Cod: {data?.totalCod?.toLocaleString()} SAR</h5>
                        </Col>
                        <Col lg={2} className="px-md-4 mx-3">
                            <h5>Total Shipments: {data?.shipments?.length?.toLocaleString()}</h5>
                        </Col>



                    </Row>
                    <div className="d-flex justify-content-end">
                        <Button disabled={!selectedTab} variant={"danger"} onClick={onClear}>Clear</Button>
                        <div className="p-2"/>
                        <Button disabled={!selectedTab || !Boolean(data?.shipments?.length)} onClick={() => refreshData(false, true)}>Export Total</Button>
                        <div className="p-2"/>
                        <Button disabled={!selectedTab || !Boolean(data?.shipments?.length)} onClick={() => refreshData(true)}>Export</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DynamicDataTable data={{matches: data?.shipments ?? [], total: data?.shipmentsCount ?? 0}}/>
                </Card.Body>
            </Card>
        </div>
    )
}

const customControlStyles = (base: any) => ({
    ...base,
    height: 21!,
});
export default DynamicCodReportTab;

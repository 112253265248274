import React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useDispatch, useSelector} from 'react-redux';
import AppStateModel from '../../models/appStateModel';
import {LoginAsync} from '../../actions/authActions';
import Card from 'react-bootstrap/Card';
import {FaShippingFast} from "react-icons/fa";


const App = () => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.login
  );
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const dispatch = useDispatch();

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submit();
    }
  };
  const submit = () => {
    dispatch(LoginAsync(username, password));
  }

  return (
    <Container style={{ height: "100vh" }}>
      <Row style={{ height: "80vh" }}>
        <Col lg={3}></Col>
        <Col
          lg={6}
          style={{ display: "flex" }}
          className="flex-column justify-content-center"
        >
          <Card bg="white" border="white">
            <div style={{ textAlign: "center" }}>
              <FaShippingFast color={"#246081"} size={240}/>
            </div>

            <Card.Body>
              <Card.Title>{content.title}</Card.Title>

              <Form>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{content.username}</Form.Label>
                  <Form.Control
                    type="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder={content.usernamePlaceholder}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>{content.password}</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder={content.passwordPlaceholder}
                  />
                </Form.Group>
                <p className="forgot-password text-right">
                  {content.forgot1} <a href="#">{content.forgot2}</a>
                </p>
                <Button
                  variant="primary"
                  onClick={submit}
                  style={{ width: "100%", height: '40px', fontSize: '15px' }}
                >
                  {content.submit}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3}></Col>
      </Row>
    </Container>
  );
}


export default App;
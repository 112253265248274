import moment from "moment-timezone";

const ToLocalDateTime = (date: any, countryCode: string) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (!date) {
        return "  -  "
    }
    const timeZone = timezone
    let a = moment.utc(date, 'DD/MM/YYYY hh:mm:sss a')
    a.tz(timeZone)
    return a.format('YYYY/MM/DD, hh:mm a');

}
export const ToLocalDateTimeAsDate = (date: any, countryCode: string): Date => {


    if (!date) {
        return new Date()
    }
    const timeZone = countryCode === 'SA' ? 'Asia/Riyadh' : 'Asia/Amman'
    let a = moment.utc(date, 'DD/MM/YYYY hh:mm:sss a')
    a.tz(timeZone)
    return a.toDate();

}
export const convertUTCHour = (val: any, countryCode: string | undefined) => {

    const time = new Date()
    time.setHours(val)
    if (countryCode === "SA") {
        time.setHours(time.getHours() + 3);

    }
    if (countryCode === "JO") {
        time.setHours(time.getHours() + 2);

    }

    return time.getHours()

}
export default ToLocalDateTime


export const calculateDifferenceInHours = (date2: Date) => {
        const diff = Math.abs((new Date().getTime() - date2.getTime()) /  36e5) // Convert milliseconds to hours
        const roundedDiff = Math.round(diff)
        return roundedDiff;
}

import {FunctionComponent, useCallback, useState} from "react";
import React from "react";
import AppStateModel from "../../models/appStateModel";
import {useDispatch, useSelector} from "react-redux";
import AccountInfo from "./AccountInfo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {GetShipmentsToBeCollected} from "../../api/data";
import ShipmentModel from "../../models/shipmentModel";
import MemberModel from "../../models/members/memberModel";
import BalanceSheet from "./balanceSheet";
import {RefreshProfile} from "../../actions/authActions";
import MemberSelect from "./memberSelect";

export type CodCollectionData = {
    shipments: ShipmentModel[] | null
    member: MemberModel | null
}
const CodPage: FunctionComponent = () => {
    const dispatch = useDispatch()
    const couriers = useSelector((x: AppStateModel) => x.Meta?.couriers)
    const [codData, setCodData] = useState<CodCollectionData>({shipments: [], member: null})
    const onMemberSelect = useCallback(async (id: string) => {
        const res = await GetShipmentsToBeCollected(id, false);
        if (res.succeeded) {
            setCodData(res.data)
        }
    }, [])

    const onCollected = useCallback(() => {
        dispatch(RefreshProfile())
        setCodData({shipments: [], member: null})
    }, [])
    return (<div>
        <div >
            <Row className={"d-flex justify-content-between mt-3"}>
                <Col md={6}>
                    <MemberSelect placeHolder={"Courier"} options={couriers} onChange={onMemberSelect}/>
                </Col>
                <Col md={6}>
                    {
                        codData.member && <AccountInfo member={codData.member}/>
                    }
                </Col>
            </Row>
            <Row className={"d-flex justify-content-between mt-3 px-3"}>
                <Col md={12}>
                    {
                        (Boolean(codData.shipments) && codData.member?.accountId && codData.shipments!.length > 0) &&
                        <BalanceSheet onCollected={onCollected} courierId={codData.member?.accountId}
                                      shipments={codData.shipments}/>
                    }
                </Col>
            </Row>
        </div>
    </div>)
}

export default CodPage

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import BTable from "react-bootstrap/Table";
import Pager from "../components/Pager";
import Card from "react-bootstrap/Card";
import AppStateModel from "../../models/appStateModel";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Row} from "react-bootstrap";
import {BsSearch} from "react-icons/bs";
import {SearchOtpAsync, setOtpCriteria} from "../../actions/otpActions";
import {IOtp} from "../../models/otp";
import ToLocalDateTime from "../../actions/date";


const Otp = () => {
    const pagedOtp = useSelector(
        (state: AppStateModel) => state.PagedOtp
    );
    const searchOtpCriteria = useSelector(
        (state: AppStateModel) => state.SearchOtpCriteria
    );
    const countryCode = useSelector(
        (state: AppStateModel) => state.Profile.currentTenant?.countryCode
    );
    const dispatch = useDispatch();
    useEffect(() => {
            dispatch(SearchOtpAsync());
    }, [dispatch]);

    const content = useSelector(
        (state: AppStateModel) => state.AppContent.cities
    );

    const couriers = useSelector((state: AppStateModel) => state.Meta?.couriers);

    const getCourierName = (courierId: string) => {
        if (courierId != null)
            return couriers?.find((x) => x.value == courierId)?.label;
        else
            return courierId
    };
    const onClear = () => {
        dispatch(setOtpCriteria({...searchOtpCriteria, shipmenNumber: ''}))
        dispatch(
            SearchOtpAsync()
        )
    }
    return (
        <div style={{padding: "1vw"}}>
            <Card>
                <Card.Header>
                    <Row>
                        <Col lg={2} className="px-md-4">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{height: 35}}
                                    // size="sm"
                                    placeholder={"Shipment Number"}
                                    value={searchOtpCriteria?.shipmenNumber ?? ""}
                                    onChange={(e) => {
                                        console.log(e.target.value, 'llllllllll')
                                        dispatch(setOtpCriteria({...searchOtpCriteria, shipmenNumber: e.target.value}))
                                        }}
                                />
                                <InputGroup.Append style={{zIndex: 0}}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                SearchOtpAsync()
                                            )
                                        }
                                        className="icon-button"
                                    >
                                        <BsSearch name={"search"} color={"white"} size={21}/>
                                    </Button>

                                </InputGroup.Append>
                            </InputGroup>

                        </Col>
                        <Col lg={1} className="px-md-2 ">
                            <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
                                <Button
                                    variant="danger"
                                    onClick={() => {
                                        onClear()
                                    }}
                                >
                                    Clear
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <BTable striped responsive bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Shipment Number</th>
                            <th>Password</th>
                            <th>Created On</th>
                            <th>Created By</th>
                            <th>Number Of Tries</th>
                        </tr>
                        </thead>

                        <tbody>
                        {pagedOtp?.matches?.map((row: IOtp, i: any): any => (
                            <tr key={i}>
                                <td>{row.id}</td>
                                <td>{row.shipmentNumber}</td>
                                <td>{row.password}</td>
                                <td>{ToLocalDateTime(row.createdOn, countryCode!)}</td>
                                <td>{getCourierName(row.createdBy)}</td>
                                <td>{row.tries}</td>
                            </tr>
                        ))}
                        </tbody>
                    </BTable>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Pager
                            currentPageNumber={searchOtpCriteria?.pageNumber!}
                            numberOfPages={Math.ceil(
                                pagedOtp?.total! / searchOtpCriteria?.pageSize!
                            )}
                            onChange={(page: number) => {
                                dispatch(
                                    setOtpCriteria({
                                        pageNumber: page,
                                    })
                                );
                                dispatch(SearchOtpAsync());
                            }}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Otp;

import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import ShipmentModel from "../../models/shipmentModel";
import ScanListWithScan from "../shipments/components/shipmentListWithScan";

interface IProps {
    visible: boolean;
    onHide: () => any;
    onDone: (ids: any) => any;
    title: string;
    submitButton: string;
    allowedStatuses?: string[];
}

const ForcePickupModal = (props: IProps) => {

    const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);

    const onSubmit = () => {
        let ids: string[] = [];
        shipments.forEach((s) => {
            ids.push(s.id);
        });
        props.onDone(ids);
    };


    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ScanListWithScan
                    setShipments={setShipments}
                    allowedStatuses={props.allowedStatuses}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={
                        shipments.length === 0
                    }
                    onClick={onSubmit}
                >
                    {props.submitButton}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ForcePickupModal;

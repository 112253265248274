import AppContentModel from "../models/appContentModel";
import Ar from './ar';
import En from './en';


const SetAppContent = (culture?: string): AppContentModel => {
    
    if (culture && culture.substr(0,2) === "ar") return Ar;

    return En;
} 

export { SetAppContent };
import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import { useSelector } from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import ScanListWithScan from "./shipmentListWithScan";
import ReactSelect from "react-select";

interface IProps {
	visible: boolean;
	onHide: () => any;
	onDone: (ids: any, courierId: any) => any;
	allowedStatuses?: string[];
}

const Component = (props: IProps) => {
	const content = useSelector(
		(state: AppStateModel) => state.AppContent.shipments.returnToCustomer
	);
	const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [driver, setDriver] = React.useState<any>(null);
	const drivers = useSelector((state: AppStateModel) => state.Meta?.couriers);

	const onSubmit = () => {
		let ids: string[] = [];
		shipments.forEach((s) => {
			ids.push(s.id);
		});
		props.onDone(ids, driver!.value!);
	};

	return (
		<Modal
			show={props.visible}
			onHide={() => props.onHide()}
			dialogClassName="modal-100w"
			aria-labelledby="example-custom-modal-styling-title"
			size="xl"
		>
			<Modal.Header closeButton>
				<Modal.Title id="example-custom-modal-styling-title">
					{content.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ScanListWithScan
					setShipments={setShipments}
					allowedStatuses={props.allowedStatuses}
				/>
			</Modal.Body>
			<Modal.Footer>
				<div style={{ width: "20%" }}>
					<ReactSelect
						value={driver}
						onChange={(v) => setDriver(v)}
						options={drivers}
					/>
				</div>
				<Button
					disabled={
						(shipments.length === 0 || loading) && !driver && driver == null
					}
					onClick={onSubmit}
				>
					{content.button}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default Component;

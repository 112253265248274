import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import React from 'react';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import ConfigureStore from './store';
import {createRoot} from 'react-dom/client';

import {Provider} from 'react-redux';
import "./index.css";

const AppLoader = () => {
  const store = ConfigureStore()
  return (

    <Provider store={store}>
      <App/>
    </Provider>

  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AppLoader/>
);


import { CreateStation, SearchStations } from "../api/data";
import PagedModel from "../models/pagedModel";
import { SearchStationCriteria, StationsModel } from "../models/StationsModel";
import { SET_STATIONS, SET_STATIONS_CRITERIA } from "../store/types";
import { SetAlert, setLoading } from "./common";

export const SearchStationsAsync = () => {
    return async (dispatch: any,getState:any) => {
        const state=getState()
        dispatch(setLoading(true));

        const res = await SearchStations(state.StationsCriteria);

        if (res.succeeded) {

            dispatch(searchStations(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data:res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};

export const CreateStationshAsync = (station:StationsModel,onDone:()=>any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await CreateStation(station);
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Station is created successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onDone();
        } else {
            dispatch(SetAlert({
                visible: true,
                data:res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};

export const searchStations = (searchStationsResult: PagedModel<StationsModel>) => {
    return {
        type: SET_STATIONS,
        payload: {
            searchStationsResult,
        },
    };
};

export const setStationsCriteria = (filters: SearchStationCriteria) => {
    return {
        type: SET_STATIONS_CRITERIA,
        payload: {
            filters,
        },
    };
};
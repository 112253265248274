import React, {useCallback, useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BTable from "react-bootstrap/Table";
import {ContainersExportToExcel} from "../../api/data";
import ToLocalDateTime from "../../actions/date";
import CreateContainerModel from "./createContainerModal";
import {Button} from "react-bootstrap";
import {GiFootsteps} from "react-icons/gi";
import ViewContainer from "./viewContainer";
import {
    RemoveShipmentFromContainerAsync,
    SearchContainersAsync,
    setContainersCriteria
} from "../../actions/containerActions";
import {useDispatch, useSelector} from "react-redux";
import Pager from "../components/Pager";
import CheckInContainerModal from "./CheckInContainerModal";
import CheckOutContainerModal from "./CheckOutContainerModal";
import AppStateModel from "../../models/appStateModel";
import {SetAlert, setLoading} from "../../actions/common";
import {ContainerModel} from "../../models/containers";

const Containers: React.FC = () => {
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState<"NONE" | "CREATE" | `VIEW_${string}` | "CHECK_IN" | "CHECK_OUT">("NONE")
    const meta = useSelector((state: AppStateModel) => state.Meta);
    const resolveEmployee = useCallback((id: string | null) => {
        if (id === null) {
            return ''
        }
        const st = meta?.employees?.find((s) => s.value === id);
        return st?.label;
    }, [meta?.employees]);

    const pagedContainers = useSelector(
        (state: AppStateModel) => state.PagedContainers
    );
    const searchContainersCriteria = useSelector(
        (state: AppStateModel) => state.SearchContainersCriteria
    );


    useEffect(() => {
        refreshData()
    }, [openModal, ])
    const refreshData = () => {
        dispatch(SearchContainersAsync())
    }

    const onRemoveContainment = (shipmentId: string, containerId: number) => {
        dispatch(RemoveShipmentFromContainerAsync(shipmentId, containerId, refreshData))
    }

    const ExportToExcel = async () => {
        dispatch(setLoading(true));
        const res = await ContainersExportToExcel();
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer");
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false))
    }

    const onCloseModal = () => {
        setOpenModal("NONE")
    }
    const getBackgroundColor = (x: ContainerModel) => {
        if (x.journeyFinalized) {
            return "#90EE90"
        }
        if (x.closedBy == null) {
            return "#ADD8E6"
        } else {
            return undefined
        }
    }
    return <div style={{ padding: "1vw" }}>
        {
          openModal === "CREATE" &&
          <CreateContainerModel onHide={onCloseModal}/>
        }
        {
          openModal === "CHECK_IN" &&
          <CheckInContainerModal refreshData={refreshData}
                                 onHide={onCloseModal}/>
        }
        {
          openModal === "CHECK_OUT" &&
          <CheckOutContainerModal refreshData={refreshData}
                                  onHide={onCloseModal}/>
        }
        {
          openModal.startsWith("VIEW") &&
          <ViewContainer
            resolveEmployee={resolveEmployee}
            refreshData={refreshData}
            onRemoveShipment={onRemoveContainment}
            data={pagedContainers?.matches.find(i => i.id == Number(openModal.substring(5)))}
            onHide={onCloseModal}/>
        }
        <Card>
            <Card.Header>
                <Row className={"flex justify-content-between"}>
                    <Col lg={2} className="px-md-2">
                        <h3>Containers</h3>
                    </Col>
                    <Col md={2}>
                        <Row>
                            <Col md={4} className={"mb-2"}>
                                <Button
                                  onClick={() => setOpenModal("CREATE")}>Create</Button>
                            </Col>
                            <Col md={8}>
                                    <Button
                                      onClick={() => setOpenModal("CHECK_IN")}>
                                        Check In
                                    </Button>
                            </Col>
                        </Row>
                            <Row>
                                <Col md={4}>
                                    <Button onClick={ExportToExcel}>
                                        Export
                                    </Button>
                                </Col>
                                <Col md={8}>
                                    <Button className={"mx-1"}
                                            onClick={() => setOpenModal("CHECK_OUT")}>
                                        Check Out
                                    </Button>

                                </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Header>
        </Card>

        <Card>

            <Card.Body>
                <Row className={"px-2"}>
                    <BTable striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Type</th>
                            <th>Number of shipments</th>
                            <th>Journey</th>
                            <th>Closed On</th>
                            <th>Created On</th>
                            <th>Closed By</th>
                            <th>Actions</th>

                        </tr>
                        </thead>

                        <tbody>
                        {pagedContainers?.matches &&
                            pagedContainers?.matches?.map((x, index) => {
                                return (
                                  <tr style={{
                                      backgroundColor: getBackgroundColor(x)
                                  }} key={x.id}>
                                      <td>{x.id} </td>
                                      <td>{x.type} </td>
                                      <td>{x.containments?.length}</td>
                                      <td>{x.journey?.sort((a, b) => a.order - b.order)?.map(i => `${i.route?.sourceStation?.name} => ${i.route?.destinationStation?.name} => `)}</td>
                                      <td>{ToLocalDateTime(x.closedOn, 'SA') ?? '-'} </td>
                                      <td>{ToLocalDateTime(x.createdOn, 'SA')} </td>
                                      <td>{resolveEmployee(x?.closedBy)} </td>

                                      <td>
                                          <Button
                                            onClick={() => setOpenModal(`VIEW_${x.id}`)}
                                            className="icon-button main-button"
                                          >
                                              <GiFootsteps color={"white"}
                                                           size={21}/>
                                          </Button>
                                      </td>
                                  </tr>
                                )
                            }
                          )
                        }
                        </tbody>
                    </BTable>
                </Row>
                {pagedContainers?.matches && pagedContainers?.matches?.length > 0 && (
                  <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                  >
                      <Pager
                        currentPageNumber={searchContainersCriteria?.pageNumber!}
                        numberOfPages={Math.ceil(
                            pagedContainers?.total! / searchContainersCriteria?.pageSize!
                        )}
                        onChange={(page: number) => {
                            dispatch(
                                setContainersCriteria({
                                    pageNumber: page,
                                })
                            );
                            dispatch(SearchContainersAsync());
                        }}
                      />
                  </div>
                )}
            </Card.Body>
        </Card>
    </div>
}

export default Containers;

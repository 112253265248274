import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BTable from "react-bootstrap/Table";
import ToLocalDateTime from "../../actions/date";
import CreateJourneyModal from "./createJourneyModal";
import Button from "react-bootstrap/Button";
import {DeactivateJourneyAsync, SearchJourneysAsync} from "../../actions/routesActions";
import SubmitModal from "../Cod/modals/SubmitModal";
import UpdateJourneyModal from "./modals/updateJourneyModal";
import {AiFillEdit} from "react-icons/ai";

const Journeys: React.FC = () => {
    const d = useDispatch()
    const [openModal, setOpenModal] = useState<"NONE" | "ADD" | "UPDATE">("NONE")
    const data = useSelector((i: AppStateModel) => i.Journeys)
    const [deleteModal, setDeleteModal] = useState({show: false, id: 0})
    const [selectedJourneyForEdit, setSelectedJourneyForEdit] = useState<any>()

    useEffect(() => {
        d(SearchJourneysAsync())
    }, [])
    return (<div className={"px-5 "}>
        {
            openModal == "ADD" &&
            <CreateJourneyModal onHide={() => setOpenModal("NONE")}/>
        }
        {
            openModal == "UPDATE" &&
            <UpdateJourneyModal selectedJourney={selectedJourneyForEdit} onHide={() => setOpenModal("NONE")}/>
        }

        {
            deleteModal.show &&
            <SubmitModal onSubmit={() => {
                d(DeactivateJourneyAsync(deleteModal.id))
            }}
                         handleClose={() => {
                             setDeleteModal({show: false, id: 0})
                         }} show={deleteModal.show}
            />
        }

        <Card>
            <Card.Header>
                <Row className={"flex justify-content-between"}>
                    <Col lg={2} className="px-md-2">
                        <h3>Journeys</h3>
                    </Col>
                    <Col>
                        <Button
                            onClick={() => setOpenModal("ADD")}>{'Create'}</Button>
                    </Col>
                </Row>
            </Card.Header>
        </Card>

        <Card>

            <Card.Body>
                <Row className={"px-2"}>
                    <BTable striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Source Station</th>
                            <th>Destination Station</th>
                            <th>Steps</th>
                            <th>Created On</th>
                            <th>Actions</th>

                        </tr>
                        </thead>

                        <tbody>
                        {data?.matches &&
                            data?.matches?.map((x) => {
                                    return (
                                        <tr style={{
                                            backgroundColor: x.deactivated ? "#ff000033" : undefined
                                        }} key={x.id}>
                                            <td>{x.id} </td>
                                            <td>{x.name} </td>
                                            <td>{x.sourceStation?.name}</td>
                                            <td>{x.destinationStation?.name}</td>
                                            <th>
                                                <span>{x.steps?.sort((a, b) => a.order - b.order)?.map(i => `${i.route?.sourceStation?.name} => ${i.route?.destinationStation?.name} => `)}</span>
                                            </th>
                                            <td>{ToLocalDateTime(x.createdOn, 'SA')} </td>
                                            <td>
                                                {
                                                    !x.deactivated &&
                                                        <Button
                                                            onClick={() => {
                                                                setDeleteModal({show: true, id: x.id})
                                                            }}
                                                            style={{minWidth: 90}}
                                                            className="icon-button main-button"
                                                            variant="danger"
                                                        >
                                                            {"Deactivate"}
                                                        </Button>
                                                }
                                                {/*<Button*/}
                                                {/*    onClick={() => {*/}
                                                {/*        setSelectedJourneyForEdit(x)*/}
                                                {/*        setOpenModal("UPDATE")*/}
                                                {/*    }}*/}
                                                {/*    variant="primary"*/}
                                                {/*    className="icon-button"*/}
                                                {/*>*/}
                                                {/*    <AiFillEdit color={"white"} size={21}/>*/}
                                                {/*</Button>*/}

                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </BTable>
                </Row>
            </Card.Body>
        </Card>
    </div>)
}

export default Journeys

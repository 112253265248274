import React, {useEffect, useState} from 'react';
import Select from "react-select";
import LookupModel from "../../../models/lookupModel";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import CitiesSelect from "../../components/CitiesSelect";
import {SingleDatePicker} from "react-dates";
import moment from "moment/moment";


type operator =
  'eq'
  | 'greater'
  | 'greaterOrEq'
  | 'less'
  | 'lessOrEq'
  | 'like'
  | 'notEq';

export interface IFilter {
  field?: string;
  rule?: operator;
  value?: string;
  type?: string
}


const nameOptions = [
  {
    value: "",
    label: "Field",
    type: "shipment"

  },
  {
    id: 1,
    value: "CreatedOn",
    label: "Created On",
    type: "shipment"

  },
  {
    value: "UniqueReference",
    label: "Unique Reference",
    type: "shipment"

  },
  {
    value: "CustomerId",
    label: "Customer Id",
    type: "shipment"

  },
  {
    value: "Number",
    label: "Number",
    type: "shipment"

  },
  {
    value: "StationId",
    label: "StationId",
    type: "shipment"

  },
  {
    value: "Commodity.COD.Amount",
    label: "COD",
    type: "shipment"

  },
  {
    value: "Payment.Value.Method",
    label: "Payment Method",
    type: "shipment"

  },
  {
    value: "CurrentStatus",
    label: "Status",
    type: "shipment"

  },
  {
    value: "Shipper.Name",
    label: "Shipper Name",
    type: "shipment"

  },
  {
    value: "Shipper.City",
    label: "Shipper City"
  },
  {
    value: "Consignee.City",
    label: "Consignee City",
    type: "shipment"

  },
  {
    value: "Consignee.Name",
    label: "Consignee Name",
    type: "shipment"

  },
  {
    value: "failedAttempts",
    label: "Failed Attempts",
    type: "shipment"

  },
  {
    label: "Picked up On",
    value: "pickupDate",
    type: "shipment"

  },
  {
    label: "Received On",
    value: "receivedOn",
    type: "shipment"

  },
  {
    label: "Out For Delivery On",
    value: "outForDeliveryOn",
    type: "shipment"

  },
  {
    label: "Action by",
    value: "CreatedBy",
    type: "trace"
  },
  {
    label: "Action Type",
    value: "Code",
    type: "trace"
  },

  {
    id: 2,
    label: "Action Date",
    value: "CreatedOnt",
    type: "trace"
  },


]

const operatorOptions: LookupModel[] = [
  {
    value: '',
    label: "Rule"
  },
  {
    value: '1',
    label: "Equals to"
  },
  {
    value: '2',
    label: "Not Equals to"
  },
  {
    value: '4',
    label: "Contains"
  },
  {
    value: '5',
    label: "Greater than"
  },
  {
    value: '6',
    label: "Greater or Equals to"
  },
  {
    value: '7',
    label: "Less than"
  },
  {
    value: '8',
    label: "Less or Equals than"
  },


]

interface IProps {
  onSetDataFilters: (f: IFilter[]) => void
}

const Component = (props: IProps) => {

  const [filters, setFilters] = useState<IFilter[]>([]);
  const [newFilter, setNewFilter] = useState<IFilter>({});
  const [disabled, setDisabled] = useState(true)
  const add = () => {

    const type = nameOptions.find(x => x.value === newFilter.field)?.type


    setFilters((i) => ([...i, {
      ...newFilter,
      field: newFilter.field === "CreatedOnt" ? "CreatedOn" : newFilter.field,
      type: type
    }]));
    setNewFilter({});

  }
  useEffect(() => {
    if (newFilter.field && newFilter.value && newFilter.rule) {
      setDisabled(false)
    } else setDisabled(true)

  }, [newFilter])

  const remove = (name: string) => {
    let arr: IFilter[] = [];
    filters.forEach(f => {
      if (f.field != name)
        arr.push(f)
    });
    setFilters(arr);

  }
  useEffect(() => {
    props.onSetDataFilters(filters)
  }, [filters])
  return (
    <>
      {
        filters.map(f => (
          <Row className={"mb-3"}>
            <Col>


              <div className={"p-1 px-2 bg-secondary rounded "}>
                <h3
                  className={"text-center text-white"}>{nameOptions.find(i => i.value.startsWith(f.field ?? "") && i.type == f.type)?.label}</h3>
              </div>
            </Col>
            <Col>

              <div className={"p-1 px-2 bg-secondary rounded"}>


                <h3
                  className={"text-center text-white"}>{operatorOptions.find(i => i.value == f?.rule)?.label}</h3>
              </div>
            </Col>
            <Col>
              <div className={"p-1 px-2 bg-secondary rounded"}>


                <h3
                  className={"text-center text-white"}>{f?.value?.toString()}</h3>
              </div>
            </Col>
            <Col>
              <Button
                onClick={() => remove(f.field!)}
              >Remove</Button>

            </Col>
          </Row>

        ))
      }

      <Row className={"mb-3"}>
        <Col>
          <Select
            value={nameOptions.find(i => i.value == newFilter?.field) || nameOptions[0]}
            className="basic-multi-select"
            escapeClearsValue={true}
            options={nameOptions}
            placeholder={"Field"}
            onChange={(selectedOptions) =>
              setNewFilter({
                ...newFilter,
                field: selectedOptions?.value,
              })}
          />
        </Col>
        <Col>
          <Select
            value={operatorOptions.find(i => i.value == newFilter?.rule) || operatorOptions[0]}
            className="basic-multi-select"
            escapeClearsValue={true}
            options={operatorOptions}
            placeholder={"Operator"}
            onChange={(selectedOptions) => setNewFilter({
              ...newFilter,
              rule: selectedOptions?.value as operator
            })}
          />
        </Col>
        <Col>
          <GetFilterValue onChange={(e) => setNewFilter({
            ...newFilter,
            value: e,
          })}
                          value={newFilter.value ?? ""}
                          type={newFilter.field ?? ""}/>
        </Col>
        <Col>
          <Button
            disabled={disabled}
            onClick={add}
          >Add</Button>

        </Col>
      </Row>

    </>
  )
}

interface IValue {
  type: string
  onChange: (i: any) => void
  value: string
}

const GetFilterValue = (props: IValue) => {
  const meta = useSelector((state: AppStateModel) => state.Meta);
  const content = useSelector(
    (state: AppStateModel) => state.AppContent
  );
  switch (props.type) {
    case "CurrentStatus": {

      return <div>
        <Select
          isMulti={false}
          value={meta?.shipmentStatuses?.find(x => x.value == props.value)}
          className="basic-multi-select"
          escapeClearsValue={true}
          options={meta?.shipmentStatuses}
          placeholder={content.shipments.model.status}
          onChange={(e: any) => props.onChange(e?.value ?? "")}
        />
      </div>

    }
    case "Code": {

      return <div>
        <Select
          isMulti={false}
          value={meta?.traceStatuses?.find(x => x.value == props.value)}
          className="basic-multi-select"
          escapeClearsValue={true}
          options={meta?.traceStatuses}
          placeholder={content.shipments.model.status}
          onChange={(e: any) => props.onChange(e?.value ?? "")}
        />
      </div>

    }
    case "StationId": {

      return <div>
        <Select
          isMulti={false}
          value={meta?.stations?.find(x => x.value == props.value)}
          className="basic-multi-select"
          escapeClearsValue={true}
          options={meta?.stations}
          placeholder={"Station"}
          onChange={(e: any) => props.onChange(e?.value ?? "")}
        />
      </div>

    }
    case "Shipper.City": {

      return <div>
        <CitiesSelect
          value={String(props.value) ?? ""}
          onChange={(val: any) => {
            props.onChange(val ?? "")
          }}
          placeholder={"City"}
        />
      </div>

    }
    case "CustomerId": {
      return <div>
        <Select
          isMulti={false}
          value={meta?.customers?.find(x => x.value == props.value)}
          className="basic-multi-select"
          escapeClearsValue={true}
          options={meta?.customers}
          placeholder={"Customers"}
          onChange={(e: any) => props.onChange(e?.value ?? "")}
        />
      </div>
    }
    case "Consignee.City": {

      return <div>
        <CitiesSelect
          value={String(props.value) ?? ""}
          onChange={(val: any) => {
            props.onChange(val ?? "")
          }}
          placeholder={"City"}
        />
      </div>

    }
    case "receivedOn": {
      return <div style={{
        height: 48,
        backgroundColor: "white",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#00a699",
        borderRadius: "3px"
      }}>
        <SingleDatePicker
          noBorder={true}
          enableOutsideDays={true}
          isDayBlocked={(s) => {
            return false
          }}
          isOutsideRange={(s) => {
            return false
          }}
          date={props.value ? moment(new Date(props.value)).utc() : null} // momentPropTypes.momentObj or null
          onDateChange={d => {
            // @ts-ignore
            props.onChange(moment(d).utc())
          }}
          focused={true} // PropTypes.bool
          onFocusChange={({ focused }) => {
          }}// PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
        />
      </div>
    }
    case "outForDeliveryOn": {
      return <div style={{
        height: 48,
        backgroundColor: "white",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#00a699",
        borderRadius: "3px"
      }}>
        <SingleDatePicker
          noBorder={true}
          enableOutsideDays={true}
          isDayBlocked={(s) => {
            return false
          }}
          isOutsideRange={(s) => {
            return false
          }}
          date={props.value ? moment(new Date(props.value)).utc() : null} // momentPropTypes.momentObj or null
          onDateChange={d => {
            // @ts-ignore
            props.onChange(moment(d).utc())
          }}
          focused={true} // PropTypes.bool
          onFocusChange={({ focused }) => {
          }}// PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
        />
      </div>
    }
    case "CreatedOn": {
      return <div style={{
        height: 48,
        backgroundColor: "white",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#00a699",
        borderRadius: "3px"
      }}>
        <SingleDatePicker
          noBorder={true}
          enableOutsideDays={true}
          isDayBlocked={(s) => {
            return false
          }}
          isOutsideRange={(s) => {
            return false
          }}
          date={props.value ? moment(new Date(props.value)).utc() : null} // momentPropTypes.momentObj or null
          onDateChange={d => {
            // @ts-ignore
            props.onChange(moment(d).utc())
          }}
          focused={true} // PropTypes.bool
          onFocusChange={({ focused }) => {
          }}// PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
        />
      </div>
    }

    case "pickupDate": {
      return <div style={{
        height: 48,
        backgroundColor: "white",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#00a699",
        borderRadius: "3px"
      }}>
        <SingleDatePicker
          noBorder={true}
          enableOutsideDays={true}
          isDayBlocked={(s) => {
            return false
          }}
          isOutsideRange={(s) => {
            return false
          }}
          date={props.value ? moment(new Date(props.value)).utc() : null} // momentPropTypes.momentObj or null
          onDateChange={d => {
            // @ts-ignore
            props.onChange(moment(d).utc())
          }}
          focused={true} // PropTypes.bool
          onFocusChange={({ focused }) => {
          }}// PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
        />
      </div>
    }
    case "CreatedOnt": {
      return <div style={{
        height: 48,
        backgroundColor: "white",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#00a699",
        borderRadius: "3px"
      }}>
        <SingleDatePicker
          noBorder={true}
          enableOutsideDays={true}
          isDayBlocked={(s) => {
            return false
          }}
          isOutsideRange={(s) => {
            return false
          }}
          date={props.value ? moment(new Date(props.value)) : null} // momentPropTypes.momentObj or null
          onDateChange={d => {
            // @ts-ignore
            props.onChange((d))
          }}// PropTypes.func.isRequired
          focused={true} // PropTypes.bool
          onFocusChange={({ focused }) => {
          }}// PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
        />
      </div>
    }
    case "CreatedOnt": {
      return <div style={{
        height: 48,
        backgroundColor: "white",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#00a699",
        borderRadius: "3px"
      }}>
        <SingleDatePicker
          noBorder={true}
          enableOutsideDays={true}
          isDayBlocked={(s) => {
            return false
          }}
          isOutsideRange={(s) => {
            return false
          }}
          date={props.value ? moment(new Date(props.value)) : null} // momentPropTypes.momentObj or null
          onDateChange={d => {
            // @ts-ignore
            props.onChange((d))
          }}// PropTypes.func.isRequired
          focused={true} // PropTypes.bool
          onFocusChange={({ focused }) => {
          }}// PropTypes.func.isRequired
          id="your_unique_id" // PropTypes.string.isRequired,
        />
      </div>
    }
    case "CreatedBy": {
      return <div>
        <Select
          onChange={(selectedOption) => {
            props.onChange(selectedOption?.value ?? "")
          }}
          isClearable
          value={meta?.couriers?.find(x => x.value == props.value)}
          options={meta?.couriers}
          placeholder="Courier"
        />
      </div>
    }

    default: {
      return <div>
        <Form.Control
          style={{ height: 35 }}
          placeholder={"Value"}
          value={props?.value}
          onChange={(e) => props.onChange(e.target.value)}

        />
      </div>
    }
  }


}

export default Component;

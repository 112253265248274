import React, {useCallback, useEffect, useState} from 'react';
import {setLoading} from "../../../actions/common";
import {GetTransactionsExcelReport} from "../../../api/data";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Col, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import LookupModel from "../../../models/lookupModel";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import {BsSearch} from "react-icons/bs";
import DynamicDataTable from "../../../components/dynamicDataTable";
import { transactionDetails} from "../../../models/codReports";
import {setShipmentsCriteriaAsync} from "../../../actions/shipmentsActions";


const AllTransactions = () => {
    const [ReturnDateFromFocusedInput, setReturnDateFromFocusedInput] = useState<any>();
    const [selectedReason, setSelectedReason] = useState<LookupModel | undefined>();
    const [balanceType, setBalanceType] = useState<LookupModel | undefined>()
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [data, setData] = useState<transactionDetails[]>()
    const [selectedMember, setSelectedMember] = React.useState<LookupModel | undefined>();
    const customers = useSelector((state: AppStateModel) => state.Meta?.customers);



    const dispatch = useDispatch();
    const refreshData = async (exportToExcel?: boolean) => {
            dispatch(setLoading(true))
            const res = await GetTransactionsExcelReport({
                dateFrom: startDate,
                dateTo: endDate,
                reason: selectedReason?.value,
                balanceType: balanceType?.value,
                memberAccountId: selectedMember?.value,
                exportToExcel
            })

            if (res.succeeded && res.data.location) {
                window.open(res.data.location, "_blank", "noreferrer");
            }
            else if (res.succeeded) {
                setData(res.data)
            }

            dispatch(setLoading(false))
        }

    useEffect(() => {
            refreshData().then()
    }, [selectedReason, balanceType, startDate, endDate, selectedMember])


    const onClear = () => {
        setData([])
        setEndDate(undefined)
        setStartDate(undefined)
        setSelectedReason(undefined)
        setSelectedMember(undefined)
        setBalanceType(undefined)
    }

    return (
        <div style={{margin: '10px 40px 0px'}}>
            <Card>
                <Card.Header>

                    <Row>
                        <Col lg={2} className="px-md-4">

                            <Select
                                key={`selectedReasonX_${selectedReason}`}
                                value={selectedReason}
                                className="basic-multi-select"
                                isClearable
                                options={[
                                    {label: 'Unknown', value: '0',},
                                    {label: 'CodCollected', value: '11',},
                                    {label: 'Cleared', value: '21',},
                                    {label: 'ReceivedFailedAttempt', value: '31',},
                                    {label: 'Transferred', value: '41',},
                                    {label: 'Delivered', value: '51',},
                                    {label: 'InitiatedDelivery', value: '61'}
                                ]}
                                placeholder={"Reason"}
                                onChange={(selectedOption) => {
                                    setSelectedReason(selectedOption ?? undefined)
                                }}
                            />
                        </Col>
                        <Col lg={2} className="px-md-4">

                            <Select
                                key={'balanceType'}
                                value={balanceType}
                                className="basic-multi-select"
                                isClearable
                                options={[
                                    {label: 'ShipmentWorth', value: "0"},
                                    {label: 'Cash', value: "1"},
                                    {label: 'Card', value: "2"},
                                ]}
                                placeholder={"Balance Type"}
                                onChange={(selectedOption) => {
                                    setBalanceType(selectedOption ?? undefined)
                                }}
                            />
                        </Col>

                        <Col lg={2} className="px-md-4">
                            <Select
                                key={`selectedMember${selectedMember}`}
                                onChange={(selectedOption) => {
                                    if (Array.isArray(selectedOption)) {
                                        throw new Error(
                                            "Unexpected type passed to ReactSelect onChange handler"
                                        );
                                    }
                                    setSelectedMember({
                                        value: selectedOption?.value!,
                                        label: selectedOption?.label!,
                                    });
                                }}
                                value={selectedMember}
                                options={customers}
                                placeholder="Member"
                            />

                        </Col>

                        <Col lg={2} className="px-md-4">
                            <DateRangePicker
                                noBorder={false}
                                isOutsideRange={() => false}
                                small
                                minimumNights={0}
                                startDatePlaceholderText="Date from"
                                endDatePlaceholderText="Date To"
                                startDate={
                                    startDate ? moment(startDate) : null
                                }
                                startDateId="123" // PropTypes.string.isRequired,
                                endDate={
                                    startDate ? moment(endDate) : null
                                }
                                endDateId="321" // PropTypes.string.isRequired,
                                onDatesChange={({startDate, endDate}) => {
                                    setStartDate(startDate?.toDate());
                                    setEndDate(endDate?.toDate())
                                }}
                                focusedInput={ReturnDateFromFocusedInput}
                                onFocusChange={(focusedInput: any) => {
                                    setReturnDateFromFocusedInput(focusedInput);
                                }}
                            />

                        </Col>

                    </Row>
                    <div className="d-flex justify-content-end">

                        <Button style={{marginRight: '5px'}} variant={"danger"}
                                onClick={onClear}>Clear</Button>
                        <Button
                            style={{marginRight: '5px'}}
                            onClick={() => {refreshData(false).then()}}>
                            Refresh
                        </Button>
                        <Button
                            onClick={() => {refreshData(true).then()}}>
                            Export
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                        <DynamicDataTable data={{matches: data ?? [], total: data?.length ?? 0}}/>
                </Card.Body>
            </Card>

        </div>

    )
}

export default AllTransactions;
import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BTable from "react-bootstrap/Table";
import ToLocalDateTime from "../../actions/date";
import {useDispatch, useSelector} from "react-redux";
import {DeactivateRouteAsync, SearchRoutesAsync} from "../../actions/routesActions";
import AppStateModel from "../../models/appStateModel";
import CreateRouteModal from "./createRouteModal";
import {Button} from "react-bootstrap";
import SubmitModal from "../Cod/modals/SubmitModal";
import {AiFillEdit} from "react-icons/ai";
import UpdateRouteModal from "./modals/updateRouteModal";

const Routes: React.FC = () => {
  const d = useDispatch()
  const [openModal, setOpenModal] = useState<"NONE" | "ADD" | "UPDATE">("NONE")
  const [deleteModal, setDeleteModal] = useState({show: false, id: 0})
  const data = useSelector((i: AppStateModel) => i.Routes)
  const [selectedRouteForEdit, setSelectedRouteForEdit] = useState<any>()
  useEffect(() => {
    d(SearchRoutesAsync())
  }, [])
  return (<div className={"px-5 "}>
    {
      openModal == "ADD" &&
      <CreateRouteModal onHide={() => setOpenModal("NONE")}/>
    }

    {
        openModal == "UPDATE" &&
        <UpdateRouteModal selectedRoute={selectedRouteForEdit} onHide={() => setOpenModal("NONE")}/>
    }


    {
        deleteModal.show &&
        <SubmitModal onSubmit={() => {
          d(DeactivateRouteAsync(deleteModal.id))
        }}
        handleClose={() => {
          setDeleteModal({show: false, id: 0})
        }} show={deleteModal.show}
        />
    }

    <Card>
      <Card.Header>
        <Row className={"flex justify-content-between"}>
          <Col lg={2} className="px-md-2">
            <h3>Routes</h3>
          </Col>
          <Col>
            <Button onClick={() => setOpenModal("ADD")}>{'Create'}</Button>
          </Col>
        </Row>
      </Card.Header>
    </Card>

    <Card>

      <Card.Body>
        <Row className={"px-2"}>
          <BTable striped bordered hover size="sm">
            <thead>
            <tr>
              <th>Id</th>
              <th>Source Station</th>
              <th>ETD</th>
              <th>Destination Station</th>
              <th>ETA</th>
              <th>Created On</th>
              <th>Actions</th>

            </tr>
            </thead>

            <tbody>
            {data?.matches &&

              data?.matches?.map((x) => {
                  return (
                    <tr style={{
                      backgroundColor: x.deactivated ? "#ff000033" : undefined}} key={x.id}>
                      <td>{x.id} </td>
                      <td>{x.sourceStation?.name}</td>
                      <td>{x.estimatedDepartureTime}</td>
                      <td>{x.destinationStation?.name}</td>
                      <td>{x.estimatedArrivalTime}</td>
                      <td>{ToLocalDateTime(x.createdOn, 'SA')} </td>
                      <td>
                        {
                          !x.deactivated &&
                              <>
                                <Button
                                    onClick={() => {
                                      setDeleteModal({show: true, id: x.id})
                                    }}
                                    style={{ minWidth: 90 }}
                                    className="icon-button main-button"
                                    variant="danger"
                                >
                                  {"Deactivate"}
                                </Button>
                                <Button
                                    onClick={() => {
                                      setSelectedRouteForEdit(x)
                                      setOpenModal("UPDATE")
                                    }}
                                    variant="primary"
                                    className="icon-button"
                                >
                                  <AiFillEdit color={"white"} size={21}/>
                                </Button>
                              </>
                        }
                      </td>
                    </tr>
                  )
                }
              )
            }
            </tbody>
          </BTable>
        </Row>
      </Card.Body>
    </Card>
  </div>)
}
export default Routes

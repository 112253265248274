import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import {CodCollectionData} from "./cod";
import {ClearData, GetShipmentsToBeCollected, TransferData} from "../../api/data";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MemberSelect from "./memberSelect";
import AccountInfo from "./AccountInfo";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {Button, FormLabel} from "react-bootstrap";
import {RefreshProfile} from "../../actions/authActions";
import {SetAlert, setLoading} from "../../actions/common";
import SubmitModal from "./modals/SubmitModal";


const Clear = () => {
    const dispatch = useDispatch();
    const [clearModel, setClearModel] = useState<ClearBalanceModel>({
         cashValue:{
             amount:0,
             currency:"SAR"
         },
        cardValue:{
            amount:0,
            currency:"SAR"
        }

    })
    const [showModal, setShowModal] = useState(false)

    const onSubmit = async () => {
        dispatch(setLoading(true))

        const res = await ClearData(clearModel);
        if (res.succeeded) {
            dispatch(RefreshProfile());
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Money was cleared successfully",
                    title: "Success",
                    kind: "success",
                })
            );
        }
        setClearModel({
            ...clearModel,cashValue:{
                ...clearModel.cashValue,
                amount:0
            },
            cardValue:{
                ...clearModel.cardValue,
                amount:0
            },
        })
        dispatch(setLoading(false))
    }
    return (
        <>
            {showModal &&
                <SubmitModal
                    onSubmit={() => onSubmit()}
                    handleClose={() => {setShowModal(false)}}
                    show={showModal}
                />
            }
        <div>
            <div>
                <div className={"shadow rounded border py-5 mx-5 my-4"}>

                    <Row>

                        <Col md={6} className={"px-5"}>
                            <FormLabel>Cash amount</FormLabel>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    min={0}
                                    type={"number"}
                                    style={{height: 35}}
                                    value={clearModel?.cashValue?.amount}
                                    onChange={(e) => setClearModel({
                                        ...clearModel, cashValue: {
                                            ...clearModel?.cashValue, amount: Number(e.target.value)
                                        }
                                    })}
                                />
                                <InputGroup.Append style={{zIndex: 0}}>
                                    <Form.Control
                                        style={{height: 35, width: 75}}
                                        placeholder={"Currency"}
                                        disabled={true}
                                        value={clearModel?.cashValue?.currency}
                                        onChange={(e) => setClearModel({
                                            ...clearModel, cashValue: {
                                                ...clearModel?.cashValue, currency: e.target.value
                                            }
                                        })}
                                    />
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                        <Col md={6} className={"px-5"}>
                            <FormLabel>Card amount</FormLabel>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type={"number"}
                                    style={{height: 35}}
                                    // placeholder={"Card Value"}
                                    min={0}
                                    value={clearModel?.cardValue?.amount}
                                    onChange={(e) => setClearModel({
                                        ...clearModel, cardValue: {
                                            ...clearModel?.cardValue, amount: Number(e.target.value)
                                        }
                                    })}/>
                                <InputGroup.Append style={{zIndex: 0}}>
                                    <Form.Control
                                        style={{height: 35, width: 75}}
                                        disabled={true}
                                        placeholder={"Currency"}
                                        value={clearModel?.cardValue?.currency}
                                        onChange={(e) => setClearModel({
                                            ...clearModel, cardValue: {
                                                ...clearModel?.cardValue, currency: e.target.value
                                            }
                                        })}
                                    />
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                    </Row>

                    <Row className={"justify-content-end w-100 mt-5"}>
                        <div>
                            <Button onClick={() => {setShowModal(true)}} size={"lg"}>Submit</Button>
                        </div>
                    </Row>

                </div>
            </div>
        </div>
        </>
    )
}
export default Clear
export type ClearBalanceModel = {
    cashValue?: Money
    cardValue?: Money
}
type Money = {
    amount?: number
    currency?: string
}

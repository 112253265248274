import React, {useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import BTable from "react-bootstrap/Table";
import Pager from "../components/Pager";
import Card from "react-bootstrap/Card";
import AppStateModel from "../../models/appStateModel";
import {PrepareMetaDataAsync} from "../../actions/common";
import LookupModel from "../../models/lookupModel";
import {SearchCitiesAsync, setCitiesCriteria} from "../../actions/citiesActions";
import {ICityModel} from "../../models/cities";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Row} from "react-bootstrap";
import Select from "react-select";
import UpdateModal from "./updateModal";
import {BsSearch} from "react-icons/bs";
import {AiFillEdit} from "react-icons/ai";
import {SearchCities} from "../../api/data";
import AddModal from "./addModal";
import CreateModal from "./createModal";
import {CityTiersAsync} from "../../actions/cityTiersAction";


const Screen = () => {
    const pagedCities = useSelector(
        (state: AppStateModel) => state.PagedCities
    );
    const searchCitiesCriteria = useSelector(
        (state: AppStateModel) => state.SearchCitiesCriteria
    );

    const dispatch = useDispatch();
    React.useEffect(() => {
        batch(() => {
            dispatch(PrepareMetaDataAsync());
            dispatch(SearchCitiesAsync());
            dispatch(CityTiersAsync())
        })
    }, [dispatch]);

    const content = useSelector(
        (state: AppStateModel) => state.AppContent.cities
    );
    const exportToExcel = async () => {
        const res = await SearchCities(undefined, true)
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer");
        }
    }


    const stations = useSelector((state: AppStateModel) => state.Meta?.stations);

    const getStationName = (stationId: string) => {
        if (stationId != null)
            return stations?.find((x) => x.value == stationId)?.label;
        else
            return "All"
    };

    const handleKeyPress = (event: any, callback: () => any) => {
        if (event.key === "Enter") {
            callback();
        }
    };
    const customControlStyles = (base: any) => ({
        ...base,
        height: 21!,
    });
    const isServiceResolver = (value?: string) => {
        if (value == "yes") {
            setSelectedService({value: "yes", label: "Yes"})
            dispatch(SearchCitiesAsync(true))
        } else if (value == "no") {
            setSelectedService({value: "no", label: "No"})
            dispatch(SearchCitiesAsync(false))
        }
    }
    const cityTiers = useSelector((state: AppStateModel) => state.CityTiers);
    const [selectedService, setSelectedService] = React.useState<LookupModel | undefined>();

    const onClear = () => {
        dispatch(setCitiesCriteria({
            name: undefined,
            isServiced: undefined,
        }))
        setSelectedService(undefined)
        dispatch(
            SearchCitiesAsync(undefined, true)
        )
    }
    const [openModal, setOpenModal] = useState<{
        id?: string,
        title?: string,
        type: 'EDIT' | 'ADD' | 'NONE' | 'CREATE'
    }>({type: 'NONE',})
    return (
        <div style={{padding: "1vw"}}>
            {openModal.type === 'EDIT' && openModal.id && <UpdateModal id={openModal.id}
                                                                       onHide={() => setOpenModal({type: 'NONE'})}/>}
            {openModal.type === 'ADD' && openModal.id && <AddModal title={openModal.title} id={Number(openModal.id)}
                                                                   onHide={() => setOpenModal({type: 'NONE'})}/>}
            {openModal.type === 'CREATE' && <CreateModal cityTiers={cityTiers}
                                                         onHide={() => setOpenModal({type: 'NONE'})}/>}
            <Card>
                <Card.Header>
                    <Row>
                        <Col lg={2} className="px-md-4">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{height: 35}}
                                    // size="sm"
                                    placeholder={"search"}
                                    value={searchCitiesCriteria?.name ?? ""}
                                    onChange={(e) => dispatch(setCitiesCriteria({
                                        ...searchCitiesCriteria,
                                        name: e.target.value
                                    }))}
                                    onKeyPress={(e: any) =>
                                        handleKeyPress(e, () =>
                                            dispatch(
                                                SearchCitiesAsync(selectedService?.value == "yes" ? true : selectedService?.value == "no" ? false : undefined)
                                            )
                                        )
                                    }
                                />
                                <InputGroup.Append style={{zIndex: 0}}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                SearchCitiesAsync(selectedService?.value == "yes" ? true : selectedService?.value == "no" ? false : undefined)
                                            )
                                        }
                                        className="icon-button"
                                    >
                                        <BsSearch name={"search"} color={"white"} size={21}/>
                                    </Button>

                                </InputGroup.Append>
                            </InputGroup>

                        </Col>
                        <Col lg={2} className="px-md-4 mb-3 ">
                            <Select
                                key={`selectedService__${searchCitiesCriteria?.isServiced}`}
                                value={selectedService ?? {
                                    label: "Is Serviced ?",
                                    value: "Is Serviced?"
                                }}
                                styles={{control: customControlStyles}}
                                options={[{label: "Yes", value: "yes"}, {
                                    label: "No",
                                    value: "no"
                                }]}
                                isClearable
                                placeholder={"Is Serviced ?"}
                                onChange={(selectedOption) => {
                                    if (Array.isArray(selectedOption)) {
                                        throw new Error(
                                            "Unexpected type passed to ReactSelect onChange handler"
                                        );
                                    }

                                    isServiceResolver(selectedOption?.value)
                                }}
                            />
                        </Col>
                        <Col lg={1} className="px-md-2 ">
                            <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
                                <Button
                                    onClick={() => {
                                        setOpenModal({type: 'CREATE'})


                                    }}

                                >
                                    Create
                                </Button>

                                <Button
                                    onClick={() =>
                                        exportToExcel()
                                    }

                                >
                                    Export
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => {
                                        onClear()
                                    }}
                                >
                                    Clear
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <BTable striped responsive bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>{content.idPlaceholder}</th>
                            <th>{content.namePlaceholder}</th>
                            <th>Country</th>
                            <th>{content.isServicedPlaceholder}</th>
                            <th>{content.stationPlaceholder}</th>
                            <th>{content.tierName}</th>
                            <th>{content.tierUpdate}</th>
                        </tr>
                        </thead>

                        <tbody>
                        {pagedCities?.matches?.map((row: ICityModel, i: any): any => (
                            <tr key={i}>
                                <td>{row.id}</td>
                                <td>{row.name}</td>
                                <td>{row.country}</td>
                                <td>{row.isServiced ? "Yes" : "NO"}</td>
                                <td>{getStationName(row.station)}</td>
                                <td>{row.tierName}</td>
                                <td>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        columnGap: '10%'
                                    }}>
                                        <div style={{cursor: "pointer"}}
                                             onClick={() => setOpenModal({type: 'EDIT', id: row.id})}>
                                            <AiFillEdit color={"white"} size={21}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                color={'primary'}
                                                style={{cursor: "pointer"}} onClick={() => setOpenModal({
                                                type: 'ADD',
                                                id: row.id,
                                                title: row?.tierName ? 'EDIT TIER' : 'ADD TIER'
                                            })}
                                            >
                                                {row.tierName ? 'EDIT TIER' : 'ADD TIER'}
                                            </Button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </BTable>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Pager
                            currentPageNumber={searchCitiesCriteria?.pageNumber!}
                            numberOfPages={Math.ceil(
                                pagedCities?.total! / searchCitiesCriteria?.pageSize!
                            )}
                            onChange={(page: number) => {
                                dispatch(
                                    setCitiesCriteria({
                                        pageNumber: page,
                                    })
                                );
                                dispatch(SearchCitiesAsync());
                            }}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default React.memo(Screen);

import React, {ChangeEvent, useCallback, useEffect} from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useDispatch} from "react-redux";
//@ts-ignore
import SuccessBeep from "../../../res/beepsuccess.mp3";
//@ts-ignore
import FailBee from "../../../res/beepfail.mp3";
import {PrintBulkLabels, PrintLabelAsync} from "../../../actions/shipmentsActions";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {inspect} from "util";
import TextInput from "../../components/TextInput";
import useSound from "use-sound";

interface IProps {
    visible: boolean;
    onDone: () => any;
    selectedShipments?: string[];
}

const Component = (props: IProps) => {
    const [playFail] = useSound(FailBee);
    const [playSuccess] = useSound(SuccessBeep);
    const textInput = React.useRef<any>(null);

    const [scannedIds, setScannedIds] = React.useState<Array<string>>(props.selectedShipments ?? []);
    const [scannedId, setScannedId] = React.useState<string>("");
    const dispatch = useDispatch();

    const onPrint =useCallback (() => {
        dispatch(PrintBulkLabels(scannedIds))
    },[scannedIds]);

    const onKeyPress = useCallback((e: any) => {
        if (scannedId&& e.keyCode === 13 && scannedIds.length < 10 && !scannedIds.includes(scannedId)) {
            e.preventDefault();
            setScannedIds(() => ([...scannedIds, scannedId]))
            playSuccess()
            setScannedId("")
        }else {
            if(scannedIds.length===10){
                playFail()
            }
        }
    }, [scannedIds,scannedId]);

    useEffect(() => {
        textInput.current!.focus();
    }, [scannedIds])

    return (
        <Modal
            show={props.visible}
            onHide={() => {
                props.onDone()
                setScannedIds([])
            }}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Scan To Print
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{paddingTop: "3vh", paddingBottom: "3vh"}}>
                    <InputGroup className="mb-3">
                        <Form.Control
                            ref={textInput}
                            placeholder="Scan to print"
                            value={scannedId}
                            onChange={(e: ChangeEvent<any>) => setScannedId(e.target.value)}

                            onKeyDown={onKeyPress}
                            autoFocus={true}
                        />
                    </InputGroup>
                </div>
                <div>
                    <Row className={"flex align-items-center justify-content-center"}>
                        {
                            scannedIds.map(i =>
                                <Col key={i} sm={5} className={"border  rounded-xl m-1  shadow py-2 px-5"}>
                                    <h3
                                        className={"font-weight-bold"}>
                                        {i}
                                    </h3>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={scannedIds.length === 0} onClick={onPrint}>
                    Print
                </Button>
            </Modal.Footer>

        </Modal>
    );
};

export default Component;

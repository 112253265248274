import React, {useEffect, useState} from "react"
import AmsClient from "../../api/amsClient";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {ContactAddressModel, ContactModel} from "../../models/ams";
import AddressesModal from "./components/addressesModal";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import Pager from "../components/Pager";
import {setCitiesCriteria} from "../../actions/amsActions";
import CreateEditModal from "./components/createEditContactModal";
import Col from "react-bootstrap/Col";

const Ams = () => {
    const [data, setData] = useState<ContactModel[]>()
    const [openModal, setOpenModal] = useState<"NONE" | "EDIT" | "ADD">("NONE");
    const [contactToEdit, setContactToEdit] = useState<null | ContactModel>(null);
    const [totalData, setTotalData] = useState(0)
    const [addressesModal, setAddressesModal] = useState<{ show: boolean, addresses?: Partial<ContactAddressModel>[] }>({show: false})
    const dispatch = useDispatch()
    const searchAmsCriteria = useSelector(
        (state: AppStateModel) => state.SearchAmsCriteria
    );

    const refreshData = async () => {
        const res = await AmsClient.SearchContacts({
            pageNumber: searchAmsCriteria?.pageNumber,
            pageSize: searchAmsCriteria?.pageSize
        })
        if (res.succeeded) {
            setData(res.data.matches)
            setTotalData(res.data.total)
        }
    }
    useEffect(() => {
        refreshData()
    }, [])

    return (
        <Card className={"p-3 pt-2"}>

            {
                openModal === "ADD" &&
                <CreateEditModal onClose={() => setOpenModal("NONE")}/>
            }
            {
                (openModal === "EDIT" && contactToEdit) &&
                <CreateEditModal
                    contactRef={contactToEdit.id}
                    onClose={() => setOpenModal("NONE")}/>
            }
            {
                Boolean(addressesModal.show) &&
                <AddressesModal addresses={addressesModal.addresses} onClose={() => setAddressesModal({show: false})}/>
            }

            <Card.Body>
                <Row className={" mb-3 d-flex justify-content-between  px-3 shadow pt-4 pb-2 rounded border w-100"}>

                    <Col md={11}>
                        <h3>
                            Contacts
                        </h3>
                    </Col>
                    <Col md={1}>
                        <Button
                            onClick={() => setOpenModal("ADD")}
                        >
                            {"Create"}
                        </Button>
                    </Col>


                </Row>
                <Row className={"px-2"}>
                    <BTable striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th style={{width: "33%"}}>Id</th>
                            <th style={{width: "33%"}}>Mobile</th>
                            <th style={{width: "33%"}}>Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        {data &&
                            data?.map((x) => {
                                    return (
                                        <tr key={x.id}>
                                            <td>{x.id} </td>
                                            <td>{x.mobile} </td>
                                            <td>

                                                {/*<Button*/}
                                                {/*    onClick={() => {*/}
                                                {/*        setAddressesModal({show: true, addresses: x.addresses})*/}
                                                {/*    }}*/}
                                                {/*    style={{minWidth: 90}}*/}
                                                {/*    className="icon-button main-button"*/}
                                                {/*    variant="primary"*/}
                                                {/*>*/}
                                                {/*    {"View Addresses"}*/}
                                                {/*</Button>*/}
                                                <Button
                                                    onClick={() => {
                                                        setContactToEdit(x)
                                                        setOpenModal("EDIT")
                                                    }}
                                                    style={{minWidth: 90}}
                                                    className="icon-button main-button"
                                                    variant="primary"
                                                >
                                                    {"View"}
                                                </Button>


                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </BTable>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Pager
                            currentPageNumber={searchAmsCriteria?.pageNumber!}
                            numberOfPages={Math.ceil(
                                totalData! / searchAmsCriteria?.pageSize!
                            )}
                            onChange={(page: number) => {
                                dispatch(
                                    setCitiesCriteria({
                                        pageNumber: page,
                                        pageSize: 20
                                    })
                                );
                                //todo
                                refreshData()
                            }}
                        />
                    </div>
                </Row>
            </Card.Body>
        </Card>
    )
}
export default Ams
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";
import ToLocalDateTime from "../../actions/date";
import {Button} from "react-bootstrap";
import {GiFootsteps} from "react-icons/gi";
import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import TrackShipmentModal from "../shipments/components/trackShipmentModal";
import Col from "react-bootstrap/Col";
import ShipmentModel from "../../models/shipmentModel";
import ScanListWithScan from "../shipments/components/shipmentListWithScan";

import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import {ContainerJourneyStep, ContainerModel} from "../../models/containers";
import {AddShipmentFromContainerAsync, CloseContainerAsync} from "../../actions/containerActions";
import {DeactivateRouteAsync, SearchJourneysAsync} from "../../actions/routesActions";
import {SetAlert, setLoading} from "../../actions/common";
import {ContainerTracesExportToExcel, SearchShipment} from "../../api/data";
import SubmitModal from "../Cod/modals/SubmitModal";

type Props = {
    data: ContainerModel | undefined
    onHide: () => void;
    refreshData: () => void;
    onRemoveShipment: (shipmentId: string, containerId: number) => void;
    resolveEmployee: (id: (string | null)) => (string | undefined)

}
const ViewContainer: React.FC<Props> = ({
                                            resolveEmployee,
                                            data,
                                            onHide,
                                            onRemoveShipment,
                                            refreshData
                                        }) => {
    const d = useDispatch()
    const [trackShipmentId, setTrackShipmentId] = useState<string | null>(null);
    const [addOn, setAddOn] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false)
    const [shipments, setShipments] = useState<ShipmentModel[]>([])
    const meta = useSelector((state: AppStateModel) => state.Meta);
    const resolveStation = useCallback((id: number | null) => {
        if (!Boolean(id)) {
            return ''
        }
        const st = meta?.stations?.find((s) => s.value === id?.toString());
        return st?.label;
    }, [meta?.stations]);

    useEffect(() => {
        d(SearchJourneysAsync())
    }, [])
    const onAddShipments = () => {
        d(AddShipmentFromContainerAsync(data?.id!, shipments.map(i => i.id), () => {
            refreshData()
            setAddOn(false)
        }))
    }
    const onCloseContainer = () => {
        d(CloseContainerAsync(data?.id!, () => {
            refreshData()
            //   onHide()
        }))
    }

    const stations = useSelector((state: AppStateModel) => state.Meta?.stations);
    const stationName = (stationId: string) => {
        return stations?.find((x) => x.value == stationId)?.label ?? stationId;
    }

    const ExportToExcel = async () => {
        d(setLoading(true));
        const res = await ContainerTracesExportToExcel(data?.id ?? 0);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer");
        } else {
            d(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        d(setLoading(false))
    }

    const numbers = data?.containments.map(x => x.shipment.number)
    const exportShipmentToExcel = async () => {
        //@ts-ignore
        let res = await SearchShipment({ numbers}, true);
        res.succeeded && window.open(res.data.location, "_blank", "noreferrer");
    };



    return (
      <div>
          {trackShipmentId !== null && (
            <TrackShipmentModal
              visible={true}
              onHide={() => setTrackShipmentId(null)}
              shipmentId={trackShipmentId ?? ""}
            />
          )}
          {
              showModal &&
              <SubmitModal onSubmit={() => {
                  onCloseContainer()
              }}
                           handleClose={() => {
                               setShowModal(false)
                           }} show={showModal}
              />
          }


          <Modal
            show={true}
            onHide={onHide}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
          >
              <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                      {'Container'}
                  </Modal.Title>
              </Modal.Header>
              <Card.Body>

                  <Row className={"p-2"}>
                      <Col>
                          <h3>
                              ID : {data?.id}
                          </h3>
                      </Col>
                      <Col>
                          <h3>
                              Closed On :{ToLocalDateTime(data?.closedOn, 'SA')}
                          </h3>
                      </Col>
                      <Col>
                          <h3>
                              Created On
                              : {ToLocalDateTime(data?.createdOn, 'SA')}
                          </h3>
                      </Col>
                  </Row>

                  <Row className={"flex-row-reverse p-3"}>
                      {
                        (!addOn && !Boolean(data?.closedOn)) && <Button
                              style={{width: 70, height: 50}}
                              onClick={() => setAddOn(true)}
                              className="icon-button main-button"
                          >
                              {'Add'}
                          </Button>
                      }
                      {
                        !Boolean(data?.closedOn) &&
                          <Button
                              style={{width: 70, height: 50}}
                              onClick={() => {setShowModal(true)}}
                              className="icon-button main-button"
                          >
                              {'Close'}
                          </Button>
                      }
                  </Row>


                  {
                    addOn && (
                      <Col md={12} className={"px-5 py-2 border  my-2"}>
                          <Row>
                              <ScanListWithScan
                                setShipments={setShipments}
                                allowedStatuses={["20", "15", "50"]}
                              />
                          </Row>
                          <Row className={"flex-row-reverse px-3"}>
                              <Button
                                style={{ width: 72, height: 37 }}
                                onClick={onAddShipments}
                                className="icon-button main-button"
                              >
                                  {'Add'}
                              </Button>
                          </Row>
                      </Col>

                    )
                  }
                  <Row className={"py-3"}>
                      {
                          data?.journey?.sort((a, b) => a.order - b.order)?.map((i: ContainerJourneyStep) =>
                            <Col md={12}>
                                <p className={"fs-3"}>
                                    <span style={{
                                        fontSize: 16,
                                        fontWeight: "600"
                                    }}>

                                        {i.sourceStation?.name} {"=>"} {i.destinationStation?.name},{"  "}
                                        {i.order != 0 && "Arrival Time: "} {i.order != 0 && (ToLocalDateTime(i.arrivalTime, 'SA'))},
                                        {"Departure Time: "} {ToLocalDateTime(i.departureTime, 'SA')}
                                    </span>

                                </p>
                            </Col>)
                      }
                  </Row>

                  {data?.containments && data?.containments?.length > 0 &&
                      <Row className={"flex justify-content-end mt-5"}>
                          <Col md={2}>
                              <Row>
                                  <Col md={4}>
                                      <Button
                                          onClick={exportShipmentToExcel}
                                      >
                                          Export
                                      </Button>
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                  }
                  <Row className={"px-2 pt-3"}>
                      <BTable striped bordered hover size="sm">
                          <thead>
                          <tr>
                              <th>Number</th>
                              <th>Station</th>
                              <th>Staus</th>
                              <th>Consignee City</th>
                              <th>shipper City</th>
                              <th>Actions</th>
                          </tr>
                          </thead>

                          <tbody>
                          {data &&

                            data?.containments?.map((x) => {
                                  const shipment = x.shipment
                                  return (
                                    <tr key={shipment.number}>
                                        <td>{shipment.number} </td>
                                        <td>{stationName(shipment.stationId)} </td>
                                        <td>{shipment.statusLabel} </td>
                                        <td>{shipment.consignee.address?.city} </td>
                                        <td>{shipment.shipper.address?.city} </td>
                                        <td>{ToLocalDateTime(shipment.createdOn, 'SA')} </td>
                                        <td>
                                            <Button
                                              style={{ width: 42, height: 37 }}
                                              onClick={() => setTrackShipmentId(shipment.id)}
                                              className="icon-button main-button"
                                            >
                                                <GiFootsteps color={"white"}
                                                             size={21}/>
                                            </Button>
                                            {
                                              !Boolean(data?.closedOn) && <Button
                                                onClick={() =>
                                                  onRemoveShipment(shipment.id, data.id)
                                                }
                                                variant="danger"
                                                style={{ width: 42, height: 37 }}
                                                className="icon-button main-button font-weight-bold"
                                              >
                                                  {"X"}
                                              </Button>
                                            }

                                        </td>
                                    </tr>
                                  )
                              }
                            )
                          }
                          </tbody>
                      </BTable>
                  </Row>

                  {(data?.containerTraces?.length ?? 0) > 0 &&
                      <>
                  <Row className={"flex justify-content-between mt-5"}>
                      <Col lg={2} className="px-md-2">
                          <h3>Container Traces</h3>
                      </Col>
                      <Col md={2}>
                          <Row>
                              <Col md={4}>
                                  <Button
                                      onClick={ExportToExcel}
                                  >
                                      Export
                                  </Button>
                              </Col>
                          </Row>
                      </Col>
                  </Row>

                  <Row className={"px-2 pt-3"}>
                      <BTable striped bordered hover size="sm">
                          <thead>
                          <tr>
                              <th>Id</th>
                              <th>Code</th>
                              <th>Station</th>
                              <th>Created On</th>
                              <th>Created By</th>
                          </tr>
                          </thead>

                          <tbody>
                          {data && data?.containerTraces?.map((trace) => {
                                      return (
                                          <tr key={trace.id.toString()}>
                                              <td>{trace.id} </td>
                                              <td>{trace.code?.replace(/([a-z])([A-Z])/g, '$1 $2')} </td>
                                              <td>{resolveStation(trace.stationId)} </td>
                                              <td>{ToLocalDateTime(trace.createdOn, 'SA')} </td>
                                              <td>{resolveEmployee(trace.createdBy)} </td>
                                          </tr>
                                      )
                                  }
                              )
                          }
                          </tbody>
                      </BTable>
                  </Row>
                      </>}

              </Card.Body>
          </Modal>
      </div>
    )
}

export default ViewContainer;

import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import {useSelector} from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import ScanListWithScan from "./shipmentListWithScan";
import ReactSelect from "react-select";

interface IProps {
    visible: boolean;
    onHide: () => any;
    onDone: (ids: any, station: any) => any;
    title: string;
    submitButton: string;
    allowedStatuses?: string[];
}

const Component = (props: IProps) => {

    const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [stations, setStations] = React.useState<any>();
    const stationsOptions = useSelector(
      (state: AppStateModel) => state.Meta?.stations
    );
    const profile = useSelector((state: AppStateModel) => state.Profile)

    React.useEffect(() => {
        (profile.stationId != null && setStations(stationsOptions?.find((x) => x.value == profile.stationId)))
    }, []);

    const onSubmit = () => {
        let ids: string[] = [];
        shipments.forEach((s) => {
            ids.push(s.id);
        });
        props.onDone(ids, stations.value);
    };


    return (
      <Modal
        show={props.visible}
        onHide={() => props.onHide()}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
        size="xl"
      >
          <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                  {props.title}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <ScanListWithScan
                setShipments={setShipments}
                allowedStatuses={props.allowedStatuses}
              />
          </Modal.Body>
          <Modal.Footer>
              <div style={{ width: "20%" }}>
                  <ReactSelect
                    placeholder="Select station.."
                    value={stations}
                    onChange={(v) => setStations(v)}
                    options={stationsOptions}
                    isDisabled={profile.stationId != null}
                  />
              </div>
              <Button
                disabled={
                  shipments.length === 0 || loading || !stations || stations == null
                }
                onClick={onSubmit}
              >
                  {props.submitButton}
              </Button>
          </Modal.Footer>
      </Modal>
    );
};

export default Component;

import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import { useSelector } from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import ShipmentList from "./shipmentsList";
import ReactSelect from "react-select";
import { SearchShipment } from "../../../api/data";
import useSound from "use-sound";
//@ts-ignore
import SuccessBeep from "../../../res/beepsuccess.mp3";
//@ts-ignore
import FailBee from "../../../res/beepfail.mp3";

interface IProps {
  visible: boolean;
  onHide: () => any;
  onDone: (ids: any, courierId: string) => any;
  title: string;
  submitButton: string;
  allowedStatuses?: string[];
  changeButton: string;
}
const Component = (props: IProps) => {
  const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [courier, setCourier] = React.useState<any>(null);
  const [scannedId, setScannedId] = React.useState("");
  const couriers = useSelector((state: AppStateModel) => state.Meta?.couriers);

  const [playFail] = useSound(FailBee);
  const [playSuccess] = useSound(SuccessBeep);
  const [stations, setStations] = React.useState<any>();

  const onSubmit = () => {
    props.onDone(selectedIds,stations.value);
  };
  const stationsOptions = useSelector(
    (state: AppStateModel) => state.Meta?.stations
  );
  const textInput = React.useRef<any>(null);
  const profile = useSelector((state :AppStateModel)=>state.Profile)

  React.useEffect(() => {
    (profile.stationId !=null && setStations(stationsOptions?.find((x) => x.value == profile.stationId)))
	}, [profile.stationId, stationsOptions]);

  React.useEffect(() => {
    courier != null && textInput.current!.focus();
  },[courier])

  const ChangeCourier = async (courier: any) => {
    if (courier != null) {
      let shipmentsResponse = await SearchShipment(
        {
          bypassStationFilter:true,
          pageSize: 100,
          courier: courier.value,
          statuses: ["40"],
        },
        false
      );
      if (shipmentsResponse.succeeded) {
        setShipments(shipmentsResponse.data.matches);
      }
    } else {
      setShipments([]);
      setSelectedIds([]);
    }
    setCourier(courier);
    textInput.current!.focus();
  };



  const onScan = async () => {
    let shipment = shipments.find((s) => s.number == scannedId);

    if (
      shipment &&
      shipment != null &&
      shipments.find((s) => s.id == shipment?.id)
    ) {
      let ids: string[] = [];
      selectedIds.forEach((s) => {
        ids.push(s);
      });
      ids.push(shipment.id);
      setSelectedIds(ids);
      setScannedId("");
      playSuccess();
    } else {
      playFail();
    }
    textInput.current!.focus();
  };
  const onKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      // e.preventDefault(); // Ensure it is only this e that runs

      onScan();
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
          {courier === null ? (
            <ReactSelect
              value={courier}
              onChange={(v) => ChangeCourier(v)}
              options={couriers}
            />
          ) : (
            <InputGroup className="mb-3">
              <Form.Control value={courier.label} disabled={true} />
              <InputGroup.Append>
                <Button
                  onClick={() => ChangeCourier(null)}
                  className="icon-button"
                >
                  {props.changeButton}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          )}
        </div>

        {courier != null && (
          <>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Scan"
                value={scannedId}
                onChange={(e: any) => setScannedId(e.target.value)}
                disabled={loading}
                ref={textInput}
                onKeyDown={onKeyPress}
                autoFocus={true}
              />
              <InputGroup.Append>
                <Button disabled={scannedId === ""} onClick={() => onScan()}>
                  +
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <ShipmentList
              isLoading={loading}
              shipments={shipments}
              notClearable={true}
              selectedIds={selectedIds}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
      <div style={{ width: "20%" }}>
          <ReactSelect
            placeholder="Select station.."
            value={stations}
            onChange={(v) => setStations(v)}
            options={stationsOptions}
            isDisabled={profile.stationId!=null}
          />
        </div>
        <Button
          disabled={selectedIds.length === 0 || loading}
          onClick={onSubmit}
        >
          {props.submitButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Component;

import {UpdateContactAddressModel} from "../../../models/ams";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import AMSCitiesSelect from "../../components/AMSCitiesSelect";
import {Button} from "react-bootstrap";
import amsClient from "../../../api/amsClient";
import {useDispatch} from "react-redux";
import {SetAlert} from "../../../actions/common";

type Props = {
    address: Partial<UpdateContactAddressModel>
    contactId: number
    onFinish: () => void
    onAssignToShipment?: (addressId: string) => void
}
const AddressEditor: React.FC<Props> = (props) => {
    const d = useDispatch()
    const [address, setAddress] = useState<Partial<UpdateContactAddressModel>>(props.address);
    const onChange = (value: any, key: keyof UpdateContactAddressModel) => {
        setAddress((c) => ({...c, [key]: value}))
    }
    const onSaveAddress = async () => {
        const res = await amsClient.UpdateAddress(address.id!, {
            ...address,
        } as any)
        if (res.succeeded) {
            d(
                SetAlert({
                    visible: true,
                    data: "Address saved successfully",
                    title: "Address Saved",
                    kind: "success",
                })
            );
            props.onFinish()
        } else {
            d(
                SetAlert({
                    visible: true,
                    data: "Something went wrong",
                    title: "Error",
                    kind: "error",
                })
            );
        }
    }
    const onCreateAddress = async () => {
        const res = await amsClient.AddAddressToContact(props.contactId, {
            ...address,
            // references: [{
            //     type: "Manual",
            //     value: userProfile.id,
            //     note: userProfile.name
            // }]
        } as any)
        if (res.succeeded) {
            d(
                SetAlert({
                    visible: true,
                    data: "Address Created successfully",
                    title: "Address Created",
                    kind: "success",
                })
            );
            props.onFinish()
        } else {
            d(
                SetAlert({
                    visible: true,
                    data: "Something went wrong",
                    title: "Error",
                    kind: "error",
                })
            );
        }
    }
    return <div>
        <Row className={"w-100 d-flex  align-items-center border px-1 py-2 shadow rounded-lg "}>
            <Col md={2}>
                <TextInput placeholder={"Label "} onChange={(e) => onChange(e, "label")}
                           value={address.label}/>
            </Col>


            <Col md={2}>
                <TextInput placeholder={"Line 1"} onChange={(e) => onChange(e, "line1")}
                           value={address.line1}/>
            </Col>
            <Col md={2}>
                <TextInput placeholder={"Line 2"} onChange={(e) => onChange(e, "line2")}
                           value={address.line2}/>
            </Col>
            <Col md={2}>
                <TextInput placeholder={"Line 3"} onChange={(e) => onChange(e, "line3")}
                           value={address.line3}/>
            </Col>
            <Col md={2}>
                <TextInput placeholder={"Latitude"} onChange={(e) => onChange(e, "latitude")}
                           value={address.latitude}/>
            </Col>
            <Col md={2}>
                <TextInput placeholder={"Longitude "} onChange={(e) => onChange(e, "longitude")}
                           value={address.longitude}/>
            </Col>

            <Col md={2}>
                <AMSCitiesSelect
                    value={String(address?.cityId) ?? ""}
                    onChange={(val: any) => {
                        onChange(val, "cityId")
                    }}
                    placeholder={"City"}
                />
            </Col>
            <Col md={2}>
                <TextInput disabled placeholder={"Country code"}
                           value={address.countryCode}></TextInput>

            </Col>
            <Col>
                {
                    address.new && <Button onClick={() => onCreateAddress()}>
                        Create
                    </Button>
                }
                {
                    address.id && <Button onClick={onSaveAddress}>
                        Save
                    </Button>
                }
                {
                    (address.id && props.onAssignToShipment) &&
                    <Button className={"mx-2"} onClick={() => props.onAssignToShipment!(address.id?? '')}>
                        Assign To Shipment
                    </Button>
                }
            </Col>

        </Row>
        <Row>

        </Row>
    </div>
}
export default AddressEditor

import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import BTable from "react-bootstrap/Table";
import AppStateModel from "../../../models/appStateModel";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import {setShipmentDetailsAsync} from "../../../actions/shipmentsActions";
import {AddressModel, ContactModel,} from "../../../models/common/commonModels";
import ToLocalDateTime from "../../../actions/date";

interface IProps {
    visible: boolean;
    onHide: () => any;
    shipmentId: string;
}

const Page = (props: IProps) => {
    const content = useSelector(
      (state: AppStateModel) => state.AppContent.shipments.shipmentTrackModel
    );
    const countryCode = useSelector(
      (state: AppStateModel) => state.Profile.currentTenant?.countryCode
    );
    const shipment = useSelector(
      (state: AppStateModel) => state.SelectedShipment
    );
    const stations = useSelector((state: AppStateModel) => state.Meta?.stations);
    const employees = useSelector((state: AppStateModel) => state.Meta?.employees);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setShipmentDetailsAsync(props.shipmentId));
    }, [dispatch, props.shipmentId]);

    const stationName = useCallback((stationId: string) => {
        return stations?.find((x) => x.value == stationId)?.label ?? stationId;
    }, [stations]);

    const camelToSpace = (text: string) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const parseComment = useCallback((status: string, comment: string | number | undefined | null) => {
        const urls = (comment as string)?.split(',');
        if (!comment) {
            return ""
        }

        if ((comment as string).startsWith("http") && urls && urls?.length > 1) {
            return (
                <div>
                    {urls?.map((url, index) => (
                        <a key={index} className="d-block"  target="_blank" href={url}>
                            {"POD"}
                        </a>
                    ))}
                </div>
            );
        }
        if ((comment as string).startsWith("http")) {
            //@ts-ignore
            return comment?.split(',').map((url, index) => (
                <a key={index} target="_blank" href={url}>
                    {"POD"}
                </a>
            ));
        }
        if ((comment as string).startsWith("http")) {
            return <a target="_blank" href={comment as string}>
                {"POD"}
            </a>
        }
        if (new RegExp("[\\da-fA-F]{32}").test(comment as string)) {
            return employees?.find(x => x.value == comment)?.label ?? comment
        }
        const stationStatus = ["Received", "ReceivedInTransit"]
        if (stationStatus.includes(status) && new RegExp("(^\\d{1,2}$)").test(comment as string)) {

            return stationName(comment.toString())
        }
        return comment;
    }, [stationName, employees])


    const renderAddress = useCallback((address?: AddressModel) => {
        return (
          <>
              <p className="mb-0">
                  <b>{content.address?.country}:</b> {address?.country}
              </p>
              <p className="mb-0">
                  <b>{content.address?.city}:</b> {address?.city}
              </p>
              <p className="mb-0">
                  <b>{content.address?.addressLine1}:</b> {address?.addressLine1}
              </p>
              <p className="mb-0">
                  <b>{content.address?.addressLine2}:</b> {address?.addressLine2}
              </p>
              <p>
                  <b>{content.address?.addressLine3}:</b> {address?.addressLine3}
              </p>
          </>
        );
    }, []);
    const renderContact = useCallback((contact?: ContactModel) => {
        return (
          <>
              <p className="mb-0">
                  <b>{content.contact.name}:</b> {contact?.name}
              </p>
              <p className="mb-0">
                  <b>{content.contact.email}:</b> {contact?.email}
              </p>
              <p className="mb-0">
                  <b>{content.contact.phone}:</b> {contact?.phone}
              </p>
              <p className="mb-0">
                  <b>{content.contact.secondPhone}:</b> {contact?.secondPhone}
              </p>
          </>
        );
    }, []);

    return (
      <Modal
        show={props.visible}
        onHide={() => props.onHide()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="xl"
      >
          <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                  {content.title}
              </Modal.Title>
          </Modal.Header>
          {shipment != null && (
            <Modal.Body>
                <Row className="trace-page-titles">
                    <Col>
                        <p>
                            <b>{content.shipmentNumberLabel}:</b> {shipment?.number}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            <b>{content.uniqueReferenceLabel}:</b>{" "}
                            {shipment?.uniqueReference}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            <b>Scheduled Delivery Date:</b>{" "}
                            {shipment?.scheduledDeliveryDate}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            <b>Pickup Date:</b>{" "}
                            {shipment?.pickupDate}
                        </p>
                    </Col>

                    <Col>
                        <p>
                            <b>{content.customerLabel}:</b> {shipment?.customerName}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            <b>{content.stationId}:</b> {stationName(shipment.stationId)}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            <b>{"Status reason"}:</b> {shipment.statusReason}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <p className="trace-page-titles">
                                    <b>{content.shipperTitle}</b>
                                </p>
                                {renderAddress(shipment?.shipper?.address)}
                                {renderContact(shipment?.shipper?.contact)}
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <Card.Body>
                                <p className="trace-page-titles">
                                    <b>{content.consigneeTitle}</b>
                                </p>
                                {renderAddress(shipment?.consignee?.address)}
                                {renderContact(shipment?.consignee?.contact)}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <p className="trace-page-titles">
                                    <b>{content.commodityTitle}</b>
                                </p>
                                <p className="mb-0">
                                    <b>{content.commodity?.weight}:</b>{" "}
                                    {shipment?.commodity?.weight}
                                </p>
                                <p className="mb-0">
                                    <b>{content.commodity?.dimensions}:</b>{" "}
                                    {shipment?.commodity?.dimensions}
                                </p>
                                <p className="mb-0">
                                    <b>{content.commodity?.description}:</b>{" "}
                                    {shipment?.commodity?.description}
                                </p>
                                <p className="mb-0">
                                    <b>{content.commodity?.cod}:</b> {shipment?.commodity?.cod}
                                </p>
                                <p className="mb-0">
                                    <b>{content.commodity?.numberOfPieces}:</b>{" "}
                                    {shipment?.commodity?.numberOfPieces}
                                </p>
                                <p className="mb-0">
                                    <b>{content.category}:</b>{" "}
                                    {camelToSpace(shipment?.category ?? '')}
                                </p>
                                <p className="mb-0">
                                    <b>{content.deliveryService}:</b>{" "}
                                    {camelToSpace(shipment?.deliveryService ?? '')}
                                </p>
                                <p>
                                    <b>{'Attached Images Urls: '}</b>{" "}
                                    <br/>
                                    {shipment?.imageUrls?.map((i, index) => {
                                        return <a href={i}
                                                  target="_blank">{`Image ${index + 1}`} </a>
                                    })}
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <BTable className="mx-3" striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>{content.trace.index}</th>
                            <th>{content.trace.date}</th>
                            <th>{content.trace.activity}</th>
                            <th>{content.trace.comment}{" 1"}</th>
                            <th>{content.trace.comment}{" 2"}</th>
                            <th>{content.trace.comment}{" 3"}</th>

                            <th>{content.trace.by}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {shipment?.traces?.map((t, index) => (
                          <tr>
                              <td>{index + 1}</td>
                              <td>{ToLocalDateTime(t.date, countryCode!)}</td>
                              <td>{t.activity}</td>
                              <td>{parseComment(t.activity, t.comment1)}</td>
                              <td>{parseComment(t.activity, t.comment2)}</td>
                              <td>{parseComment(t.activity, t.comment3)}</td>
                              <td>{t.byName}</td>
                          </tr>
                        ))}
                        </tbody>
                    </BTable>
                </Row>
            </Modal.Body>
          )}
      </Modal>
    );
};

export default React.memo(Page);

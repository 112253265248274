 
 export const Admin:IJobRole= {label : "Admin" , value : 101} 
 export const OpsManager:IJobRole= {label : "OpsManager" , value : 111} 
 export const OpsOfficer: IJobRole={label : "OpsOfficer" , value : 131} 
 export const OpsSupervisor: IJobRole={label : "OpsSupervisor" , value : 121} 
 export const Accountant:IJobRole= {label : "Accountant" , value : 132} 
 export const Cashier:IJobRole= {label : "Cashier" , value : 133} 
 export const CustomerServiceManager: IJobRole={label : "CustomerServiceManager" , value : 112} 
 export const CustomerService:IJobRole= {label : "CustomerService" , value : 122} 
 export const Sales:IJobRole= {label : "Sales" , value : 134} 
 export const Courier:IJobRole= {label : "Courier" , value : 201} 
 export const CustomerAdmin:IJobRole= {label : "CustomerAdmin" , value : 301} 

export interface IJobRole{
  label:string
  value:number
}
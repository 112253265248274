import {SetAlert, setLoading} from "./common";
import {CityTiers, CreateCityTier, CreateStation, SearchStations} from "../api/data";
import {SET_CITY_TIERS, SET_STATIONS} from "../store/types";
import {CityTiersModel} from "../models/cityTiersModel ";
import {StationsModel} from "../models/StationsModel";
import PagedModel from "../models/pagedModel";

export const CityTiersAsync = () => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await CityTiers();

        if (res.succeeded) {
            dispatch(setCityTier(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data:res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};
export const CreateCityTierAsync = (station:CityTiersModel,onDone:()=>any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await CreateCityTier(station);
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Tier was created successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onDone();
        } else {
            dispatch(SetAlert({
                visible: true,
                data:res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};
export const setCityTier = (cityTiersResult:PagedModel<CityTiersModel> ) => {
    return {
        type: SET_CITY_TIERS,
        payload: cityTiersResult
    };
};

import React from "react";
import {Button, Modal} from "react-bootstrap";

interface IProps{
    onHide:()=>void
    onClick:()=>void
}

const ConfirmModal=(props:IProps)=>{
    return (
            <Modal show={true} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to confirm this customer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={props.onClick}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}
export default ConfirmModal

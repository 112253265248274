import PagedModel from "../../../models/pagedModel";
import {DraftModel} from "../../../models/DraftModel";
import React from "react";
import BTable from "react-bootstrap/Table";
import ToLocalDateTime from "../../../actions/date";
import {useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";

interface IProps {
    data: PagedModel<DraftModel>

}

const DraftsTable = (props: IProps) => {
    const currentTenant = useSelector((state: AppStateModel) => state.Profile.currentTenant);

    return (<BTable striped bordered hover size="xs">
        <thead>
        <tr>

            <th style={{border: 0}}>{"Total:"} {props.data != null ? props.data?.total : 0}</th>

        </tr>
        <tr>

            <th>{"Draft Id"}</th>
            <th>{"Count"}</th>
            <th>{"Created On"}</th>
            <th>{"Actions"}</th>


        </tr>
        </thead>

        <tbody className={"px-2"}>


        {props.data.matches.map((row: DraftModel, key) => (
            <tr key={key}>
                <td>
                    {row.id}
                </td>
                <td>
                    {row.count}
                </td>
                <td>
                    {ToLocalDateTime(row.createdOn, currentTenant?.countryCode!)}
                </td>
                <td>
                </td>

            </tr>))}
        </tbody>
    </BTable>)
}
export default DraftsTable;

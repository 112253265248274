import {SetAlert, setLoading} from "./common";
import {
    AddContainment,
    CheckInContainer,
    CheckOutContainer,
    CloseContainer,
    CreateContainer,
    RemoveContainment, SearchContainers
} from "../api/data";
import {SET_CONTAINERS, SET_CONTAINERS_CRITERIA} from "../store/types";
import {ContainerModel, SearchContainerCriteria} from "../models/containers";
import PagedModel from "../models/pagedModel";


export const SearchContainersAsync = () => {
    return async (dispatch: any,getState:any) => {
        const state=getState()
        dispatch(setLoading(true));

        const res = await SearchContainers({...state.SearchContainersCriteria});

        if (res.succeeded) {
            dispatch(searchContainers(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data:res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};


export const CreateContainerAsync = (shipmentIds: string[], transferTo: number, onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await CreateContainer(transferTo);
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return;
        }

        const containerId = res.data?.containerId
        if (!containerId) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return;
        }

        const addShipmentsRes = await AddContainment(containerId, shipmentIds)
        if (!addShipmentsRes.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        dispatch(
          SetAlert({
              visible: true,
              data: "",
              title: "Container Created",
              kind: "success",
          })
        );
        onComplete()

        dispatch(setLoading(false));
    };
};

export const CloseContainerAsync = (containerId: number, onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await CloseContainer(containerId)
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        dispatch(
          SetAlert({
              visible: true,
              data: "",
              title: "Container Closed",
              kind: "success",
          })
        );
        window.open(res.data.location, "_blank", "noreferrer");

        onComplete()

        dispatch(setLoading(false));
    };
};

export const CheckInContainerAsync = (containerId: number, stationId: number, onComplete: () => void, onFail: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await CheckInContainer(containerId, stationId)
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            onFail()
            dispatch(setLoading(false));
            return
        }
        dispatch(
          SetAlert({
              visible: true,
              data: "",
              title: "Container Checked in",
              kind: "success",
          })
        );
        onComplete()

        dispatch(setLoading(false));
    };
};


export const CheckOutContainerAsync = (containerId: number, stationId: number, onComplete: () => void, onFail: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await CheckOutContainer(containerId, stationId)
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  data: res.data!,
                  visible: true,
                  title: "Error",
                  kind: "error",
              })
            );
            onFail()
            dispatch(setLoading(false));
            return
        }
        dispatch(
          SetAlert({
              visible: true,
              data: "",
              title: "Container Checked Out ",
              kind: "success",
          })
        );
        onComplete()

        dispatch(setLoading(false));
    };
};


export const AddShipmentFromContainerAsync = (containerId: number, shipmentIds: string[], onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await AddContainment(containerId, shipmentIds)
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        dispatch(
          SetAlert({
              visible: true,
              data: "",
              title: "Container Created",
              kind: "success",
          })
        );
        onComplete()

        dispatch(setLoading(false));
    };
};
export const RemoveShipmentFromContainerAsync = (shipmentId: string, containerId: number, onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));


        const res = await RemoveContainment(containerId, shipmentId)
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        dispatch(
          SetAlert({
              visible: true,
              data: "",
              title: "Remove Containment",
              kind: "success",
          })
        );
        onComplete()

        dispatch(setLoading(false));
    };
};

export const searchContainers = (searchContainersResult: PagedModel<ContainerModel>) => {
    return {
        type: SET_CONTAINERS,
        payload: {
            searchContainersResult,
        },
    };
};


export const setContainersCriteria = (filters: SearchContainerCriteria) => {
    return {
        type: SET_CONTAINERS_CRITERIA,
        payload: {
            filters,
        },
    };
};



import React, {useEffect} from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useDispatch} from "react-redux";
//@ts-ignore
import SuccessBeep from "../../../res/beepsuccess.mp3";
//@ts-ignore
import FailBee from "../../../res/beepfail.mp3";
import {PrintLabelAsync} from "../../../actions/shipmentsActions";

interface IProps {
    visible: boolean;

    onDone: () => any;


}

const Component = (props: IProps) => {

    const [loading, setLoading] = React.useState(false);
    const [scannedId, setScannedId] = React.useState("");

    const dispatch = useDispatch();

    const textInput = React.useRef<any>(null);

    const onScan =  () => {
        dispatch(PrintLabelAsync(scannedId, true))
    };

    const onKeyPress = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onScan();
            setScannedId("")
        }
    };

    useEffect(()=>{
        textInput.current!.focus();
    },[scannedId])

    return (
        <Modal
            show={props.visible}
            onHide={() => props.onDone()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Scan To Print
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{paddingTop: "2vh", paddingBottom: "2vh"}}>
                    <InputGroup className="mb-3">
                        <Form.Control
                            ref={textInput}
                            placeholder="Scan to print"
                            value={scannedId}
                            onChange={(e: any) => setScannedId(e.target.value)}
                            disabled={loading}
                            onKeyDown={onKeyPress}
                            autoFocus={true}
                        />
                        <InputGroup.Append>
                            <Button disabled={scannedId === ""} onClick={() => onScan()}>
                                Print
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </div>

            </Modal.Body>

        </Modal>
    );
};

export default Component;

import React, {useCallback, useState} from "react";
import Col from "react-bootstrap/Col";
import MemberSelect from "./memberSelect";
import AccountInfo from "./AccountInfo";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import {GetShipmentsToBeCollected, TransferData} from "../../api/data";
import {CodCollectionData} from "./cod";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {Button, FormLabel} from "react-bootstrap";
import {RefreshProfile} from "../../actions/authActions";
import {SetAlert, setLoading} from "../../actions/common";
import SubmitModal from "./modals/SubmitModal";


const Transfer = () => {
    const [showModal, setShowModal] = useState(false)

    const dispatch = useDispatch();
    const cashiers = useSelector((x: AppStateModel) => x.Meta?.cashiers)
    const [codData, setCodData] = useState<CodCollectionData>({shipments: [], member: null})
    const [transferModel, setTransferModel] = useState<TransferBalanceModel>({
        fromId: codData.member?.id, cardValue: {
            currency: "SAR",
            amount: 0
        }, cashValue: {
            currency: "SAR",
            amount: 0
        }
    })
    const [fromId, setFromId] = useState<string>("")
    const onMemberSelect = useCallback(async (id: string) => {
        const res = await GetShipmentsToBeCollected(id, false);
        if (res.succeeded) {
            setCodData(res.data)
            setTransferModel({fromId: res.data.member.id})
        }
    }, [])

    const onSubmit = async () => {
        dispatch(setLoading(true))
        if ((transferModel.cashValue?.amount || transferModel.cardValue?.amount) && codData.member?.balance) {
            if ((transferModel.cashValue?.amount ?? 0) <= codData.member.balance.cash.amount
                && (transferModel.cardValue?.amount ?? 0) <= codData.member.balance.cardReceiptWorth.amount) {
                const res = await TransferData(transferModel);
                if (res.succeeded) {
                    dispatch(RefreshProfile());
                    await onMemberSelect(fromId)
                    dispatch(
                        SetAlert({
                            visible: true,
                            message: "Money was transferred successfully",
                            title: "Success",
                            kind: "success",
                        })
                    );

                }
            } else {
                dispatch(
                    SetAlert({
                        visible: true,
                        message: "Cash or Card value transferred are more than what the Cashier has",
                        title: "Error",
                        kind: "error",
                    })
                );
            }

        }
        setTransferModel({
            ...transferModel, cashValue: {
                ...transferModel.cashValue, amount: 0,
            },
            cardValue: {
                ...transferModel.cardValue,
                amount: 0
            }
        })
        dispatch(setLoading(false))
    }


    return (
        <>
            {showModal &&
                <SubmitModal
                    onSubmit={() => onSubmit()}
                    handleClose={() => {
                        setShowModal(false)
                    }}
                    show={showModal}
                />
            }
            <div>
                <div>
                    <Row className={"d-flex justify-content-between mt-3"}>
                        <Col md={6}>
                            <MemberSelect placeHolder={"Cashier"} options={cashiers} onChange={async (e) => {
                                await onMemberSelect(e)
                                setFromId(e)
                            }}/>
                        </Col>
                        <Col md={6}>
                            {
                                codData.member && <AccountInfo member={codData.member}/>
                            }
                        </Col>
                    </Row>
                    {codData.member && <div className={"shadow rounded border py-5 mx-5 my-4"}>

                        <Row>

                            <Col md={6} className={"px-5"}>
                                <FormLabel>Cash amount</FormLabel>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        min={0}
                                        max={codData.member.balance?.cash.amount}
                                        type={"number"}
                                        style={{height: 35}}
                                        value={transferModel?.cashValue?.amount}
                                        onChange={(e) => {
                                            setTransferModel({
                                                ...transferModel, cashValue: {
                                                    ...transferModel?.cashValue, amount: Number(e.target.value)
                                                }
                                            })
                                        }
                                        }
                                    />
                                    <InputGroup.Append style={{zIndex: 0}}>
                                        <Form.Control
                                            style={{height: 35, width: 50}}
                                            placeholder={"Currency"}
                                            value={transferModel?.cashValue?.currency}
                                            disabled={true}
                                            onChange={(e) => setTransferModel({
                                                ...transferModel, cashValue: {
                                                    ...transferModel?.cashValue, currency: e.target.value
                                                }
                                            })}
                                        />
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>

                            <Col md={6} className={"px-5"}>
                                <FormLabel>Card amount</FormLabel>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        min={0}
                                        type={"number"}
                                        style={{height: 35}}
                                        // placeholder={"Card Value"}
                                        value={transferModel?.cardValue?.amount}
                                        onChange={(e) => setTransferModel({
                                            ...transferModel, cardValue: {
                                                ...transferModel?.cardValue, amount: Number(e.target.value)
                                            }
                                        })}/>
                                    <InputGroup.Append style={{zIndex: 0}}>
                                        <Form.Control
                                            style={{height: 35, width: 50}}
                                            placeholder={"Currency"}
                                            disabled={true}
                                            value={transferModel?.cardValue?.currency}
                                            onChange={(e) => setTransferModel({
                                                ...transferModel, cardValue: {
                                                    ...transferModel?.cardValue, currency: e.target.value
                                                }
                                            })}
                                        />
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>

                        </Row>

                        <Row className={"justify-content-end w-100 mt-5"}>
                            <div>
                                <Button onClick={() => {
                                    setShowModal(true)
                                }} size={"lg"}>Submit</Button>
                            </div>
                        </Row>

                    </div>}
                </div>
            </div>
        </>

    )
}
export default Transfer

export type TransferBalanceModel = {
    cashValue?: Money
    cardValue?: Money
    fromId?: string
}
type Money = {
    amount?: number
    currency?: string
}

import {SetAlert, setLoading} from "./common";
import {CreateDraft, CreateShipment, SearchCustomers, SearchDraft} from "../api/data";
import PagedModel from "../models/pagedModel";
import {DraftModel, SearchDraftCriteria} from "../models/DraftModel";
import {SET_DRAFTS, SET_DRAFTS_CRITERIA} from "../store/types";
import AppState from "../models/appStateModel";

export const CreateDraftBatchAsync = (count:any,onDone:()=>any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await CreateDraft(count);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer");

            dispatch(
                SetAlert({
                    visible: true,
                    message: "Draft Batch is created successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onDone();
        } else {
            dispatch(SetAlert({
                visible: true,
                data:res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};
export const SearchDraftBatchesAsync = () => {
    return async (dispatch: any,getState:any) => {
        const state=getState()
        dispatch(setLoading(true));

        const res = await SearchDraft(state.DraftBatchesCriteria);

        if (res.succeeded) {

            dispatch(searchDrafts(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data:res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};
export const searchDrafts = (searchDraftsResult: PagedModel<DraftModel>) => {
    return {
        type: SET_DRAFTS,
        payload: {
            searchDraftsResult,
        },
    };
};
export const setDraftsCriteria = (filters: SearchDraftCriteria) => {
    return {
        type: SET_DRAFTS_CRITERIA,
        payload: {
            filters,
        },
    };
};

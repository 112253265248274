import {SET_AMS_CITIES, SET_AMS_CRITERIA} from "../store/types";
import {ISearchAmsModel} from "../models/ams";
import {SetAlert, setLoading} from "./common";
import amsClient from "../api/amsClient";

export const setCitiesCriteria = (filters: ISearchAmsModel) => {
    return {
        type: SET_AMS_CRITERIA,
        payload: {
            filters,
        },
    };
};
export const setAMCities = (cites: Array<{ label: string, value: string }>) => {
    return {
        type: SET_AMS_CITIES,
        payload: cites
    };
};

export const SetCitiesMetaDataAsync = () => {
    return async (dispatch: any, getState: any) => {
        const state = getState()

        let res = await amsClient.GetCitiesMeta();

        if (res.succeeded) {
            dispatch(setAMCities(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};
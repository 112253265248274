import React, {useState} from "react";
import {PrepareMetaDataAsync} from "../../actions/common";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  SearchStationsAsync,
  setStationsCriteria
} from "../../actions/stationsActions";
import CreateStation from "./components/createStation";
import StationsTable from "./components/stationsTable";
import {BsSearch} from "react-icons/bs";

export const Stations = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(PrepareMetaDataAsync());
    dispatch(SearchStationsAsync());
  }, [dispatch]);
  const data = useSelector((state: AppStateModel) => state.PagedStations);
  const criteria = useSelector((state: AppStateModel) => state.StationsCriteria);
  const [modalOn, setModalOn] = useState<modalNames>("none")
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.stations
  );
  type modalNames =
    | "create"
    | "none";
  const renderModal = (val: modalNames) => {
    switch (val) {
      case "create":
        return (
          <CreateStation
            submitButton={"Submit"}
            visible={true}
            title={"Create Station"}
            onHide={() => {
              dispatch(SearchStationsAsync());
              setModalOn("none");
            }}
          />
        );
    }
  }
  const OnClear = () => {
    dispatch(setStationsCriteria({ name: "", city: "", country: "" }));
    dispatch(SearchStationsAsync());
  }
  return (
    <div style={{ padding: "1vw" }}>
      {renderModal(modalOn)}
      <Card>
        <Card.Header>
          <Row>
            <Col lg={2} className="px-md-2">
              <InputGroup className="mb-1">
                <Form.Control
                  style={{ height: 35 }}
                  placeholder={content.namePlaceholder}
                  value={criteria?.name}
                  onChange={(e) => dispatch(setStationsCriteria({
                    ...criteria,
                    name: e.target.value
                  }))}

                />
                <InputGroup.Append>
                  <Button
                    onClick={() =>
                      dispatch(
                        SearchStationsAsync()
                      )
                    }
                    className="icon-button"
                  >
                    <BsSearch name={"search"} color={"white"} size={21}/>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
          <Row className="px-3 p-2 d-flex justify-content-between">
            <Button variant="danger" onClick={() => OnClear()}>Clear</Button>
            <Button onClick={() => setModalOn("create")}>Create</Button>

          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col}>
              <Row/>
            </Form.Group>
          </Row>
          <Row className={"px-4"}>
            <Col md={5}>
              <Row> <StationsTable data={data}/>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </div>
  )
}

export default Stations

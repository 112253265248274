import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import BTable from "react-bootstrap/Table";
import ShipmentModel, {TraceModel} from "../../models/shipmentModel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import {CollectCodAsync} from "../../actions/shipmentsActions";
import SubmitModal from "./modals/SubmitModal";

interface IProps {
    shipments: ShipmentModel[] | null
    courierId: string;
    onCollected: () => void
}

const BalanceSheet: FunctionComponent<IProps> = ({shipments, courierId, onCollected}) => {
    const content = useSelector(
        (state: AppStateModel) => state.AppContent.shipments
    );
    const dispatch = useDispatch()
    const [shipmentsForCollection, setShipmentsForCollection] = useState<ShipmentModel[]>([])
    useEffect(() => {
        if (shipments) {
            setShipmentsForCollection(shipments)

        }
    }, [shipments])
    const totalCodAmount = useMemo(() => {
            return shipmentsForCollection?.length > 0 ? shipmentsForCollection
                ?.map(x => Number(x.commodity.cod))
                .reduce((x, y) => x + y) : 0
        },
        [shipmentsForCollection])

    const onRemoveItem = useCallback((shipId: string) => {
        setShipmentsForCollection((ships) => ([...ships.filter(x => x.id != shipId)]))
    }, [])
    const [showModal, setShowModal] = useState(false)

    const onCodCollected = useCallback(() => {
        const ids: Array<string> = shipmentsForCollection?.map(x => x.id)
        dispatch(
            CollectCodAsync(ids, courierId, () => onCollected())
        );
    }, [shipmentsForCollection])

    console.log(shipments, 'shipments')
    return (
        <>
            {showModal &&
                <SubmitModal
                    onSubmit={() => onCodCollected()}
                    handleClose={() => {setShowModal(false)}}
                    show={showModal}
                />
            }

            <div className="p-5 mx-5 shadow rounded border">
                <BTable striped responsive bordered hover>
                    <thead>
                    <tr>
                        <th style={{border: 0}}></th>
                        <td style={{border: 0}}/>
                        <th style={{border: 0}}></th>
                        <th style={{fontSize: '15px'}}>Total: {shipmentsForCollection.length} Shipments</th>
                        <th style={{border: 0}}></th>
                        <th style={{fontSize: '15px'}}>Total: {totalCodAmount}$</th>
                    </tr>
                    <tr>
                        <th>{content.referencesHeader}</th>
                        <th>{content.commodityDetailsheader}</th>
                        <th>{content.creationDateHeader}</th>
                        <th>{content.deliveryDateHeader}</th>
                        <th>{content.consigneeCity}</th>
                        <th>{content.customerHeader}</th>
                        <th>{content.codHeader}</th>
                        <th>{"Payment Method"}</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {shipmentsForCollection?.map((row: ShipmentModel): any => (
                        <tr key={row.id}>
                            <td>
                                <Col>
                                    <Row>
                                        {content.model.number}: {row.number}
                                    </Row>
                                    <Row>
                                        {content.model.uniqueReference}:{" "}
                                        {row.uniqueReference}
                                    </Row>
                                </Col>
                            </td>
                            <td>
                                <Col>
                                    <Row>
                                        {content.model.commodity.weight}:{" "}
                                        {row.commodity.weight}
                                    </Row>
                                    <Row>
                                        {content.model.commodity.dimensions}:{" "}
                                        {row.commodity.dimensions ?? "N/A"}
                                    </Row>
                                </Col>
                            </td>
                            <td>
                                <Col>
                                    <Row>
                                        {row.createdOn}
                                    </Row>
                                </Col>
                            </td>
                            <td>
                                <Col>
                                    {row.traces?.map((trace: TraceModel) => {
                                        if (trace.activity == 'Delivered') {
                                            return (
                                                <Row>
                                                    {trace?.date?.toString()}
                                                </Row>
                                            )
                                        }
                                    })}

                                </Col>
                            </td>


                            <td>
                                <Col>
                                    <Row>
                                        {row.consignee.address?.city}
                                    </Row>
                                </Col>
                            </td>
                            <td>{row.customerName}</td>
                            <td>{row.commodity.cod}</td>
                            <td>{row.paymentMethod}</td>

                            <td>
                                <Button
                                    variant="danger"
                                    className=""
                                    onClick={() => onRemoveItem(row.id)}
                                >
                                    {"X"}
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </BTable>
                <div className={"d-flex flex-row-reverse"}>
                    <Button onClick={() => {setShowModal(true)}}>{"Collect"}</Button>
                </div>
            </div>
        </>
    )
}

export default React.memo(BalanceSheet)
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AppStateModel from "../../../models/appStateModel";


import {
  GetCustomerAsync,
  UpdatePasswordAsync,
} from "../../../actions/customersActions";
import IUpdatePassword from "../../../models/customers/updatePasswordModel";

interface IProps {
  userId: string;
  visible: boolean;
  onHide: () => any;
}
const Component = (props: IProps) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(GetCustomerAsync(customer.id!));
  // }, []);

  const content = useSelector(
    (state: AppStateModel) => state.AppContent.customers.newCustomer
  );
  const [customer, setCustomer] = useState<IUpdatePassword>({
    id: props.userId,
    newPassword:""
  });
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

  const onSubmit = () => {
    let validations: string[] = [];

    !customer.newPassword && customer.newPassword == null && validations.push("Invalid Password");

    if (validations.length > 0) {
      setValidationErrors(validations);
    } else {
      dispatch(UpdatePasswordAsync(customer, () => props.onHide()));
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {"Change Password"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <Form.Label>New Password : </Form.Label>
              <TextInput
                value={customer.newPassword!}
                onChange={(val) => setCustomer({ ...customer, newPassword: val })}
                placeholder={"Type here..."}
                required={true}
                invalidMessage={content.password.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e == "Invalid Password") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <div
                style={{
                  paddingTop: 21,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={() => onSubmit()} variant="light">
                  {content.submit}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

import {SET_ALERT, SET_LOADING, SET_META} from "../store/types";
import {
    GetCategories,
    GetCities, GetDeliveryServices,
    GetJobRoles, GetPartners,
    GetStations,
    GetStatuses,
    GetStatusReasons,
    GetTraceStatuses,
    SearchCustomers,
    SearchMembers,
} from "../api/data";
import LookupModel from "../models/lookupModel";
import MetaModel from "../models/metaModel";
import AppStateModel, {AlertModel} from "../models/appStateModel";
import {CustomerAdmin} from "../models/jobRole";
import {SetCitiesMetaDataAsync} from "./amsActions";

export const setLoading = (isLoading: boolean, message?: string) => {
    return {
        type: SET_LOADING,
        payload: {
            isLoading,
            message,
        },
    };
};

export const PrepareMetaDataAsync = () => {
    return async (dispatch: any, getState: any) => {
        const state: AppStateModel = getState();
        dispatch(SetCitiesMetaDataAsync())

        if (state.Profile.jobRole && state.Profile.jobRole != CustomerAdmin.label) {

            const _customersRes = SearchCustomers({lookup: true}, false);
            const _couriersRes = SearchMembers({
                jobRole: 201,
                lookup: true,
            });
            const _employees = SearchMembers({
                lookup: true,
            });
            const _cashiers = SearchMembers({
                jobRole: 133,
                lookup: true,
            });

            const [customersRes, couriersRes, employees, cashiers] = await Promise.all([_customersRes, _couriersRes, _employees, _cashiers])
            if (customersRes.succeeded) {
                dispatch(setMeta({customers: toLookup(customersRes.data)}));
            }

            if (couriersRes.succeeded) {
                dispatch(setMeta({couriers: toLookup(couriersRes.data)}));
            }

            if (couriersRes.succeeded) {
                dispatch(setMeta({employees: toLookup(employees.data)}));
            }
            if (couriersRes.succeeded) {
                dispatch(setMeta({cashiers: toLookup(cashiers.data)}));
            }
        }


        const _jobRoles = GetJobRoles();
        const _holdReasons = await GetStatusReasons();
        const _traceStatusesRes = await GetTraceStatuses();
        const _statusesRes = await GetStatuses();
        const _citiesRes = await GetCities();
        const _partnersRes = await GetPartners();
        const _stationsRes = await GetStations();
        const _categoriesRes = await GetCategories();
        const _deliveryServices = await GetDeliveryServices();

        const [jobRoles, holdReasons, traceStatusesRes, statusesRes, citiesRes, stationsRes, categoriesRes, deliveryServicesRes, partnersRes] = await Promise
            .all([_jobRoles, _holdReasons, _traceStatusesRes, _statusesRes, _citiesRes, _stationsRes, _categoriesRes, _deliveryServices, _partnersRes])

        if (jobRoles.succeeded) {
            dispatch(setMeta({jobRoles: toLookup(jobRoles.data)}));
        }

        if (holdReasons.succeeded) {
            dispatch(setMeta({statusReasons: toLookup(holdReasons.data)}));
        }

        if (traceStatusesRes.succeeded) {
            dispatch(setMeta({traceStatuses: toLookup(traceStatusesRes.data)}));
        }
        if (statusesRes.succeeded) {
            dispatch(setMeta({shipmentStatuses: toLookup(statusesRes.data)}));
        }

        if (citiesRes.succeeded) {
            dispatch(setMeta({cities: toLookup(citiesRes.data)}));
        }

        if (stationsRes.succeeded) {
            dispatch(setMeta({stations: toLookup(stationsRes.data)}));
        }
        if (categoriesRes.succeeded) {
            dispatch(setMeta({categories: toLookup(categoriesRes.data)}));
        }
        if (deliveryServicesRes.succeeded) {
            dispatch(setMeta({deliveryServices: toLookup(deliveryServicesRes.data)}));
        }
        if (partnersRes.succeeded) {
            dispatch(setMeta({partners: toLookup(partnersRes.data)}));
        }
    }
};

const setMeta = (meta: MetaModel) => {
    return {
        type: SET_META,
        payload: {
            ...meta,
        },
    };
};

export const SetAlert = (alert: AlertModel) => {
    return {
        type: SET_ALERT,
        payload: alert,
    };
};

const toLookup = (obj: any) => {
    let result: LookupModel[] = [];
    Object.keys(obj).map((key: any) =>
        result.push({value: key, label: obj[key]})
    );

    return result;
};

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AppStateModel from "../../../models/appStateModel";
import IUpdateCustomerModel from "../../../models/customers/updateCustomerModel";
import CustomerModel from "../../../models/customers/customerModel";
import AddCustomerMember from './addMember';
import {DeactivateCutomerAsync, GetCustomerAsync, UpdateCustomerAsync,} from "../../../actions/customersActions";
import {Table} from "react-bootstrap";
import {GenerateCustomerApiKey} from "../../../api/data";
import ChangePassword from "./changePassword";
import {convertUTCHour} from "../../../actions/date";
import EditStoreModal from "./editStoreModal";

interface IProps {
    customer: CustomerModel;
    visible: boolean;
    addStoreModal: any;
    onHide: () => any;
}

const Component = (props: IProps) => {
    const store = useSelector((state: AppStateModel) => state.SelectedCustomer);
    const profile = useSelector((state: AppStateModel) => state.Profile);
    const content = useSelector(
        (state: AppStateModel) => state.AppContent.customers.newCustomer
    );

    const setAndConvertHour = (val: any) => {

        const t = profile.currentTenant?.countryCode ?? "SA";

        const time = new Date()
        time.setHours(val)
        if (t === "SA") {
            time.setHours(time.getHours() - 3);

        }
        if (t === "JO") {
            time.setHours(time.getHours() - 2);

        }

        return time.getHours()
    }
    const dispatch = useDispatch();
    const [customer, setCustomer] = useState<IUpdateCustomerModel>({
        id: props.customer.id,
        name: props.customer.name,
        maximumNumberOfAllowedDeliveryAttempts:props.customer?.options?.maximumNumberOfAllowedDeliveryAttempts,
        preferredPickupTime: props.customer.preferredPickupTime ?
            convertUTCHour(props.customer.preferredPickupTime, profile.currentTenant?.countryCode ?? "SA") : null
    });
    useEffect(() => {
        dispatch(GetCustomerAsync(customer.id!));
    }, [customer.id, dispatch]);


    const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

    const onSubmit = () => {
        let validations: string[] = [];
        (!customer.name || false) && validations.push("name");
        if (validations.length > 0) {
            setValidationErrors(validations);
        } else {

            dispatch(UpdateCustomerAsync({
                ...customer,
                preferredPickupTime: setAndConvertHour(customer.preferredPickupTime)
            }, () => props.onHide()));
        }
    };

    const [apiKey, setApiKey] = useState<string | "loading" | null>(null);
    const [changePasswordFor, setChangePasswordFor] = useState<string | null>(null);
    const [addMemberOn, setAddMemberOn] = useState(false);
    const [storeEditModal,setStoreEditModal]=useState<{modal:boolean,id?:string,storeName?:string, phone?: string}>({modal:false})

    const generateKey = async (id: string) => {
        setApiKey("loading");
        let res = await GenerateCustomerApiKey(id);
        if (res.succeeded) {
            setApiKey(res.data.key);
            setTimeout(() => {
                setApiKey(null);
            }, 20000);
        } else {
            setApiKey(null);
        }
    };


    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {"Update Customer"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Col md={6}>
                            <Form.Label>Name</Form.Label>
                            <TextInput
                                value={customer.name!}
                                onChange={(val) => setCustomer({...customer, name: val})}
                                placeholder={"Name"}
                                required={true}
                                invalidMessage={content.name.requiredMessage}
                                isInvalid={
                                    !!validationErrors.find((e) => e == "name")
                                }
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>Pickup Time (hour) </Form.Label>
                            <TextInput
                              value={customer?.preferredPickupTime ?? ""}
                              type={"number"}
                              onChange={(val) => setCustomer({
                                  ...customer,
                                  preferredPickupTime: Boolean(val) ? (val) : null
                              })}
                              placeholder={"Pickup time"}

                            />
                        </Col>
                        <Col md={6}>
                            <Form.Label>{"Maximum Delivery Attempts"} </Form.Label>
                            <TextInput
                                value={customer?.maximumNumberOfAllowedDeliveryAttempts ?? ""}
                                type={"number"}
                                onChange={(val) => setCustomer({
                                    ...customer,
                                    maximumNumberOfAllowedDeliveryAttempts: val
                                })}
                                placeholder={"Pickup time"}

                            />
                        </Col>
                        <Col md={12}>

                            <div
                                style={{
                                    paddingTop: 21,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                }}
                            >
                                <Button size="lg" onClick={() => onSubmit()} variant="primary">
                                    Save
                                </Button>
                            </div>
                        </Col>
                        <Col>

                        </Col>
                    </Form.Row>

                    <Form.Row className=" align-items-center">
                        <h2>Users</h2>
                        <Button
                            size={"sm"}
                            className=" m-3"
                            onClick={() => setAddMemberOn(true)}
                        >
                            Add User
                        </Button>
                    </Form.Row>
                    <Form.Row>
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th> Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {store?.users &&
                                store.users.map((s) => (
                                    <tr>
                                        <td>{s.id}</td>
                                        <td>{s.name}</td>
                                        <td>{s.phone}</td>
                                        <td>{s.email}</td>
                                        <td>
                                            <Button
                                                disabled={apiKey == "loading"}
                                                onClick={() => generateKey(s.id!)}
                                            >
                                                Generate Key
                                            </Button>
                                            {"  "}
                                            {apiKey}
                                            {"  "}
                                            <Button
                                                disabled={apiKey == "loading"}
                                                onClick={() => setChangePasswordFor(s.id!)}
                                            >
                                                Change Password
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Form.Row>


                    <Form.Row className=" align-items-center">
                        <h2>Stores</h2>
                        <Button
                            size={"sm"}
                            className=" m-3"
                            onClick={() => props.addStoreModal()}
                        >
                            Add Store
                        </Button>
                    </Form.Row>
                    <Form.Row>
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Store Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>City</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {store?.stores &&
                                store.stores.map((s) => (
                                    <tr>
                                        <td>{s.id}</td>
                                        <td>{s.name}</td>
                                        <td>{s.phone}</td>
                                        <td>{s.email}</td>
                                        <td>{s.city}</td>
                                        <td>
                                            {" "}
                                            <Button
                                                variant="danger"
                                                className=""
                                                onClick={() => {
                                                    dispatch(
                                                        DeactivateCutomerAsync(s.id!, () =>
                                                            dispatch(GetCustomerAsync(customer.id!))
                                                        )
                                                    );
                                                }}
                                            >
                                                {"X"}
                                            </Button>
                                            {" "}
                                            <Button
                                                variant="primary"
                                                className=""
                                                onClick={() => {
                                                    setStoreEditModal({modal:true,id:s.id,storeName:s.name, phone: s.phone})
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Form.Row>

                </Form>

                {changePasswordFor != null && <ChangePassword
                    userId={changePasswordFor}
                    visible={true}
                    onHide={() => {
                        dispatch(GetCustomerAsync(customer.id!));
                        setChangePasswordFor(null);
                    }}
                />}
                {storeEditModal.modal && <EditStoreModal
                    storeId={storeEditModal.id}
                    phone={storeEditModal.phone}
                    storeName={storeEditModal.storeName}
                    visible={true}
                    onHide={() => {
                        dispatch(GetCustomerAsync(customer.id!));
                        setStoreEditModal({modal:false})
                    }}
                />}

                {addMemberOn && <AddCustomerMember
                    customerId={customer.id!}
                    visible={true}
                    onHide={() => {
                        dispatch(GetCustomerAsync(customer.id!));
                        setAddMemberOn(false);
                    }}
                />}
            </Modal.Body>
        </Modal>
    );
};

export default Component;

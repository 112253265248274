import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import TextInput from "../../components/TextInput";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AppStateModel from "../../../models/appStateModel";
import IUpdateCustomerModel from "../../../models/customers/updateCustomerModel";
import CustomerModel from "../../../models/customers/customerModel";
import {
  CreateCustomerStoreAsync,
  UpdateCustomerAsync,
} from "../../../actions/customersActions";
import IAddCustomerStoreModel from "../../../models/customers/addStoreModel";
import CitiesSelect from "../../components/CitiesSelect";
interface IProps {
  customer: CustomerModel;
  visible: boolean;
  onHide: () => any;
}
const Component = (props: IProps) => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.customers.addStoreContent
  );
  const [
    newCustomerStore,
    setNewCustomerStore,
  ] = useState<IAddCustomerStoreModel>({ customerId: props.customer.id });
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

  const dispatch = useDispatch();
  const onSubmit = () => {
    let validations: string[] = [];
    (!newCustomerStore.email || newCustomerStore.email == null) &&
      validations.push("email");
    (!newCustomerStore.addressLine1 || newCustomerStore.addressLine1 == null) &&
      validations.push("addressLine1");
    (!newCustomerStore.addressLine2 || newCustomerStore.addressLine2 == null) &&
      validations.push("addressLine2");
    (!newCustomerStore.addressLine3 || newCustomerStore.addressLine3 == null) &&
      validations.push("addressLine3");
    (!newCustomerStore.city || newCustomerStore.city == null) &&
      validations.push("city");
    (!newCustomerStore.phone || newCustomerStore.phone == null) &&
      validations.push("phone");
    (!newCustomerStore.name || newCustomerStore.name == null) &&
      validations.push("name");

    if (validations.length > 0) {
      setValidationErrors(validations);
    } else {
      dispatch(
        CreateCustomerStoreAsync(
          props.customer.id!,
          newCustomerStore,
          props.onHide
        )
      );
    }
  };
  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Add Store
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <TextInput
                value={newCustomerStore.name!}
                onChange={(val) =>
                  setNewCustomerStore({ ...newCustomerStore, name: val })
                }
                placeholder={content.storeName.placeHolder}
                required={true}
                invalidMessage={content.storeName.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e == "name") ? true : false
                }
              />
            </Col>
            <Col>
              <TextInput
                value={newCustomerStore.email!}
                onChange={(val) =>
                  setNewCustomerStore({ ...newCustomerStore, email: val })
                }
                placeholder={content.storeEmailField.placeHolder}
                required={true}
                invalidMessage={content.storeEmailField.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e == "email") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <TextInput
                value={newCustomerStore.phone!}
                onChange={(val) =>
                  setNewCustomerStore({ ...newCustomerStore, phone: val })
                }
                placeholder={content.phoneField.placeHolder}
                required={true}
                invalidMessage={content.phoneField.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e === "phone") ? true : false
                }
              />
            </Col>
            <Col>
              <TextInput
                value={newCustomerStore.secondPhone!}
                onChange={(val) =>
                  setNewCustomerStore({ ...newCustomerStore, secondPhone: val })
                }
                placeholder={content.secondePhoneField.placeHolder}
                required={true}
                invalidMessage={content.secondePhoneField.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e === "secondPhone")
                    ? true
                    : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <CitiesSelect
                value={newCustomerStore.city!}
                onChange={(val: any) =>
                  setNewCustomerStore({
                    ...newCustomerStore,
                    city: val,
                  })
                }
                placeholder={content.cityField.placeHolder}
                invalidMessage={content.cityField.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e == "city") ? true : false
                }
              />
            </Col>{" "}
            {/* <Col>
              <CitiesSelect
                value={newCustomerStore.city!}
                onChange={(val: any) =>
                  setNewCustomerStore({
                    ...newCustomerStore,
                    city: val,
                  })
                }
                placeholder={"City"}
                invalidMessage={" "}
                isInvalid={
                  validationErrors.find((e) => e == "city") ? true : false
                }
              />
            </Col> */}
          </Form.Row>
          <Form.Row>
            <Col>
              <TextInput
                value={newCustomerStore.addressLine1!}
                onChange={(val) =>
                  setNewCustomerStore({
                    ...newCustomerStore,
                    addressLine1: val,
                  })
                }
                placeholder={"Address Line 1"}
                required={true}
                invalidMessage={"Address Line 1 invalid"}
                isInvalid={
                  validationErrors.find((e) => e == "line1") ? true : false
                }
              />
            </Col>
            <Col>
              <TextInput
                value={newCustomerStore.addressLine2!}
                onChange={(val) =>
                  setNewCustomerStore({
                    ...newCustomerStore,
                    addressLine2: val,
                  })
                }
                placeholder={"Address Line 2"}
                required={true}
                invalidMessage={"Address Line2 invalid"}
                isInvalid={
                  validationErrors.find((e) => e == "line2") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <TextInput
                value={newCustomerStore.addressLine3!}
                onChange={(val) =>
                  setNewCustomerStore({
                    ...newCustomerStore,
                    addressLine3: val,
                  })
                }
                placeholder={"Address Line 3"}
                required={true}
                invalidMessage={"Address Line 3 invalid"}
                isInvalid={
                  validationErrors.find((e) => e == "line3") ? true : false
                }
              />
            </Col>
            <Col>
              <TextInput
                value={newCustomerStore.postCode!}
                onChange={(val) =>
                  setNewCustomerStore({
                    ...newCustomerStore,
                    postCode: val,
                  })
                }
                placeholder={"Post code"}
                required={true}
                invalidMessage={"Post code invalid"}
                isInvalid={
                  validationErrors.find((e) => e == "postCode") ? true : false
                }
              />
            </Col>
          </Form.Row>{" "}
          <Form.Row></Form.Row> <Form.Row></Form.Row>
          <Form.Row>
            <Col>
              <div
                style={{
                  paddingTop: 80,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={onSubmit} variant="light">
                  {content.submit}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

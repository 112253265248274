import React, {FunctionComponent} from "react";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import LookupModel from "../../../models/lookupModel";
import {Badge} from "react-bootstrap";

interface IProps {
  selectedFields: string[]
  onAdd: (i: any) => void
  onRemove: (i: any) => void
}

const DynamicDataFields: FunctionComponent<IProps> = (props: IProps) => {

  const options: LookupModel[] = [
    { value: "Number", label: "Number" },
    { value: "ActionName", label: "Action Name" },
    { value: "OutForDeliveryOn", label: "Out For Delivery On" },

    { value: "UniqueReference", label: "UniqueReference" },
    { value: "LastActionDate", label: "LastActionDate" },
    { value: "Station", label: "Station" },
    { value: "ShipperName", label: "ShipperName" },
    { value: "ShipperPhone", label: "ShipperPhone" },
    { value: "ShipperSecondPhone", label: "ShipperSecondPhone" },
    { value: "ShipperCity", label: "ShipperCity" },
    { value: "ShipperAddressLine1", label: "ShipperAddressLine1" },
    { value: "ConsigneeName", label: "ConsigneeName" },
    { value: "ConsigneePhone", label: "ConsigneePhone" },
    { value: "ConsigneeSecondPhone", label: "ConsigneeSecondPhone" },
    { value: "ConsigneeCity", label: "ConsigneeCity" },
    { value: "ConsigneeAddressLine1", label: "ConsigneeAddressLine1" },
    { value: "ConsigneeAddressLine2", label: "ConsigneeAddressLine2" },
    { value: "Description", label: "Description" },
    { value: "COD", label: "COD" },
    { value: "PaymentMethod", label: "PaymentMethod" },
    { value: "Status", label: "Status" },
    { value: "Customer", label: "Customer" },
    { value: "FailedDeliveryAttempts", label: "FailedDeliveryAttempts" },
    { value: "CourierName", label: "CourierName" },
    { value: "ImageUrls", label: "ImageUrls" },
    { value: "CreatedOn", label: "CreatedOn" },
    { value: "ReceivedOn", label: "ReceivedOn" },
    { value: "ActualDelivery", label: "ActualDelivery" },
    { value: "CodCollectionDate", label: "CodCollectionDate" },
    { value: "DeliveredBy", label: "DeliveredBy" },
    { value: "ActionBy", label: "Action By" },
    { value: "ActionName", label: "Action Name" },
    { value: "PickupDate", label: "Pickup Date" },

    { value: "FailedDeliveryReason", label: "Failed reason" },


  ]

  return (
    <div><Row className={" w-100"}>

      <Col md={3}>
        <h4>{"Data Fields"}</h4>

        <Select
          className="basic-multi-select w-auto"
          escapeClearsValue={true}
          options={options}
          placeholder={"Field"}
          isMulti={false}
          onChange={(selectedOption) => props.onAdd(selectedOption)}
        />
      </Col>

    </Row>
      <Row className={" p-3"}>

        <Col>
          <Row
            className={"w-100 d-flex align-items-start justify-content-start"}>
            {
              props.selectedFields?.map(y => <div
                className={"d-flex  align-items-center justify-content-center p-1 px-3 mx-1"}
              >
                <Badge
                  className={"d-flex  align-items-center justify-content-center "}
                  variant={"secondary"}>
                  <div>
                    <h6 className={"mx-1"}>
                      {options.find(x => x.value == y)?.label}
                    </h6>
                  </div>

                  <div
                    className={"p-1 mx-1 bg-danger rounded-circle d-flex  align-items-center justify-content-center"}
                    onClick={() => props.onRemove(y)}><span>X</span></div>
                </Badge>
              </div>)
            }
          </Row>
        </Col>

      </Row></div>)

}

export default DynamicDataFields;

import React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';


interface IProps {
    value: any
    placeholder?: string;
    onChange?: (value: any) => any;
    invalidMessage?: string
    disabled?: boolean
    isInvalid?: boolean
    required?: boolean
    onKeyPress?: any
    type?: string
}

const Component = (props: IProps) => {

    return (
        <Form.Group>
            {
                props.placeholder && <Form.Label>{props.placeholder}</Form.Label>
            }

            <FormControl
                type={props.type}
                isInvalid={props.isInvalid}
                placeholder={props.placeholder + (props.required ? "*" : "")}
                disabled={props.disabled}
                value={props.value}
                onChange={(e) => props.onChange!(e.target.value)}
                onKeyPress={props.onKeyPress}
            />
            <Form.Control.Feedback type="invalid">
                {props.invalidMessage}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default React.memo(Component);

import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import React, {useState} from "react";
import IUpdatePassword from "../../../models/customers/updatePasswordModel";
import {EditStoreAsync, EditStoreModel, UpdatePasswordAsync} from "../../../actions/customersActions";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import Button from "react-bootstrap/Button";

interface IProps {
    storeId?: string;
    storeName?:string
    visible?: boolean;
    onHide: () => any;
    phone?: string;
}

const EditStoreModal=(props:IProps)=>{
    const dispatch = useDispatch();

    const content = useSelector(
        (state: AppStateModel) => state.AppContent.customers.editStore
    );
    const [store, setStore] = useState<EditStoreModel>({
        phone: props.phone || "",
        storeName:props.storeName||""
    });
    const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

    const onSubmit = () => {
        let validations: string[] = [];

        !store.storeName && validations.push("Invalid Name");

        if (validations.length > 0) {
            setValidationErrors(validations);
        } else {
            dispatch(EditStoreAsync(props.storeId||"",store, () => props.onHide()));
        }
    };

    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide&&props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {content.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Col>
                            <Form.Label>{content.name}</Form.Label>
                            <TextInput
                                value={store.storeName}
                                onChange={(val) => setStore({ ...store, storeName: val })}
                                placeholder={"Type here..."}
                                required={true}
                                invalidMessage={"Store name is required"}
                                isInvalid={
                                    validationErrors.find((e) => e == "Invalid Name") ? true : false
                                }
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>{'Phone'}</Form.Label>
                            <TextInput
                                value={store.phone}
                                onChange={(val) => setStore({ ...store, phone: val })}
                                placeholder={"Type here..."}
                                required={true}
                                invalidMessage={"Phone is required"}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col>
                            <div
                                style={{
                                    paddingTop: 21,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                }}
                            >
                                <Button onClick={onSubmit}  size="lg"  variant="primary">
                                    {content.submit}
                                </Button>
                            </div>
                        </Col>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
export default EditStoreModal

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import TextInput from "../../components/TextInput";
import IUpdateMemberModel from "../../../models/members/updateMemberModel";
import Button from "react-bootstrap/Button";
import { UpdateMemberAsync, UpdateMemberPasswordAsync } from "../../../actions/membersActions";
import MemberModel from "../../../models/members/memberModel";
import SelectInput from "../../components/SelectInput";
import IUpdateMemberPasswordModel from "../../../models/members/updateMemberPassword";

interface IProps {
  member: MemberModel;
  visible: boolean;
  onHide: () => any;
}
const Component = (props: IProps) => {
  const memberContent = useSelector(
    (state: AppStateModel) => state.AppContent.members
  );
  const content = memberContent.newMember;

  const [member, setMember] = useState<IUpdateMemberPasswordModel>({
    id: props.member.id!,
    newPassword:""}
   );

  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const onSubmit = () => {
    let validations: string[] = [];

    !member.newPassword && member.newPassword == null && validations.push("Invalid Password");

    if (validations.length > 0) {
      setValidationErrors(validations);
    } else {
      dispatch(UpdateMemberPasswordAsync(member, () => props.onHide()));
    }
  };


  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {"Change Password"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <Form.Label>New Password : </Form.Label>
              <TextInput
                value={member.newPassword!}
                onChange={(val) => setMember({ ...member, newPassword: val })}
                placeholder={"Type here..."}
                required={true}
                invalidMessage={content.password.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e == "Invalid Password") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <div
                style={{
                  paddingTop: 30,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={() => onSubmit()} variant="light">
                  {content.submit}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

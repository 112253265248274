import React from "react";
import {Route, Router} from "react-router-dom";
import history from "./history";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "./models/appStateModel";
import "./App.css";
import AppNav from "./AppNav";
import Loading from "./components/Loading";
import Pages from "./routes/pages";
import {CheckAuthenticationAsync, Unauthenticate,} from "./actions/authActions";
import {SetAlert} from "./actions/common";
import AlertMessage from "./components/AlertMessage";
import {SetBaseUrl, SetClientConfig} from "@simplify9/simplyapiclient";
import {getAccessToken} from "./services/cookiesManager";
import {BaseUrl} from "./env";

const App = () => {
  const loading = useSelector((state: AppStateModel) => state.Loading);
  const isAuthenticated = useSelector(
    (state: AppStateModel) => state.Auth.isAuthenticated
  );
  const isProfileComplete = useSelector(
    (state: AppStateModel) => state.Profile.isComplete
  );
  const alert = useSelector((state: AppStateModel) => state.Alert);

  const dispatch = useDispatch();
  React.useEffect(() => {
    SetClientConfig({
      authType: "bearer",
      getBearer: () => getAccessToken(),
      onAuthFail: () => dispatch(Unauthenticate()),
    });
    SetBaseUrl(BaseUrl);
    dispatch(CheckAuthenticationAsync());
  }, [dispatch]);

  return (
    <Router history={history}>
      <div className="App">
        {isAuthenticated != undefined && true &&
        isProfileComplete != null ? (
          <>
            {loading.isLoading && (
              <Loading
                open={loading.isLoading}
                title={loading.title}
                msg={loading.message}
              />
            )}
            {isAuthenticated && isProfileComplete && <AppNav/>}

            <Route component={Pages}/>

            {alert.visible && (
              <AlertMessage
                alert={alert}
                onClose={() => dispatch(SetAlert({ visible: false }))}
              />
            )}
          </>
        ) : (
          <div>Error</div>
        )}
      </div>
    </Router>
  );
};

export default App;

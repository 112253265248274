import {SET_CUSTOMER, SET_CUSTOMERS, SET_CUSTOMERS_CRITERIA,} from "../store/types";
import {
    ConfirmCustomer,
    CreateCustomer,
    CreateCustomerStore,
    DeactivateCutomer, EditStore,
    GetCustomer,
    Revoke,
    SearchCustomers,
    UpdateCustomer,
    UpdatePassword,
} from "../api/data";
import {SetAlert, setLoading} from "./common";
import PagedModel from "../models/pagedModel";
import SearchCustomersCriteria from "../models/customers/searchCustomerCriteria";
import CustomerModel from "../models/customers/customerModel";
import AppState from "../models/appStateModel";
import IAddCustomerStoreModel from "../models/customers/addStoreModel";

export const CreateCustomerAsync = (customer: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        customer.jobRole = 301;
        let res = await CreateCustomer(customer);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Customer is created",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const CreateCustomerStoreAsync = (
    id: string,
    customerStore: IAddCustomerStoreModel,
    onFinish: () => any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        let res = await CreateCustomerStore(id, customerStore);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Customer Store is created",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const UpdateCustomerAsync = (customer: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await UpdateCustomer(customer.id, customer);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Customer is updated",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const ConfirmCustomerAsync = (customer: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await ConfirmCustomer(customer.id, customer);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Customer is Confirmed",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};


export const UpdatePasswordAsync = (customer: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await UpdatePassword(customer.id, customer);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Password is updated",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const RevokeAsync = (id: string) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await Revoke(id);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Revoked",
                    title: "Success",
                    kind: "success",
                })
            );
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const SearchCustomersAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state: AppState = getState();
        //todo
        let res = await SearchCustomers(state.SearchCustomersCriteria!, false);
        // let data: PagedModel<CustomerModel> = {
        //   matches: [
        //     { id: "123", email: "abc@xyz.com", name: "waheeb", mobile: "42069" },
        //     { id: "1917", email: "marz@xyz.com", name: "karl marx", mobile: "666" },
        //   ],
        //   total: 2,
        // };
        // let res: ApiResponse = {
        //   status: 200,
        //   succeeded: true,
        //   data: data,
        // };
        if (res.succeeded) {
            dispatch(setCustomers(res.data));
        }
    };
};
export const GetCustomerAsync = (id: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state: AppState = getState();
        let res = await GetCustomer(id);

        if (res.succeeded) {
            dispatch(setCustomer(res.data));
        }
    };
};

export const setCustomersCriteriaAsync = (filters: SearchCustomersCriteria) => {
    return async (dispatch: any) => {
        dispatch(setCustomersCriteria(filters));
        // dispatch(SearchCustomersAsync());
    };
};

export const DeactivateCutomerAsync = (storeId: string, CallBack: () => any) => {
    return async (dispatch: any) => {
        await DeactivateCutomer({id: storeId});
        CallBack()
    };
};

export const EditStoreAsync = (id:string,storeModel: EditStoreModel, CallBack: () => any) => {
    return async (dispatch: any) => {
        await EditStore(storeModel,id);
        CallBack()
    };
};

const setCustomersCriteria = (filters: SearchCustomersCriteria) => {
    return {
        type: SET_CUSTOMERS_CRITERIA,
        payload: {
            filters,
        },
    };
};

const setCustomers = (customers: PagedModel<CustomerModel>) => {
    return {
        type: SET_CUSTOMERS,
        payload: {
            customers,
        },
    };
};
const setCustomer = (customer: CustomerModel) => {
    return {
        type: SET_CUSTOMER,
        payload: {
            customer,
        },
    };
}
export type EditStoreModel={
    storeName:string
    phone: string;
}

import React, {useCallback} from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import {useDispatch, useSelector} from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import ScanListWithScan from "./shipmentListWithScan";
import ReactSelect from "react-select";
import {SetAlert} from "../../../actions/common";
import moment from "moment";
import ToLocalDateTime from "../../../actions/date";

interface IProps {
  visible: boolean;
  onHide: () => any;
  onDone: (ids: any, courierId: any) => any;
  title: string;
  submitButton: string;
  allowedStatuses?: string[];
}

const Component = (props: IProps) => {
  const dispatch = useDispatch()
  const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
  const [courier, setCourier] = React.useState<any>(null);
  const couriers = useSelector((state: AppStateModel) => state.Meta?.couriers);

  const onSubmit = () => {
    let ids: string[] = [];
    shipments.forEach((s) => {
      ids.push(s.id);
    });
    props.onDone(ids, courier!.value!);
  };

  const countryCode = useSelector(
      (state: AppStateModel) => state.Profile.currentTenant?.countryCode
  );

  const validate = useCallback((shipment: ShipmentModel) => {
    const maximumNumberOfAllowedDeliveryAttempts = shipment?.customer!.options!.maximumNumberOfAllowedDeliveryAttempts
    const failedDeliveryAttempts = shipment.traces?.filter(x => x.activity === "FailedDelivery")?.length ?? 0
    if (maximumNumberOfAllowedDeliveryAttempts < failedDeliveryAttempts) {
      dispatch(SetAlert({
        visible: true,
        kind: "error",
        title: "Exceeded the number of delivery attempts allowed",
        message: `The maximum for this customer is ${maximumNumberOfAllowedDeliveryAttempts}, you tried ${failedDeliveryAttempts} `
      }))
      return false;
    }

    if (Boolean(shipment.scheduledDeliveryDate) && (moment() < moment(new Date(ToLocalDateTime(shipment?.scheduledDeliveryDate, countryCode!))))) {
      dispatch(SetAlert({
        visible: true,
        kind: "error",
        title: "Today's date is less than the scheduled date",
        message: ``
      }))

      return false
    }
    else {
      // console.log('not enter')
      // dispatch(SetAlert({
      //   visible: true,
      //   kind: "error",
      //   title: "Exceeded the number of delivery attempts allowed",
      //   message: `The maximum for this customer is ${maximumNumberOfAllowedDeliveryAttempts}, you tried ${failedDeliveryAttempts} `
      // }))
      return true;
    }
  }, [dispatch])
  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ScanListWithScan
          setShipments={setShipments}
          allowedStatuses={props.allowedStatuses}
          validate={validate}
        />
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: '20%' }}>
          <ReactSelect
            value={courier}
            onChange={(v) => setCourier(v)}
            options={couriers}

          />
        </div>
        <Button
          disabled={
            (shipments.length === 0 ) && !courier && courier == null
          }
          onClick={onSubmit}
        >
          {props.submitButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Component;

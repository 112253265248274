import React, {useState} from "react";
import CodPage from "./cod";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import Col from "react-bootstrap/Col";
import AccountInfo from "./AccountInfo";
import Row from "react-bootstrap/Row";
import Transfer from "./transfer";
import Clear from "./clear";


const CodTab = () => {
    const [key, setKey] = useState<any>('codPage');
    const user = useSelector((x: AppStateModel) => x.Profile)

    return (
        <div style={{maxWidth:"99%"}}>
            <Row className={"mt-3"}>
                <Col md={12}>
                    <AccountInfo member={user}/>
                </Col>
            </Row>
            <Tabs
                transition={false}
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                style={{margin: '10px 40px 0px'}}
            >
                <Tab eventKey="codPage" title="Cod Page">
                    <CodPage/>
                </Tab>
                <Tab eventKey="transfer" title="Transfer">
                    <Transfer/>
                </Tab>
                <Tab eventKey="clear" title="Clear">
                    <Clear/>
                </Tab>
            </Tabs>
        </div>
    )
}

export default CodTab;

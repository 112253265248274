import {SetAlert, setLoading} from "./common";
import * as routesClient from "../api/routesClient";
import {SET_JOURNEYS, SET_ROUTES} from "../store/types";
import PagedModel from "../models/pagedModel";
import {
    CreateJourneyModel,
    CreateRouteModel,
    JourneyModel,
    RouteModel
} from "../models/routes";

export const SearchRoutesAsync = () => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await routesClient.SearchRoutes()
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        dispatch(setRoutes(res.data))

        dispatch(setLoading(false));
    };
};
export const SearchJourneysAsync = () => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await routesClient.SearchJourneys()
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        dispatch(setJourneys(res.data))

        dispatch(setLoading(false));
    };
};

export const DeactivateJourneyAsync = (id: number) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await routesClient.DeactivateJourney(id)
        if (!res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Journey Deactivated Successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            dispatch(SearchJourneysAsync())
        }
        dispatch(setLoading(false));
    };
};

export const DeactivateRouteAsync = (id: number) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await routesClient.DeactivateRoute(id)
        if (!res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Route Deactivated Successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            dispatch(SearchRoutesAsync())
        }
        dispatch(setLoading(false));
    };
};




export const CreateRouteAsync = (data: CreateRouteModel, onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await routesClient.CreateRoute(data)
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        onComplete()
        dispatch(SearchRoutesAsync())

        dispatch(setLoading(false));
    };
};

export const UpdateRouteAsync = (data: CreateRouteModel, onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await routesClient.UpdateRoute(data)
        if (!res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
            dispatch(setLoading(false));
            return
        }
        onComplete()
        dispatch(SearchRoutesAsync())

        dispatch(setLoading(false));
    };
};

export const CreateJourneyAsync = (data: CreateJourneyModel, onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await routesClient.CreateJourney(data)
        if (!res.succeeded) {
            dispatch(
              SetAlert({
                  visible: true,
                  data: res.data!,
                  title: "Error",
                  kind: "error",
              })
            );
            dispatch(setLoading(false));
            return
        }
        onComplete()
        dispatch(SearchJourneysAsync())

        dispatch(setLoading(false));
    };
};

export const UpdateJourneyAsync = (data: CreateJourneyModel, onComplete: () => void) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await routesClient.UpdateJourney(data)
        if (!res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
            dispatch(setLoading(false));
            return
        }
        onComplete()
        dispatch(SearchJourneysAsync())

        dispatch(setLoading(false));
    };
};

const setJourneys = (routes: PagedModel<JourneyModel>) => {
    return {
        type: SET_JOURNEYS,
        payload: routes,
    };
};
const setRoutes = (routes: PagedModel<RouteModel>) => {
    return {
        type: SET_ROUTES,
        payload: routes,
    };
};

import React from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';



interface IProps {
    open: boolean;
    title?: string;
	msg?: string;
}
const Component = (props:IProps) => {

	return (
		<div
			className="overlay"
			style={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "fixed",
				background: "rgba(0, 0, 0, 0.7)",
				zIndex: 9999,
			}}
		>
			<Spinner animation="grow" />
			<Spinner animation="grow" />
			<Spinner animation="grow" />
			<Spinner animation="grow" />
		</div>
	);
}

export default Component;
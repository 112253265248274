import {ApiResponse} from "@simplify9/simplyapiclient";
import {client} from "./index";
import {AmsBaseUrl} from "../env";
import {ContactAddressModel, ISearchAmsModel} from "../models/ams";

const SearchContacts = async (request?: ISearchAmsModel): Promise<ApiResponse> => {
    let limit = request?.pageSize ?? 20;
    let pageNumber = request?.pageNumber ?? 1;
    let offset = (pageNumber - 1) * limit!;
    let query = `?limit=${limit}&offset=${offset}`;
    return await client.SimplyGetAsync(`${AmsBaseUrl}Contacts${query}`);
}

const CreateContact = async (mobile: string): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`${AmsBaseUrl}Contacts`, {
        mobile
    });

}

const GetContact = async (id: number | string): Promise<ApiResponse> => {
    return await client.SimplyGetAsync(`${AmsBaseUrl}Contacts/${id}`);
}
const GetCitiesMeta = async (): Promise<ApiResponse> => {
    return await client.SimplyGetAsync(`${AmsBaseUrl}lookups/cities`);
}
const AddAddressToContact = async (key: number, body: Partial<ContactAddressModel>): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`${AmsBaseUrl}Address/${key}`, body);
}

const UpdateAddress = async (key: string, body: Partial<ContactAddressModel>): Promise<ApiResponse> => {
    return await client.SimplyPostAsync(`${AmsBaseUrl}Address/${key}/update`, body);
}


export default {
    GetContact,
    CreateContact,
    SearchContacts,
    GetCitiesMeta,
    AddAddressToContact,
    UpdateAddress
}
import { IJobRole } from "./jobRole";

export const allRoles: IJobRole[] = [
  { label: "Admin", value: 101 },
  { label: "OpsManager", value: 111 },
  {label : "OpsOfficer" , value : 131},
  {label : "OpsSupervisor" , value : 121},
  {label : "Accountant" , value : 132} ,
  {label : "Cashier" , value : 133},
  {label : "CustomerServiceManager" , value : 112},
  {label : "CustomerService" , value : 122},
  {label : "Sales" , value : 134}, 
  {label : "Courier" , value : 201},
  {label : "CustomerAdmin" , value : 301} 
];

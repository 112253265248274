import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import { CreateCustomerAsync } from "../../../actions/customersActions";

import AppStateModel from "../../../models/appStateModel";
import ICreateCustomerModel from "../../../models/customers/createCustomerModel";

interface IProps {
  visible: boolean;
  onHide: () => any;
}
const Component = (props: IProps) => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.customers.newCustomer
  );
  const [newCustomer, setNewCustomer] = useState<ICreateCustomerModel>({});
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const onSubmit = () => {
    let validations: string[] = [];

    !newCustomer.name && newCustomer.name == null && validations.push("name");

    if (validations.length > 0) {
      setValidationErrors(validations);
    } else {
      dispatch(CreateCustomerAsync(newCustomer, () => props.onHide()));
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {content.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <TextInput
                value={newCustomer.name!}
                onChange={(val) =>
                  setNewCustomer({ ...newCustomer, name: val })
                }
                placeholder={"Name"}
                required={true}
                invalidMessage={content.name.requiredMessage}
                isInvalid={
                  validationErrors.find((e) => e == "name") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={6}>

            </Col>
            <Col md={6}>

            </Col>
            <Col>
              <div
                style={{

                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={() => onSubmit()} variant="light">
                  {content.submit}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {CreateJourneyModel, JourneyStepModel} from "../../models/routes";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TextInput from "../components/TextInput";
import Button from "react-bootstrap/Button";
import {CreateJourneyAsync} from "../../actions/routesActions";

type Props = {
  onHide: () => void
}
const CreateJourneyModal: React.FC<Props> = ({ onHide }) => {

  const d = useDispatch()
  const [data, setData] = useState<Partial<CreateJourneyModel>>({})
  const [step, setStep] = useState<Partial<JourneyStepModel>>({})

  const stations = useSelector((state: AppStateModel) => state.Meta?.stations);
  const routes = useSelector((i: AppStateModel) => i.Routes?.matches)
  const onChange = (value: string | number, key: keyof CreateJourneyModel) => {
    setData((c) => ({ ...c, [key]: value }))
  }
  const onChangeStep = (value: string | number, key: keyof JourneyStepModel) => {
    setStep((c) => ({ ...c, [key]: value }))
  }
  const onSubmit = () => {
    d(CreateJourneyAsync(data as CreateJourneyModel, onHide))
  }

  const onAddStep = () => {
    if (!(Boolean(step.routeId) && Boolean(step.order)))
      return

    //@ts-ignore
    setData((e) => ({ ...e, steps: [...(e.steps ?? []), step] }))
    setStep({})
  }

  const getStepRouteValue = (id: number | undefined) => {
    const val = routes?.find(i => i.id == id)
    if (!val)
      return undefined

    return {
      value: val!.id,
      label: `${val.sourceStation.name} => ${val.destinationStation.name}`
    }
  }

  return (<div>
    <Modal
      show={true}
      onHide={() => onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {"Create Journey"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className={"pt-3"}>
            <TextInput value={data.name}
                       onChange={(e) => onChange(e, "name")}
                       placeholder={"Name"}/>
          </Col>
          <Col md={6}>
            <Select
              key={`selectedStation__${data.sourceStationId}`}
              options={stations as any ?? []}
              value={stations?.find(i => (i.value as unknown as number) == data.sourceStationId)}

              isClearable
              placeholder={"Source Station"}
              onChange={(selectedOption: any) => {
                if (Array.isArray(selectedOption) || !selectedOption) {
                  throw new Error(
                    "Unexpected type passed to ReactSelect onChange handler"
                  );
                }
                onChange(selectedOption.value, 'sourceStationId')

              }}
            />
          </Col>
          <Col md={6}>
            <Select
              key={`selectedStation__${data.destinationStationId}`}
              value={stations?.find(i => (i.value as unknown as number) == data.destinationStationId)}
              options={stations as any ?? []}
              isClearable
              placeholder={"Destination Station"}
              onChange={(selectedOption: any) => {
                if (Array.isArray(selectedOption) || !selectedOption) {
                  throw new Error(
                    "Unexpected type passed to ReactSelect onChange handler"
                  );
                }
                onChange(selectedOption.value, 'destinationStationId')

              }}
            />
          </Col>
        </Row>
        <Col md={12}>

          <div className={"pt-3"}>
            {
              data.steps
                ?.sort((a, b) => a.order - b.order)
                ?.map((stepx: JourneyStepModel, index: number) => <Row>
                  <p>
                    {index + 1} - {getStepRouteValue(stepx.routeId)?.label}
                  </p>
                </Row>)
            }

          </div>

          <Row className={"border shadow mt-3 py-2 px-3 flex flex-column"}>
            <div>
              <label>Create Step</label>
            </div>
            <div className={"px-3"}>
              <TextInput onChange={(e) => onChangeStep(e, 'order')}
                         value={step.order} placeholder={"Order"}/>
              <Select
                key={`selectedStation__${step.routeId}`}
                value={getStepRouteValue(step.routeId)}
                options={routes?.map(i => ({
                  value: i.id,
                  label: `${i.sourceStation.name} => ${i.destinationStation.name}`
                })) as any ?? []}
                isClearable
                placeholder={"Route"}
                onChange={(selectedOption: any) => {
                  if (Array.isArray(selectedOption) || !selectedOption) {
                    throw new Error(
                      "Unexpected type passed to ReactSelect onChange handler"
                    );
                  }
                  onChangeStep(selectedOption.value, 'routeId')

                }}
              />
            </div>

            <div className={"pt-3 d-flex flex-row-reverse"}>
              <Button onClick={onAddStep}>Add</Button>
            </div>
          </Row>


        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!Boolean(data.destinationStationId && data.sourceStationId)}
          onClick={onSubmit}
        >
          {"Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>)
}

export default CreateJourneyModal

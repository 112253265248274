import {AUTHENTICATION_FAILED, AUTHENTICATION_SUCCEEDED, MEMBER_INFO_RETRIEVED, UNAUTHENTICATE} from "../store/types";


import {GetProfile, Login, SwitchTenant} from "../api/data";
import {
    deleteAllCookies,
    getAccessToken,
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
} from "../services/cookiesManager";
import {SetAlert, setLoading} from "./common";
import ProfileModel from '../models/members/profileModel';
import {SetBaseUrl} from '@simplify9/simplyapiclient';
import {BaseUrl} from '../env';


export const CheckAuthenticationAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true, "Loading portal"));
        let accessToken = getAccessToken();
        let refreshToken = getRefreshToken();
        if (
            accessToken !== null &&
            accessToken !== "" &&
            refreshToken !== null &&
            refreshToken !== ""
        ) {
            SetBaseUrl(BaseUrl);
            dispatch(authenticationSucceed(accessToken, refreshToken));
            dispatch(CompleteLogIn());
        } else {
            dispatch(Unauthenticate());
        }

    };
};

export const LogOutAsync = () => {
    return async (dispatch: any) => {
        dispatch(setLoading(true, "Logging out"));
        deleteAllCookies();
        dispatch(Unauthenticate());
    };
};


export const SwitchTenantAsync = (tenant: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true, "Signing in"));

        let response = await SwitchTenant(tenant);

        if (response.succeeded) {
            let result = response.data;
            let access = result.jwt;
            setAccessToken(access);
            dispatch(authenticationSucceed(access));

            dispatch(CompleteLogIn());


        } else {
            dispatch(SetAlert({
                data: response.data,
                title: "Error",
                visible: true
            }))
            dispatch(authenticationFailed());
        }
        window.location.reload();
    };
};


export const LoginAsync = (username: string, password: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true, "Signing in"));

        let response = await Login(username, password);

        if (response.succeeded) {
            let result = response.data;
            let access = result.jwt;
            let refresh = result.refresh_token;

            setAccessToken(access);
            setRefreshToken(refresh);
            dispatch(authenticationSucceed(access, refresh));

            dispatch(CompleteLogIn());
        } else {

            dispatch(SetAlert({
                data: response.data,
                title: "Failed",
                visible: true
            }))
            dispatch(authenticationFailed());
        }
    };
};

export const CompleteLogIn = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true, "Getting your information"))
        let res = await GetProfile();
        if (res.succeeded) {
            dispatch(
                memberInfoRetrieved(res.data)
            );
        } else {
            dispatch(Unauthenticate());
        }
    };
};
export const RefreshProfile = () => {
    return async (dispatch: any, getState: any) => {
        let res = await GetProfile();
        if (res.succeeded) {
            dispatch(
                memberInfoRetrieved(res.data)
            );
        } else {
            dispatch(Unauthenticate());
        }
    };
};

const memberInfoRetrieved = (memberInfo: ProfileModel) => {
    return {
        type: MEMBER_INFO_RETRIEVED,
        user: memberInfo
    };
}

const authenticationSucceed = (
    accessToken: string,
    refreshToken?: string
) => {
    return {
        type: AUTHENTICATION_SUCCEEDED,
        payload: {
            accessToken: accessToken,
            refreshToken: refreshToken
        },
    };
};

export const Unauthenticate = () => {
    return {
        type: UNAUTHENTICATE,
    };
};

const authenticationFailed = () => {
    return {
        type: AUTHENTICATION_FAILED
    }
}


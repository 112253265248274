import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, Col, Row, Table} from "react-bootstrap";
import {Line} from "react-chartjs-2";
import {
    GetDashboardCouriers,
    GetDashboardShipments,
    GetDashboardStatuses
} from '../../api/data';
import {chartExample1,} from "./variables";
import {useDispatch} from "react-redux";
import {setLoading} from "../../actions/common";

const extractData = (data: any[]) => {

    let numbers = data.map(d => d.count);

    return (canvas: any) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

        return {
            labels: [
                "JAN",
                "FEB",
                "MAR",
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC",
            ],
            datasets: [
                {
                    label: "Shipments Count",
                    fill: true,
                    backgroundColor: gradientStroke,
                    borderColor: "#1f8ef1",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#1f8ef1",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#1f8ef1",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 4,
                    data: numbers,
                },
            ],
        };
    }
}

const Component = () => {

    // const [bigChartData, setbigChartData] = React.useState("data1");
    // const setBgChartData = (name: any) => {
    //   setbigChartData(name);
    // };
    //
    const d = useDispatch()
    const [statusesCount, setStatusesCount] = useState([]);
    // const [statusesCountIsLoading, setStatusesCountIsLoading] = useState(true);

    const refreshStatusesCount = async () => {
        let res = await GetDashboardStatuses();
        if (res.succeeded) setStatusesCount(res.data);

    }

    const [couriersCount, setCouriersCount] = useState([]);
    //const [couriersCountIsLoading, setCouriersCountIsLoading] = useState(true);

    const refreshCouriersCount = async () => {
        let res = await GetDashboardCouriers();
        if (res.succeeded) setCouriersCount(res.data);

    }


    const [shipmentsCount, setShipmentsCount] = useState([]);
    const [shipmentsStatus, setShipmentStatus] = useState<string | null>(null);

    const refreshShipmentsCount = useCallback(async () => {
        let res = await GetDashboardShipments(shipmentsStatus);
        if (res.succeeded) setShipmentsCount(res.data);

    }, [shipmentsStatus])

    useEffect(() => {
        refreshStatusesCount()
        refreshCouriersCount();
        refreshShipmentsCount();
    }, [refreshShipmentsCount]);

    useEffect(() => {
        refreshShipmentsCount();
    }, [refreshShipmentsCount, shipmentsStatus])

    const onExportCouriersShipments = async () => {
        d(setLoading(true))
        let res = await GetDashboardCouriers(true);
        d(setLoading(false))

        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer");

        }
    }


    return (
      <>

          <Row className={"m-3"}>
              <Col xs="12">
                  <Card className="card-chart">
                      <Card.Header>
                          <Row>
                              <Col className="text-left" sm="6">
                                  <h5 className="card-category">Shipments</h5>
                                  {/*<Card.Title>Performance</Card.Title>*/}
                              </Col>
                              <Col sm="6">
                                  <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons"
                                  >
                                      <Button
                                        className={"btn-simple"}
                                        color="info"
                                        id="0"
                                        size="sm"
                                        onClick={() => setShipmentStatus(null)}
                                      >
                                            <span
                                              className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                              All
                                            </span>
                                      </Button>
                                      <Button
                                        color="info"
                                        id="1"
                                        size="sm"
                                        className={"btn-simple"}
                                        onClick={() => setShipmentStatus("ItemDeliveredToCustomer")}
                                      >
                                            <span
                                              className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                              Delivered
                                            </span>
                                      </Button>
                                      <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        className={"btn-simple"}
                                        onClick={() => setShipmentStatus("ItemVoidFromCustomer")}
                                      >
                                            <span
                                              className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                              Failed
                                            </span>
                                      </Button>
                                      <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        className={"btn-simple"}
                                        onClick={() => setShipmentStatus("Lost")}
                                      >
                                            <span
                                              className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                              Lost
                                            </span>
                                      </Button>
                                      <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        className={"btn-simple"}
                                        onClick={() => setShipmentStatus("ItemReturnedToCompanyStore")}
                                      >
                                            <span
                                              className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                              Returned
                                            </span>
                                      </Button>
                                  </ButtonGroup>
                              </Col>
                          </Row>
                      </Card.Header>
                      <Card.Body>
                          <div className="chart-area">
                              <Line
                                data={extractData(shipmentsCount)}
                                options={chartExample1.options}

                              />
                          </div>
                      </Card.Body>
                  </Card>
              </Col>
          </Row>

          <Row className={"m-3"}>

              <Col lg="4" md="12">
                  <Card>
                      <Card.Header>
                          <Card.Title>Shipments Statuses Counts</Card.Title>
                      </Card.Header>
                      <Card.Body>
                          <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                              <tr>
                                  <th>Status</th>
                                  <th className="text-center">Count</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                  statusesCount?.map((s: any) => (
                                    <tr>
                                        <td>{s.status}</td>
                                        <td
                                          className="text-center">{s.count}</td>
                                    </tr>
                                  ))
                              }
                              </tbody>
                          </Table>
                      </Card.Body>
                  </Card>
              </Col>

              <Col lg="6" md="12">
                  <Card>
                      <Card.Header>
                          <Row
                            className={"flex justify-content-between px-3 align-items-center"}>
                              <Card.Title>Couriers shipments</Card.Title>
                              <div>
                                  <Button onClick={onExportCouriersShipments}>
                                      Export
                                  </Button>
                              </div>
                          </Row>
                      </Card.Header>
                      <Card.Body>
                          <Table className="tablesorter" responsive>
                              <thead className="text-primary">
                              <tr>
                                  <th>Courier Name</th>
                                  <th className="text-center">
                                      Assigned For Pickup
                                  </th>
                                  <th className="text-center">
                                      Picked up
                                  </th>
                                  <th className="text-center">
                                      Out for delivery
                                  </th>
                                  <th className="text-center">
                                      Out for return
                                  </th>

                                  <th className="text-center">
                                      Failed Atempt
                                  </th>
                                  <th className="text-center">
                                      Delivered
                                  </th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                  couriersCount?.map((c: any) => (
                                    <tr>
                                        <td>{c.name}</td>
                                        <td
                                          className="text-center">{c.assignedForPickup}</td>
                                        <td
                                          className="text-center">{c.pickedUp}</td>
                                        <td
                                          className="text-center">{c.outForDelivery}</td>
                                        <td
                                          className="text-center">{c.outForReturn}</td>
                                        <td
                                          className="text-center">{c.failedAttempt}</td>
                                        <td
                                          className="text-center">{c.delivered}</td>

                                    </tr>
                                  ))
                              }

                              </tbody>
                          </Table>
                      </Card.Body>
                  </Card>
              </Col>

          </Row>

      </>
    );
}

const chart1_2_options: any = {
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
    },
    responsive: true,
    scales: {
        yAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                },
                ticks: {
                    suggestedMin: 60,
                    suggestedMax: 125,
                    padding: 20,
                    fontColor: "#9a9a9a",
                },
            },
        ],
        xAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.1)",
                    zeroLineColor: "transparent",
                },
                ticks: {
                    padding: 20,
                    fontColor: "#9a9a9a",
                },
            },
        ],
    },
};

export default React.memo(Component);

import React, {useCallback, useState} from "react";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import UsersTab from "./tabs/Users";
import DynamicCodReportTab from "./tabs/DynamicCodReportTab";
import MultipleCustomers from "./tabs/MultipleCustomers";
import MultipleCouriers from "./tabs/MultipleCouriers";
import {Button} from "react-bootstrap";
import {setLoading} from "../../actions/common";
import {GetTransactionsExcelReport} from "../../api/data";
import AllTransactions from "./tabs/AllTransactions";


const CodReportTab = () => {
  const [key, setKey] = useState<any>('customer');
  const dispatch = useDispatch()

  const meta = useSelector((state: AppStateModel) => state.Meta);
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.shipments
  );

  return (
    <>
      <div className={"px-5 mx-1 pt-2"}>


      </div>
      <Tabs
        transition={false}
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
        style={{ margin: '10px 40px 0px' }}
      >
        <Tab eventKey="customer" title="Customer">
          <DynamicCodReportTab tab={"customer"} options={meta?.customers}
                               placeHolder={content.model.customerName}/>
        </Tab>
        <Tab eventKey="courier" title="Courier">
          <DynamicCodReportTab tab={"courier"} options={meta?.couriers}
                               placeHolder={content.model.courierId}/>
        </Tab>
        <Tab eventKey="station" title="CityTiersModel">
          <DynamicCodReportTab tab={"station"} options={meta?.stations}
                               placeHolder={content.model.stationId}/>
        </Tab>
        <Tab eventKey="userName" title="Users">
          <UsersTab/>
        </Tab>
        <Tab eventKey="multipleCustomers" title="Multiple Customers">
          <MultipleCustomers/>
        </Tab>
        <Tab eventKey="multipleCouriers" title="Multiple Members">
          <MultipleCouriers/>
        </Tab>
          <Tab eventKey="allTransactions" title="All Transactions">
              <AllTransactions/>
          </Tab>

      </Tabs>
    </>

  )
}

export default CodReportTab;

import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

interface IProps {
    visible: boolean;
    onHide : ()=>any;
    onDone: (reason : string) => any;
}
const Component = (props: IProps) => {

    const [loading, setLoading] = React.useState(false);
    const [reason, setReason] = React.useState("");

    const onKeyPress = (e: any) => {
        if (e.keyCode === 13) {
            // e.preventDefault(); // Ensure it is only this code that runs

            props.onDone(reason);
        }
    };


    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Mark As Lost
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Reason..."
                            value={reason}
                            onChange={(e: any) => setReason(e.target.value)}
                            disabled={loading}
                            onKeyDown={onKeyPress}
                            autoFocus={true}
                        />
                        <InputGroup.Append>
                            <Button disabled={reason === ""} onClick={()=>props.onDone(reason)} >
                                Submit
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </div>

            </Modal.Body>

        </Modal>
    );
};

export default Component;

import React, {useState} from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Routes from "./routes";
import Journeys from "./journeys";

const RoutesScreen: React.FC = () => {
  const [key, setKey] = useState<any>('journeys');

  return (<div className={""}>


    <Tabs
      transition={false}
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
      style={{ margin: '10px 40px 0px' }}
    >
      <Tab eventKey="journeys" title="Journeys">
        <Journeys/>
      </Tab>
      <Tab eventKey="routes" title="Routes">
        <Routes/>
      </Tab>

    </Tabs>
  </div>)
}
export default RoutesScreen
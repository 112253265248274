import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import {useSelector} from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import ScanListWithScan from "./shipmentListWithScan";
import ReactSelect from "react-select";


interface IProps {
  visible: boolean;
  onHide: () => any;
  onDone: (ids: any, station: any, courierId: string) => any;
  title: string;
  submitButton: string;
  allowedStatuses?: string[];
  flag?: 'AssignForTransfer'

}

const Component = (props: IProps) => {

  const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [station, setStation] = React.useState<any>(null);

  const stationsOptions = useSelector(
    (state: AppStateModel) => state.Meta?.stations
  );

  const profile = useSelector((state: AppStateModel) => state.Profile)
  const [courier, setCourier] = React.useState<any>(null);
  const couriers = useSelector((state: AppStateModel) => state.Meta?.couriers);
  const onSubmit = () => {
    let ids: string[] = [];
    shipments.forEach((s) => {
      ids.push(s.id);
    });
    props.onDone(ids, station.value, courier!.value);
  };
  React.useEffect(() => {
    (profile.stationId != null && setStation(stationsOptions?.find(x => x.value != profile.stationId)))
  }, []);



  // const validate = (shipment: ShipmentModel) => {
  //   const finalDestinationCity = shipment?.consignee?.address?.city;
  //   const finalDestinationStation = cities?.matches?.find((x) => x.name == finalDestinationCity)?.station
  //   return (!finalDestinationStation) || finalDestinationStation == station?.value;
  //
  //
  // }
  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          !station &&
          <label className={"text-danger font-weight-bold"}>Please pick a
            station first</label>
        }
        <ScanListWithScan
          destinationStation={station}
          disabled={!Boolean(station)}
          // validate={validate}
          setShipments={setShipments}
          allowedStatuses={props.allowedStatuses}
        />
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: '20%' }}>
          <ReactSelect
            value={courier}
            onChange={(v) => setCourier(v)}
            options={couriers}
          />
        </div>
        <div style={{ width: "20%" }}>
          <ReactSelect
            placeholder="Select station.."
            value={station}
            onChange={(v) => setStation(v)}
            options={stationsOptions}
          />
        </div>
        <Button
          disabled={
            shipments.length === 0 || loading || !station
          }
          onClick={onSubmit}
        >
          {'Transfer'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Component;

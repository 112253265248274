import React, {FunctionComponent} from "react";
import Select from "react-select";
import {useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import LookupModel from "../../models/lookupModel";

interface IProps {
    onChange: (mId: string) => void
    options?:LookupModel[]
    placeHolder:string
}

const MemberSelect: FunctionComponent<IProps> = ({onChange,options,placeHolder}) => {

    return <div>
        <div style={{minHeight: '212px'}} className="p-5 mx-5 shadow rounded border">
            <label>Please Select the {placeHolder}</label>
            <Select
                key={`selectedCourier`}
                onChange={(selectedOption) => {
                    if (Array.isArray(selectedOption)) {
                        throw new Error(
                            "Unexpected type passed to ReactSelect onChange handler"
                        );
                    }
                    if (selectedOption?.value) {
                        onChange(selectedOption.value)

                    }
                }}
                isClearable
                options={options}
                placeholder={placeHolder}
            />
        </div>

    </div>
}

export default React.memo(MemberSelect)

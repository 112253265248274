import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import {useDispatch, useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import {CreateDraftBatchAsync} from "../../../actions/draftActions";


interface IProps {
    visible: boolean;
    onHide: () => any;
    title: string;
    submitButton: string;
}
const CreateDraft = (props: IProps) => {
    const content = useSelector(
        (state: AppStateModel) => state.AppContent.shipments.receive
    );
    const [count, setCount] = React.useState("");



const dispatch= useDispatch();
    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Col lg={2} className="px-md-2">
            <InputGroup className="mb-1">
                <Form.Control
                    style={{ height: 35 }}
                    placeholder={"Count"}
                    value={count}
                    onChange={(e) => setCount(e.target.value)}

                />

            </InputGroup>
            </Col>

            </Modal.Body>
            <Modal.Footer>
                <Button disabled={count == "" } onClick={()=>{
                   dispatch( CreateDraftBatchAsync(count,()=> props.onHide()))
                }}>
                    {props.submitButton}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

    export default CreateDraft;

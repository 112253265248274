import React from 'react';
import {useSelector} from 'react-redux';
import AppStateModel from '../models/appStateModel';


interface IProps {
    allowedRoles?: string[]
    children: any
    all?: boolean
}

const Component = (props: IProps) => {


    const jobRole = useSelector((state: AppStateModel) => state.Profile).jobRole
    if (props?.all || props?.allowedRoles?.some(x => x == jobRole)) {
        return (
          <>
              {props.children}
          </>
        );
    } else {
        return <></>;
    }


}

export default React.memo(Component);

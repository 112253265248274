import { SearchCustomerNotifications, SearchNotifications } from "../api/data";
import AppState, { CustomerNotificationsModel } from "../models/appStateModel";
import { ICustomerNotification, INotification, NotificationsRequest } from "../models/notifications";
import PagedModel from "../models/pagedModel";
import { SET_CUSTOMER_NOTIFICATONS, SET_NOTIFICATONS, SET_NOTIFICATONS_REQUEST } from "../store/types";
import { setLoading } from "./common";

export const SearchNotificationsAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state: AppState = getState();
        let res = await SearchNotifications(state.NotificationsRequest);

        if (res.succeeded) {
            dispatch(setNotifications(res.data));
        }
        dispatch(setLoading(false))
    };
};

export const SearchCustomerNotificationsAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state: AppState = getState();
        let res = await SearchCustomerNotifications(state.NotificationsRequest);

        if (res.succeeded) {
            dispatch(setCustomerNotifications(res.data));
        }
        dispatch(setLoading(false))
    };
};

export const setNotifications = (res: PagedModel<INotification>) => {
    return {
        type: SET_NOTIFICATONS,
        payload: {
            ...res,
        },
    };
};

export const setNotificationsRequest = (request: NotificationsRequest) => {
    return {
        type: SET_NOTIFICATONS_REQUEST,
        payload: {
            ...request,
        },
    };
};

export const setCustomerNotifications = (res: CustomerNotificationsModel<ICustomerNotification>) => {
    return {
        type: SET_CUSTOMER_NOTIFICATONS,
        payload: {
            ...res,
        },
    };
};
import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap"
import {useDispatch} from "react-redux";
import {CreatePartner} from "../../api/data";
import TextInput from "../components/TextInput";
import {CreatePartnerModel} from "../../models/partner";
import {SearchPartnersAsync} from "../../actions/partnerActions";
import SelectInput from "../../pages/components/SelectInput";
import {SetAlert} from "../../actions/common";

interface IProps {
    onHide: () => void
}

const initialState = {
    name: '',
    email: '',
    phone: '',
    type: 1,
    password: ''
}

const options = [
    {
        label: 'Delivery',
        value: '1'
    },
    {
        label: 'Lockers',
        value: '2'
    }
]
const CreatePartnerModal = (props: IProps) => {
    const [create, setCreate] = useState<CreatePartnerModel>(initialState)
    const dispatch = useDispatch()

    const onSubmit = async () => {
        let res;
        if (create) {
            res = await CreatePartner(create)
            if (res.succeeded) {
                dispatch(
                    SetAlert({
                        visible: true,
                        message: "The partner was added successfully",
                        title: "Success",
                        kind: "success",
                    })
                );
                dispatch(SearchPartnersAsync());
                props.onHide()
            }
        }
    }


    return (
        <Modal
            show={true}
            onHide={() => props.onHide()}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Create Partner
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextInput
                    value={create.name}
                    placeholder={'Name'}
                    onChange={(e) => setCreate({...create, name: e})}
                />
                <TextInput
                    value={create?.email}
                    placeholder={'Email'}
                    onChange={(e) => setCreate({...create, email: e})}
                />
                <TextInput
                    value={create?.phone}
                    placeholder={'Phone'}
                    onChange={(e) => setCreate({...create, phone: e})}
                />

                <TextInput
                    value={create?.password}
                    placeholder={'Password'}
                    onChange={(e) => setCreate({...create, password: e})}
                />
                <SelectInput
                    value={create?.type}
                    onChange={(id) => {
                        setCreate({...create, type: Number(id)});
                    }}
                    options={options!}
                    placeholder={'Type'}
                />

            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => onSubmit()}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default CreatePartnerModal

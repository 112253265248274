import {SET_MEMBERS, SET_MEMBERS_CRITERIA,} from "../store/types";
import {
    ActivateCourier,
    AssignStation,
    CreateMember,
    DeactivateCourier,
    SearchMembers,
    UpdateMember,
    UpdateMemberPassword,
    UpdateRole,
} from "../api/data";


import {SetAlert, setLoading} from "./common";
import PagedModel from "../models/pagedModel";
import MemberModel from "../models/members/memberModel";
import SearchMembersCriteria from "../models/members/searchMembersCriteria";
import IUpdateMemberRoleModel from "../models/members/updateMemberRoleModel";
import assignStationModel from "../models/members/assignStationModel";

export const SearchMembersAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state = getState();
        let response = await SearchMembers(state.SearchMembersCriteria);
        if (state.SearchMembersCriteria.exportToExcel) {
            window.open(response.data.location, "_blank", "noreferrer");
            dispatch(
                setMemberCriteriaAsync({
                    ...state.SearchMembersCriteria,
                    exportToExcel: false,
                }));
            dispatch(setLoading(false));
        } else
            dispatch(searchMembers(response.data));
    };
};

export const setMemberCriteriaAsync = (filters: SearchMembersCriteria) => {
    return async (dispatch: any) => {
        dispatch(SetMembersCriteria(filters));
        dispatch(SearchMembersAsync());
    };
};


export const CreateMemberAsync = (member: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await CreateMember(member);
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "New Member is created",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const UpdateMemberAsync = (member: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await UpdateMember(member.id, member);
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Member is updated",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.error!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const UpdateRoleAsync = (member: IUpdateMemberRoleModel, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await UpdateRole(member);
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Member is updated",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.error!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const AssignStationAsync = (member: assignStationModel, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await AssignStation(member);
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Member is updated",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.error!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const UpdateMemberPasswordAsync = (member: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await UpdateMemberPassword(member.id, member);
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Member Password is updated",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.error!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const DeactivateMemberAsync = (
    courierId: string,
    activate: boolean,
    onFinish: () => any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await DeactivateCourier(courierId, true);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: `Account is ${activate ? "activated" : "deactivated"}`,
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};
export const ActivateMemberAsync = (
    courierId: string,
    activate: boolean,
    onFinish: () => any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        const res = await ActivateCourier(courierId, true);

        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: `Account is ${!activate ? "activated" : "deactivated"}`,
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data!,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};


const searchMembers = (searchMembersResult: PagedModel<MemberModel>) => {
    return {
        type: SET_MEMBERS,
        payload: {
            searchMembersResult,
        },
    };
};

export const SetMembersCriteria = (filters: SearchMembersCriteria) => {
    return {
        type: SET_MEMBERS_CRITERIA,
        payload: {
            filters,
        },
    };
};


import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import { useSelector } from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import ShipmentList from "./shipmentsList";
import ReactSelect from "react-select";
import { GetShipmentsToBeCollected } from "../../../api/data";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";

interface IProps {
  visible: boolean;
  onHide: () => any;
  onDone: (ids: any, courierId: string) => any;
  title: string;
  submitButton: string;
  allowedStatuses?: string[];
}
const Component = (props: IProps) => {
  const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [courier, setCourier] = React.useState<any>(null);

  const couriers = useSelector((state: AppStateModel) => state.Meta?.couriers);
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.shipments
  );
  const [total, setTotal] = React.useState(0);
  const onClear = (number: string) => {
    SetShipmentsAndCalcTotal(shipments.filter((x) => x.number != number));
  };
  const onSubmit = () => {
    let ids: string[] = [];
    shipments.forEach((s) => {
      ids.push(s.id);
    });
    props.onDone(ids, courier.value);
  };
  const SetShipmentsAndCalcTotal = (s: ShipmentModel[]) => {
    let t = 0;
    s?.forEach((s: ShipmentModel) => {
      t += Number(s.commodity.cod);
    });
    setShipments(s);

    setTotal(t);
  };
  const ChangeCourier = async (courier: any) => {
    if (courier != null) {
      let shipmentsResponse = await GetShipmentsToBeCollected(courier.value);

      if (shipmentsResponse.succeeded) {
        SetShipmentsAndCalcTotal(shipmentsResponse.data);
      }
    } else {
      setShipments([]);
    }
    setCourier(courier);
  };
  const generateReport = async () => {
    let res = await GetShipmentsToBeCollected(courier.value,true);

      if (res.succeeded) {

        window.open(res?.data?.location, "_blank", "noreferrer");

      }
  };
  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
          {courier === null ? (
            <ReactSelect
              value={courier}
              onChange={(v) => ChangeCourier(v)}
              options={couriers}
            />
          ) : (
            <InputGroup className="mb-3">
              <Form.Control value={courier.label} disabled={true} />
              <InputGroup.Append>
                <Button
                  onClick={() => ChangeCourier(null)}
                  className="icon-button"
                >
                  Change
                </Button>
              </InputGroup.Append>
            </InputGroup>
          )}
        </div>
        {courier != null && (
          <>
                      <Button onClick={generateReport}> Generate Courier Balance Report </Button>

            <BTable striped responsive bordered hover size="sm">
              <thead>
                <tr>
                  <td style={{ border: 0 }}></td>
                  <td style={{ border: 0 }}></td>
                  <td style={{ border: 0 }}>Number of Shipments</td>
                  <td style={{ border: 0 }}>{shipments.length}</td>
                  <td style={{ border: 0 }}>Total</td>
                  <td style={{ border: 0 }}>{total}</td>
                </tr>
                <tr>
                  <th>{content.referencesHeader}</th>
                  <th>{content.commodityDetailsheader}</th>
                  <th>{content.fromHeader}</th>
                  <th>{content.toHeader}</th>
                  <th>{content.customerHeader}</th>
                  <th>{content.codHeader}</th>
                  <th>{"Payment Method"}</th>

                  <th> </th>
                </tr>
              </thead>

              {!loading && (
                <tbody>
                  {shipments?.map((row: ShipmentModel, i: any): any => (
                    <tr key={i}>
                      <td>
                        <Col>
                          <Row>
                            {content.model.number}: {row.number}
                          </Row>
                          <Row>
                            {content.model.uniqueReference}:{" "}
                            {row.uniqueReference}
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>
                            {content.model.commodity.weight}:{" "}
                            {row.commodity.weight}
                          </Row>
                          <Row>
                            {content.model.commodity.dimensions}:{" "}
                            {row.commodity.dimensions ?? "N/A"}
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>
                            {row.shipper.address?.city +
                              ", " +
                              row.shipper.address?.addressLine1 ?? ""}
                          </Row>
                          <Row>
                            {row.shipper.address?.addressLine2 ??
                              "" + ", " + row.shipper.address?.addressLine3 ??
                              ""}
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>
                            {row.consignee.address?.city +
                              ", " +
                              row.consignee.address?.addressLine1 ?? ""}
                          </Row>
                          <Row>
                            {row.consignee.address?.addressLine2 ??
                              "" + ", " + row.consignee.address?.addressLine3 ??
                              ""}
                          </Row>
                        </Col>
                      </td>
                      <td>{row.customerName}</td>
                      <td>{row.commodity.cod}</td>
                      <td>{row.paymentMethod}</td>

                      <td>
                        {" "}
                        <Button
                          variant="danger"
                          className=""
                          onClick={() => onClear(row.number)}
                        >
                          {"X"}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </BTable>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={shipments.length === 0} onClick={onSubmit}>
          {props.submitButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Component;

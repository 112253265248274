import React from 'react';

import Alert from "react-bootstrap/Alert";

import { AlertModel } from '../models/appStateModel';

interface IProps{
    alert:AlertModel
	onClose: () => any
	
}

const Component = (props: IProps) => {
	const [show, setShow] = React.useState(true);

	React.useEffect(() => {
		close();
	}, [])
	
	const close = async() => {
		await setTimeout(() => {
			props.onClose();
			setShow(false);
		}, 3000);
	}

    return (
			
			<Alert
				style={{
					position: "absolute",
					top: 300,
					right: 0,
					zIndex:100
				}}
				show={show}
				dismissible
			
				variant={props.alert.kind === "error" || !props.alert.kind ? "danger" : props.alert.kind}
			>
				<Alert.Heading>{props.alert.title}</Alert.Heading>
				{props.alert.data && props.alert.data[Object.keys(props.alert.data)[0]][0]}
			 	{props.alert.message}
			</Alert>
		);
}


export default Component;
import {SearchPartner} from "../api/data";
import PagedModel from "../models/pagedModel";
import {SET_PARTNER, SET_PARTNER_CRITERIA} from "../store/types";
import {SetAlert, setLoading} from "./common";
import {PartnersResponseModel, SearchPartnerCriteria} from "../models/partner";

export const SearchPartnersAsync = () => {
    return async (dispatch: any, getState: any) => {
        const state = getState()
        dispatch(setLoading(true));
        const res = await SearchPartner(state.SearchPartnerCriteria);
        if (res.succeeded) {
            console.log(res.data, 'aaaaaaaaaaaaa')
            dispatch(searchPartners(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};


export const searchPartners = (searchPartnerResult: PagedModel<PartnersResponseModel>) => {
    return {
        type: SET_PARTNER,
        payload: {
            searchPartnerResult,
        },
    };
};

export const setPartnerCriteria = (filters: SearchPartnerCriteria) => {
    return {
        type: SET_PARTNER_CRITERIA,
        payload: {
            filters,
        },
    };
};

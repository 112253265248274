import React, { useState } from "react"
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import TextInput from "../../components/TextInput";
import Col from "react-bootstrap/Col";
import { CreateNotificatin } from "../../../api/data";
import { SetAlert, setLoading } from "../../../actions/common";
import { useDispatch } from "react-redux";
import { NotificationModel } from "../../../models/notifications";
import { SearchNotificationsAsync } from "../../../actions/notificationsActions";


type Props = {
    isOpen: boolean
    onClose: () => void
}

const CreateNotificationModal: React.FC<Props> = ({ onClose, isOpen }: Props) => {
    const dispatch = useDispatch()
    const [values, setValues] = useState<NotificationModel>({
        message: "",
        title: "",
    })


    const onChange = (value: any, key: keyof NotificationModel) => {
        setValues((prev: NotificationModel) => ({ ...prev, [key]: value }))
    }

    const handleCreate = async () => {
        if (!!values.title && !!values.message) {
            dispatch(setLoading(true))
            const res = await CreateNotificatin(values)
            if (res.succeeded) {
                dispatch(
                    SetAlert({
                        visible: true,
                        data: "New Notification Pushed Successfully",
                        title: "Notificatin Created",
                        kind: "success",
                    })
                );
                dispatch(SearchNotificationsAsync())
                onClose()
            } else {
                dispatch(
                    SetAlert({
                        visible: true,
                        data: JSON.stringify((res.error as { data?: string })?.data ?? 'something went wrong'),
                        title: "Error",
                        kind: "error",
                    })
                );
            }
            dispatch(setLoading(false))
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: "Please Complete all fields",
                    title: "Missing Data",
                    kind: "error",
                }))
        }
    }

    return <div>
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {"Notifications"}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <TextInput
                                    value={values.title}
                                    placeholder={'Title'}
                                    onChange={(e) => onChange(e, 'title')}
                                />
                            </Col>
                            <Col>
                                <TextInput
                                    value={values.message}
                                    placeholder={'Message'}
                                    onChange={(e) => onChange(e, 'message')}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col>
                        <Button
                            onClick={onClose}>
                            {"Close"}
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={handleCreate}>Create</Button>
                    </Col>
                </Row>

            </Modal.Footer>
        </Modal>
    </div>
}

export default CreateNotificationModal;
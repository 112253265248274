import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap"
import Select from "react-select";
import LookupModel from "../../models/lookupModel";
import {SearchCitiesAsync} from "../../actions/citiesActions";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import {UpdateCity} from "../../api/data";

interface IProps {
    onHide: () => void
    id: string
}

const UpdateModal = (props: IProps) => {
    const customControlStyles = (base: any) => ({
        ...base,
        height: 21!,
    });
    const [selectedService, setSelectedService] = React.useState<LookupModel | undefined>();
    const [selectedStation, setSelectedStation] = React.useState<LookupModel | undefined>();

    const [update, setUpdate] = useState<{ isServiced?: boolean, station?: string }>()
    const stations = useSelector((state: AppStateModel) => state.Meta?.stations);

    const isServiceResolver = (value?: string) => {
        if (value == "yes") {
            setSelectedService({value: "yes", label: "Yes"})
            setUpdate({...update, isServiced: true})
        } else if (value == "no") {
            setSelectedService({value: "no", label: "No"})
            setUpdate({...update, isServiced: false})
        }
    }
    const dispatch=useDispatch()

    const onSubmit = async () => {
        const res=await UpdateCity({id:Number(props.id),station:selectedStation?.value ? Number(selectedStation?.value) : undefined,isServiced:update?.isServiced??false})
        if(res.succeeded){
            dispatch(SearchCitiesAsync());
            props.onHide()
        }
    }
    return (
        <Modal
            show={true}
            onHide={() => props.onHide()}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    value={selectedService ?? {label: "Is Serviced ?", value: "Is Serviced?"}}
                    styles={{control: customControlStyles}}
                    options={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}
                    isClearable
                    placeholder={"Is Serviced ?"}
                    onChange={(selectedOption) => {
                        if (Array.isArray(selectedOption)) {
                            throw new Error(
                                "Unexpected type passed to ReactSelect onChange handler"
                            );
                        }

                        isServiceResolver(selectedOption?.value)
                    }}
                />
                <div style={{height: "1vh"}}/>
                <Select
                    key={`selectedStation__${update?.station}`}
                    value={selectedStation}
                    styles={{control: customControlStyles}}
                    options={stations}
                    isClearable
                    placeholder={"Stations"}
                    onChange={(selectedOption) => {
                        if (Array.isArray(selectedOption)) {
                            throw new Error(
                                "Unexpected type passed to ReactSelect onChange handler"
                            );
                        }
                        setSelectedStation({
                            label: selectedOption?.label!,
                            value: selectedOption?.value!,
                        });
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={()=>onSubmit()}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default UpdateModal

import React, {useState} from "react";
import {PrepareMetaDataAsync} from "../../actions/common";
import {batch, useDispatch, useSelector} from "react-redux";
import {
  SearchDraftBatchesAsync,
  setDraftsCriteria
} from "../../actions/draftActions";
import AppStateModel from "../../models/appStateModel";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DraftsTable from "./components/draftsTable";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import CreateDraft from "./components/createDraft";
import Authorized from "../../components/Authorized";
import {Admin, CustomerServiceManager, OpsManager} from "../../models/jobRole";
import {BsSearch} from "react-icons/bs";
import {SearchCustomersAsync} from "../../actions/customersActions";


export const Draft = () => {

  const dispatch = useDispatch()
  React.useEffect(() => {
    batch(()=> {
      dispatch(PrepareMetaDataAsync());
      dispatch(SearchCustomersAsync());
    })
  }, [dispatch]);
  const data = useSelector((state: AppStateModel) => state.PagedDraftBatches);
  const criteria = useSelector((state: AppStateModel) => state.DraftBatchesCriteria);
  const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState<any>();
  const [modalOn, setModalOn] = useState<modalNames>("none")
  type modalNames =
    | "create"
    | "none";
  const renderModal = (val: modalNames) => {
    switch (val) {
      case "create":
        return (
          <CreateDraft
            submitButton={"Submit"}

            visible={true}
            title={"Create Batch"}
            onHide={() => {
              dispatch(SearchDraftBatchesAsync());
              setModalOn("none");
            }}
          />
        );
    }
  }
  const OnClear = () => {
    dispatch(setDraftsCriteria({
      createdFrom: undefined,
      createdTo: undefined,
      id: undefined
    }));
  }
  return (
    <div style={{ padding: "1vw" }}>
      {renderModal(modalOn)}
      <Card>
        <Card.Header>
          <Row>
            <Col lg={2} className="px-md-2">
              <InputGroup className="mb-1">
                <Form.Control
                  style={{ height: 35 }}
                  placeholder={"Id"}
                  value={criteria?.id}
                  onChange={(e) => dispatch(setDraftsCriteria({
                    ...criteria,
                    id: Number(e.target.value)
                  }))}

                />
                <InputGroup.Append>
                  <Button
                    onClick={() =>
                      dispatch(
                        SearchDraftBatchesAsync()
                      )
                    }
                    className="icon-button"
                  >
                    <BsSearch name={"search"} color={"white"} size={21}/>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col lg={3} className="px-md-1">
              <DateRangePicker
                isOutsideRange={() => false}
                small
                minimumNights={0}
                startDatePlaceholderText="Created From"
                endDatePlaceholderText="Created To"
                startDate={
                  criteria?.createdFrom
                    ? moment(criteria.createdFrom)
                    : null
                }
                startDateId="123" 
                endDate={
                  criteria?.createdTo
                    ? moment(criteria.createdFrom)
                    : null
                }
                endDateId="321" 
                onDatesChange={({ startDate, endDate }) => {
                  dispatch(
                    setDraftsCriteria({
                      ...criteria,
                      createdFrom: startDate?.toDate(),
                      createdTo: endDate?.toDate(),
                    })
                  );
                  if (startDate && endDate) {
                    dispatch(
                      SearchDraftBatchesAsync()
                    )
                  }
                }}
                focusedInput={dateRangeFocusedInput}
                onFocusChange={(focusedInput) => {
                  setDateRangeFocusedInput(focusedInput);
                }}
              />
            </Col>

          </Row>
          <Row className="px-3 p-2 d-flex justify-content-between">
            <Button variant="danger" onClick={() => OnClear()}>Clear</Button>
            <Authorized
              allowedRoles={[Admin.label, OpsManager.label, CustomerServiceManager.label]}>
              <Button
                onClick={() => setModalOn("create")}>Create</Button></Authorized>

          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col}>
              <Row/>
            </Form.Group>
          </Row>
          <Row className={"px-4"}>
            <Col md={5}>
              <Row> <DraftsTable data={data}/>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </div>
  )
}

export default React.memo(Draft)

import React from "react"
import {ContactAddressModel} from "../../../models/ams";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";

interface IProps {
    addresses?: Partial<ContactAddressModel>[]
    onClose: () => void
}

const AddressesModal = ({addresses, onClose}: IProps) => {
    return (<div>
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {"Addresses"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Row className={"px-2"}>
                            <BTable striped bordered hover size="sm">
                                <thead>
                                <tr>
                                    <th>label</th>
                                    <th>city</th>
                                    <th>createdOn</th>
                                    <th>state</th>
                                    <th>longitude</th>
                                    <th>latitude</th>
                                    <th>countryCode</th>
                                    <th>createdBy</th>
                                    <th>latitude</th>
                                    <th>line1</th>
                                    <th>line2</th>
                                    <th>line3</th>
                                </tr>
                                </thead>
                                <tbody>
                                {addresses &&
                                    addresses?.map((x, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{x.label}</td>
                                                    <td>{x.city?.name}</td>
                                                    <td>{x.createdOn}</td>
                                                    <td>{x.state?.name}</td>
                                                    <td>{x.coordinates?.longitude}</td>
                                                    <td>{x.coordinates?.latitude}</td>
                                                    <td>{x.countryCode}</td>
                                                    <td>{x.createdBy}</td>
                                                    <td>{x.line1}</td>
                                                    <td>{x.line2}</td>
                                                    <td>{x.line3}</td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </BTable>
                        </Row>
                    </Card.Body>
                </Card>

            </Modal.Body>
            <Modal.Footer>
                {/*<Button*/}
                {/*    onClick={onSubmit}*/}
                {/*>*/}
                {/*    {"Submit"}*/}
                {/*</Button>*/}
            </Modal.Footer>
        </Modal>
    </div>)
}
export default AddressesModal
import React, {Fragment} from 'react';
import BTable from "react-bootstrap/Table";
import {useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ShipmentModel from '../../../models/shipmentModel';

interface IProps {
    shipments: ShipmentModel[]
    isLoading: boolean
    selectedIds?: string[]
    notClearable?: boolean
    onRemovePressed?: (id: string) => any
}

const Component = (props: IProps) => {
    const content = useSelector(
      (state: AppStateModel) => state.AppContent.shipments
    );
    const meta = useSelector((state: AppStateModel) => state.Meta);
    const resolveStatus = (status: any) => {
        let st = meta?.shipmentStatuses?.find((s) => s.value == status);
        return st?.label;
    };

    return (
      <Fragment>

          <BTable striped responsive bordered hover size="sm">
              <thead>
              <tr>
                  <th style={{ border: 0 }}></th>
                  <th style={{ border: 0 }}></th>
                  <th style={{ border: 0 }}></th>
                  <th style={{ border: 0 }}></th>
                  <th style={{ border: 0 }}></th>
                  <th style={{ border: 0 }}>Count</th>
                  <th style={{ border: 0 }}>{props.shipments.length}</th>
              </tr>
              <tr>
                  <th>{content.referencesHeader}</th>
                  <th>{content.commodityDetailsheader}</th>
                  <th>{content.fromHeader}</th>
                  <th>{content.toHeader}</th>
                  <th>{content.customerHeader}</th>
                  <th>{content.statusHeader}</th>
                  {!props.notClearable && <th>{content.actions}</th>}
              </tr>
              </thead>

              {!props.isLoading && (
                <tbody>

                {props.shipments?.map((row: ShipmentModel, i: any): any => (
                  <tr
                    key={row.number}
                    style={
                        props.selectedIds?.find((id) => id == row.id)
                          ? { backgroundColor: "#9ccaaa" }
                          : {}
                    }
                  >
                      <td>
                          <Col>
                              <Row>
                                  {content.model.number}: {row.number}
                              </Row>
                              <Row>
                                  {content.model.uniqueReference}: {row.uniqueReference}
                              </Row>
                          </Col>
                      </td>
                      <td>
                          <Col>
                              <Row>
                                  {content.model.commodity.weight}: {row.commodity.weight}
                              </Row>
                              <Row>
                                  {content.model.commodity.dimensions}:{" "}
                                  {row.commodity.dimensions ?? "N/A"}
                              </Row>
                          </Col>
                      </td>
                      <td>
                          <Col>
                              <Row>
                                  {row.shipper.address?.city +
                                    ", " +
                                    row.shipper.address?.addressLine1 ?? ""}
                              </Row>
                              <Row>
                                  {row.shipper.address?.addressLine2 ??
                                    "" + ", " + row.shipper.address?.addressLine3 ??
                                    ""}
                              </Row>
                          </Col>
                      </td>
                      <td>
                          <Col>
                              <Row>
                                  {row.consignee.contact?.name}
                              </Row>
                              <Row>
                                  {row.consignee.address?.city +
                                    ", " +
                                    row.consignee.address?.addressLine1 ?? ""}{", "}
                                  {row.consignee.address?.addressLine2 ??
                                    "" + ", " + row.consignee.address?.addressLine3 ??
                                    ""}
                              </Row>

                              <Row>
                                  {row.consignee.contact?.phone}
                              </Row>
                          </Col>
                      </td>
                      <td>{row.customerName}</td>

                      <td>{resolveStatus(row.status)}</td>

                      {!props.notClearable && (
                        <td>
                            <Button
                              onClick={() => props.onRemovePressed!(row.id)}
                              className="icon-button main-button"
                            >
                                X
                            </Button>
                        </td>
                      )}
                  </tr>
                ))}
                </tbody>
              )}
          </BTable>
          {props.shipments.length === 0 && (
            <div>please scan shipment to add them</div>
          )}
      </Fragment>
    );
}

export default Component;
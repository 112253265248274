import React, {useRef, useState} from "react";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import useSound from "use-sound";
//@ts-ignore
import SuccessBeep from '../../../res/beepsuccess.mp3';
//@ts-ignore
import FailBee from '../../../res/beepfail.mp3';
import * as DataClient from "../../../api/operationsClient";
import {SetAlert} from "../../../actions/common";
import {useDispatch} from "react-redux";
import ScanListWithScan from "./shipmentListWithScan";
import ShipmentModel from "../../../models/shipmentModel";

interface IProps {
  visible: boolean;
  onHide: () => any;
  title: string;
  submitButton: string;
}

const StoreShipmentModal = (props: IProps) => {


  const [location, setLocation] = useState<string>("")
  const [shipments, setShipments] = useState<Array<ShipmentModel>>([])
  const [playFail] = useSound(FailBee);
  const [playSuccess] = useSound(SuccessBeep);
  const dispatch = useDispatch()
  const ref = useRef<any>(null)
  const [showShipments, setsShowShipments] = useState(false)

  const onSubmit = async () => {
    let res = await DataClient.StoreBulkShipment(shipments.map(x => x.id), location);
    if (res.succeeded) {
      dispatch(SetAlert({
        visible: true,
        message: "Shipment stored successfully",
        title: "Success",
        kind: "success",
      }));
      playSuccess()
    } else {
      dispatch(SetAlert({
        visible: true,
        data: res.data,
        title: "Error",
        kind: "error",
      }))
      playFail()
    }

  };
  const onKeyPressLocation = (e: any) => {
    if (e.keyCode === 13) {
      setsShowShipments(true)
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: '40%' }}>
          <label>Location</label>
          <Form.Control
            placeholder="Location"
            value={location}
            onChange={(e: any) => setLocation(e.target.value)}
            onKeyDown={onKeyPressLocation}
            autoFocus={true}
            ref={ref}
          />
        </div>
        <view style={{ paddingTop: 13 }}>
          {
            showShipments && <ScanListWithScan
              allowedStatuses={["50", "15", '20']}
              setShipments={setShipments}
            />
          }

        </view>

      </Modal.Body>
      <Modal.Footer>

        <Button

          onClick={props.onHide}
        >
          Close
        </Button>
        <Button

          onClick={onSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StoreShipmentModal;

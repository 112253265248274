import React, {useEffect, useState} from "react"
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import TextInput from "../../components/TextInput";
import {ContactModel} from "../../../models/ams";
import AmsClient from "../../../api/amsClient";
import AddressEditor from "./addressEditor";
import Col from "react-bootstrap/Col";
import {AssignAmsAddressToShipmentAsync} from "../../../api/data";
import {SetAlert} from "../../../actions/common";
import {useDispatch} from "react-redux";
import {searchShipmentsAsync} from "../../../actions/shipmentsActions";


type Props = {
    onClose: () => void
    contactRef?: string | number
    shipmentId?: string
}
const CreateEditModal: React.FC<Props> = ({onClose, contactRef, shipmentId}) => {
    const d = useDispatch()
    const [infoText, setInfoText] = useState("");
    const [contact, setContact] = useState<null | Partial<ContactModel>>({
        mobile: "",
    })

    const fetchData = async (id: string | number) => {
        const res = await AmsClient.GetContact(id)
        if (res.succeeded) {
            setContact(res.data)
        } else {

            if (typeof contactRef === "string") {
                setContact({mobile: contactRef})
                setInfoText("This contact does not exist on the AMS do you want to create a contact ")
            }
        }
    }
    useEffect(() => {
        if (contactRef) {
            fetchData(contactRef)
        }
    }, [contactRef])
    const onChange = (value: any, key: keyof ContactModel) => {
        setContact((c) => ({...c, [key]: value}))
    }

    const onCreateContact = async () => {
        if (contact?.mobile) {
            const res = await AmsClient.CreateContact(contact.mobile)
            onChange(res.data.id, "id")
            setInfoText("")
        }
    }

    const onAssignToShipment = async (addressId: string) => {

        const res = await AssignAmsAddressToShipmentAsync(shipmentId!, addressId)
        if (res.succeeded) {
            d(
                SetAlert({
                    visible: true,
                    data: "Address assigned successfully",
                    title: "Address Saved",
                    kind: "success",
                })
            );
            d(searchShipmentsAsync());
            onClose()
        } else {
            d(
                SetAlert({
                    visible: true,
                    data: "Something went wrong",
                    title: "Error",
                    kind: "error",
                })
            );
        }
    }
    const onCreteAddress = () => {
        setContact((c) => ({
            ...c,
            addresses: [...(c?.addresses ?? []), {
                //uid: crypto.randomUUID(),
                countryCode: "SA",
                new: true
            }]
        }))

    }
    return <div>
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {"Contact"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        {infoText && <Row className={"pb-3"}>
                            <Col>
                                <h5 style={{color: "red"}}>
                                    {infoText}

                                </h5>

                            </Col>
                        </Row>}

                        <Row className={"px-2 d-flex flex-row justify-content-between"}>
                            <div className={"d-flex flex-row"}>
                                <TextInput placeholder={"Id"} disabled value={contact?.id}
                                />
                                <div className={"mx-1"}/>
                                <TextInput placeholder={"Mobile"} value={contact?.mobile}
                                           onChange={(v) => onChange(v, "mobile")}/>
                            </div>

                        </Row>
                        <div className={"px-2"}>
                            {
                                contact?.id && <Row>
                                    <Col>
                                        <Button onClick={onCreteAddress}>
                                            Create address
                                        </Button>
                                    </Col>
                                </Row>
                            }

                            <Row className={"mt-3"}>
                                {
                                    contact?.id && contact.addresses?.map((i) =>
                                        <Col xs={12} className={"my-3"} key={`${i.id}_${i.new}_city}`}>
                                            <AddressEditor onFinish={() => fetchData(contact?.id!)}
                                                           onAssignToShipment={shipmentId ? onAssignToShipment : undefined}
                                                           contactId={contact.id!}
                                                           address={{
                                                               ...i,
                                                               longitude: i.coordinates?.longitude,
                                                               latitude: i.coordinates?.latitude,
                                                           } as any}/>

                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    </Card.Body>
                </Card>

            </Modal.Body>
            <Modal.Footer>
                <Row>

                    <Col>
                        <Button
                            onClick={onClose}>
                            {"Close"}
                        </Button>

                    </Col>
                    <Col>
                        {
                            !contact?.id && <Button onClick={onCreateContact}>Create</Button>
                        }
                    </Col>
                </Row>

            </Modal.Footer>
        </Modal>
    </div>
}
export default CreateEditModal;
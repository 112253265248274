import React, {useCallback, useEffect, useState} from 'react';
import LookupModel from "../../../models/lookupModel";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../../actions/common";
import {GetCustomerMultipleCodData} from "../../../api/data";
import {Button, Card, Col, Row} from "react-bootstrap";
import Select from "react-select";
import AppStateModel from "../../../models/appStateModel";
import DynamicDataTable from "../../../components/dynamicDataTable";
import {DateRangePicker} from "react-dates";
import moment from "moment";

const MultipleCustomers = () => {
  const [multipleCustomers, setMultipleCustomers] = useState<LookupModel[]>([])
  const [ReturnDateFromFocusedInput, setReturnDateFromFocusedInput] = useState<any>();

  const dispatch = useDispatch()
  const [data, setData] = useState<any>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const customers = useSelector((state: AppStateModel) => state.Meta?.customers);
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.shipments
  );

  const refreshData = useCallback(async (isExport?: boolean, exportAll?: boolean) => {
    dispatch(setLoading(true))
    const res = await GetCustomerMultipleCodData({
      exportToExcel: isExport,
      exportAll: exportAll,
      dateTo: endDate ?? null,
      dateFrom: startDate ?? null,
      customersIds: multipleCustomers.map(x => x.value),
    })
    if (res.succeeded) {
      if (isExport && res.data.location) {
        window.open(res.data.location, "_blank", "noreferrer");
      } else {
        setData(res.data)
      }
    }
    dispatch(setLoading(false))
  }, [startDate, endDate, multipleCustomers])
  const onClear = () => {
    setMultipleCustomers([])
    setEndDate(undefined)
    setStartDate(undefined)
  }
  useEffect(() => {
    refreshData(false, (multipleCustomers.length == 0)).then()
  }, [refreshData, multipleCustomers])


  return (
    <div style={{ margin: '10px 40px 0px' }}>
      <Card>
        <Card.Header>

          <Row>
            <Col lg={2} className="px-md-4">
              <Select
                isMulti
                isClearable={false}
                value={multipleCustomers}
                key={`selectedStatuses${multipleCustomers}`}
                className="basic-multi-select"
                escapeClearsValue={true}
                options={customers}
                placeholder={content.model.customerName}
                onChange={(selectedOptions) => {
                  let customersModel: LookupModel[] = [];
                  if (Array.isArray(selectedOptions)) {
                    selectedOptions?.forEach((element: any) => {
                      customersModel.push({
                        value: element.value,
                        label: element.label,
                      });
                    });
                  }
                  setMultipleCustomers(customersModel);
                }}
              />
            </Col>
            <Col lg={2} className="px-md-4">
              <DateRangePicker
                noBorder={false}
                isOutsideRange={() => false}
                small
                minimumNights={0}
                startDatePlaceholderText="Date from"
                endDatePlaceholderText="Date To"
                startDate={
                  startDate ? moment(startDate) : null
                }
                startDateId="123"
                endDate={
                  startDate ? moment(endDate) : null
                }
                endDateId="321"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate?.toDate());
                  setEndDate(endDate?.toDate())
                }}
                focusedInput={ReturnDateFromFocusedInput}
                onFocusChange={(focusedInput: any) => {
                  setReturnDateFromFocusedInput(focusedInput);
                }}
              />

            </Col>
            <div className="d-flex justify-content-end w-100 px-2">
              <div className="p-2"/>
              <Button
                onClick={() => refreshData(false, true)}>
                Refresh
              </Button>
              <div className="p-2"/>
              <Button disabled={multipleCustomers.length == 0}
                      onClick={() => refreshData(true, false)}>
                Export
              </Button>
              <div className="p-2"/>

              <Button
                onClick={() => refreshData(true, true)}>
                Export all
              </Button>
              <div className="p-2"/>

              <Button variant={"danger"}
                      onClick={onClear}>Clear</Button>
            </div>
          </Row>
        </Card.Header>
        <Card.Body>
          <DynamicDataTable data={{
            matches: data?.data ?? [],
            total: data?.count
          }}/>
        </Card.Body>
      </Card>
    </div>
  )
}

const customControlStyles = (base: any) => ({
  ...base,
  height: 21!,
});

export default MultipleCustomers;

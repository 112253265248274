import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {IGenerateReportModel} from "../../models/appStateModel";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import DynamicFilters, {IFilter} from './Components/DynamicFilter';
import DynamicDataFields from "./Components/DynamicDataFields";
import DynamicSort from "./Components/DynamicSort";
import {Button} from "react-bootstrap";
import {
  GenerateExcelReportAsync,
  GenerateReportAsync
} from "../../actions/shipmentsActions";
import BTable from "react-bootstrap/Table";
import Pager from "../components/Pager";
import PagedModel from "../../models/pagedModel";


export const Reports = () => {
  const dispatch = useDispatch()

  const [clicked, setClicked] = useState(false)
  const [reportScheme, setReportScheme] = useState<IGenerateReportModel>({
    dataFields: ["Number"],
    orderBy: "CreatedOn",
    offset: 0,
    limit: 20
  })

  useEffect(() => {
    refreshData()
  }, [reportScheme])
  const onAddField = (selectedOption: any) => {

    const test = reportScheme.dataFields.find(x => x == selectedOption.value)
    if (!test) {
      setReportScheme((r) => ({
        ...r,
        dataFields: [...r.dataFields as string[], selectedOption?.value as string]
      }))
    }
  }
  const setDataFilters = (filters: IFilter[]) => {

    setReportScheme((r) => ({
      ...r,
      filters: filters.filter(x => x.type === "shipment"),
      TraceFilters: filters.filter(x => x.type === "trace"),
    }))

  }

  const camelToSpace = (text: string) => {

    if (!text)
      return "";
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  const onChangeSort = (s: string) => {
    setReportScheme((r) => ({
      ...r,
      orderBy: s
    }))
  }
  const [data, setData] = useState<PagedModel<object[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const onSubmit = () => {
    setClicked(true)
    refreshData()
  }
  const onExportToExcel = () => {
    setClicked(true)
    dispatch(GenerateExcelReportAsync({ ...reportScheme, isExcel: true }))
  }
  const refreshData = () => {
    dispatch(GenerateReportAsync(reportScheme, (i) => {

      setData(i)

    }))
  }
  useEffect(() => {
    refreshData()
  }, [reportScheme])
  const onRemoveField = (value: string) => {
    setReportScheme((r) => ({
      ...r,
      dataFields: [...r.dataFields.filter(x => x != value)]
    }))
  }
  useEffect(() => {
    if (clicked) {
      refreshData()

    }

  }, [pageNumber])
  return (
    <div style={{ padding: "1vw" }}>
      <Card>
        <Card.Header>
          <Row>
            <Col lg={2} className="px-md-2">
              <InputGroup className="mb-1">


              </InputGroup>
            </Col>
          </Row>
          <Row className="px-3 p-2 d-flex justify-content-between">


          </Row>
        </Card.Header>
        <Card.Body>

          <Row className={"d-flex justify-content-between"}>
            <Col md={10}>
              <DynamicDataFields selectedFields={reportScheme.dataFields}
                                 onAdd={onAddField}
                                 onRemove={onRemoveField}/>
            </Col>
            <Col md={2}>
              <DynamicSort value={reportScheme.orderBy}
                           onChange={onChangeSort}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <DynamicFilters onSetDataFilters={setDataFilters}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button size={"lg"} onClick={onSubmit}>Submit</Button>
            </Col>
            <Col>
              <Button size={"lg"} disabled={data?.total == 0}
                      onClick={onExportToExcel}>
                Export to Excel
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>

        <Card.Body>
          <Row className={"px-2"}>

            <BTable striped bordered hover size="md">
              <thead>

              <tr>

                {(data?.matches && data?.matches?.length > 0) &&
                  Object.keys(data?.matches?.[0])?.map((i) => {
                    return <th key={i}>{camelToSpace(i)}</th>
                  })
                }
              </tr>
              </thead>

              <tbody>
              {data?.matches &&

                data?.matches?.map((x) => {
                    return (
                      <tr key={x.toString()}>
                        {Object.values(x).map(y => {
                          return <td>{y?.toString()}</td>
                        })}
                      </tr>
                    )
                  }
                )
              }
              </tbody>
            </BTable>
          </Row>
          {data?.matches && data?.matches?.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pager
                currentPageNumber={pageNumber}
                numberOfPages={Math.ceil(
                  data.total! / 20
                )}
                onChange={(page: number) => {
                  setReportScheme((r) => ({ ...r, offset: (20 * (page - 1)) }));
                  setPageNumber(page)
                }}
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  )

}

export default Reports

import {SearchOtp} from "../api/data";
import PagedModel from "../models/pagedModel";
import {SET_OTP, SET_OTP_CRITERIA} from "../store/types";
import {SetAlert, setLoading} from "./common";
import {IOtp, SearchOtpCriteria} from "../models/otp";

export const SearchOtpAsync = () => {
    return async (dispatch: any, getState: any) => {
        const state = getState()
        dispatch(setLoading(true));
        const res = await SearchOtp(state.SearchOtpCriteria);
        if (res.succeeded) {
            dispatch(searchOtp(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};


export const searchOtp = (searchOtpResult: PagedModel<IOtp>) => {
    return {
        type: SET_OTP,
        payload: {
            searchOtpResult,
        },
    };
};

export const setOtpCriteria = (filters: SearchOtpCriteria) => {
    return {
        type: SET_OTP_CRITERIA,
        payload: {
            filters,
        },
    };
};

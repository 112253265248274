import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap"
import ScanListWithScan from "../shipments/components/shipmentListWithScan";
import ReactSelect from "react-select";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import LookupModel from "../../models/lookupModel";
import { SearchCitiesAsync} from "../../actions/citiesActions";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../models/appStateModel";
import {setShipmentsCriteriaAsync} from "../../actions/shipmentsActions";
import {AddTierCity, UpdateCity} from "../../api/data";
import {setLoading} from "../../actions/common";

interface IProps {
    onHide: () => void
    id: number
    title?:string
}

const AddModal = (props: IProps) => {
    const data = useSelector((state: AppStateModel) => state.CityTiers);

    const customControlStyles = (base: any) => ({
        ...base,
        height: 21!,
    });
    const [selectedTier, setSelectedTier] = React.useState<{label:string,value:number| string}>();



    const dispatch=useDispatch()

    const onSubmit = async (id:number) => {
        dispatch(setLoading(true));
        const res = await AddTierCity(id,props.id);
        if(res.succeeded){
            dispatch(SearchCitiesAsync());
            props.onHide()
        }
        dispatch(setLoading(false));
    }
    //cities/addTier
    return (
        <Modal
            show={true}
            onHide={() => props.onHide()}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    { props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Select
                    value={selectedTier}
                    styles={{control: customControlStyles}}
                    options={data?.matches?.map(tier => ({label: tier.name, value: tier.id as any}))}
                    isClearable
                    placeholder={"Tier"}
                    onChange={(selectedOption) => {
                        if (Array.isArray(selectedOption)) {
                            throw new Error(
                                "Unexpected type passed to ReactSelect onChange handler"
                            );
                        }
                        setSelectedTier({
                            label: selectedOption?.label!,
                            value: selectedOption?.value!,
                        });
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={()=>onSubmit(selectedTier?.value as number)}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default AddModal

import PagedModel from "../../../models/pagedModel";
import React from "react";
import BTable from "react-bootstrap/Table";
import {StationsModel} from "../../../models/StationsModel";

interface IProps {
  data: PagedModel<StationsModel>

}

const StationsTable = (props: IProps) => {

  return (<BTable striped bordered hover size="xs">
    <thead>

    <tr>
      <th>{"Id"}</th>
      <th>{"Code"}</th>
      <th>{"Name"}</th>
      <th>{"Country"}</th>
      <th>{"City"}</th>
    </tr>
    </thead>

    <tbody className={"px-2"}>
    
    {props.data.matches.map((row: StationsModel, key) => (
      <tr key={key}>
        <td>
          {row.id}
        </td>
        <td>
          {row.code}
        </td>
        <td>
          {row.name}
        </td>
        <td>
          {row.country}
        </td>
        <td>
          {row.city}
        </td>

      </tr>))}
    </tbody>
  </BTable>)
}
export default StationsTable;

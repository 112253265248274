import React, {FunctionComponent} from "react";
import BTable from "react-bootstrap/Table";
import PagedModel from "../models/pagedModel";
import ToLocalDateTime from "../actions/date";
import {useSelector} from "react-redux";
import AppStateModel from "../models/appStateModel";

interface IProps {
  data: PagedModel<any> | undefined | null
}

const DynamicDataTable: FunctionComponent<IProps> = ({ data }) => {
  const camelToSpace = (text: string) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  const employees = useSelector((state: AppStateModel) => state.Meta?.employees);
  const stations = useSelector((state: AppStateModel) => state.Meta?.stations);
  const stationName = (stationId: string) => {
    return stations?.find((x) => x.value == stationId)?.label ?? stationId;
  }
  const countryCode = useSelector(
    (state: AppStateModel) => state.Profile.currentTenant?.countryCode
  );
  const parseValue = (name: string, value: any) => {


    if (value == 0) {
      return 0
    }
    if (!value) {
      return ""
    }

    if (typeof value != "string")
      return value.toString()


    if ((value as string).startsWith("http")) {

      return <a target="_blank" href={value as string}>
        {"POD"}
      </a>
    }
    if (new RegExp("[0-9a-fA-F]{32}").test(value as string)) {
      return employees?.find(x => x.value == value)?.label ?? value
    }

    if (value.match("(0?[1-9]|[12][0-9]|3[01])/[0-9]+/[0-9]+\\s[0-9]+:[0-9]+\\s[a-zA-Z]+")) {
      return ToLocalDateTime(value, countryCode ?? "SA")
    }

    if (new RegExp("(^\\d{1}$)").test(value as string)) {
      return stationName(value.toString())
    }
    return value
  }
  return (
    <BTable striped bordered hover size="md">
      <thead>
      <tr>
        {data && data.total > 0 &&
          Object.keys(data.matches[0]).map((i, index) =>
            <th key={index}>
              {camelToSpace(i)}
            </th>
          )}
      </tr>
      </thead>
      <tbody>
      {data && data.total > 0 &&
        data.matches.map((x, index) => {
            return (
              <tr key={index}>
                {Object.entries(x).map(([x, y]) =>
                  <td>
                    {parseValue(x, y)}
                  </td>
                )}
              </tr>
            )
          }
        )
      }
      </tbody>
    </BTable>
  )
}

export default React.memo(DynamicDataTable)

import React, { useEffect, useState } from "react"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import Pager from "../components/Pager";
import Col from "react-bootstrap/Col";
import { INotification } from "../../models/notifications";
import CreateNotificationModal from "./modals/createNotificationModal";
import { SearchNotificationsAsync, setNotificationsRequest } from "../../actions/notificationsActions";
import AppState from "../../models/appStateModel";

const Notifications = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch()
    const data = useSelector(
        (state: AppState) => state.SearchNotifications
    );
    const search = useSelector(
        (state: AppState) => state.NotificationsRequest
    );

    useEffect(() => {
        dispatch(SearchNotificationsAsync())
    }, [])

    return (
        <Card className={"p-3 pt-2"}>

            {
                isModalOpen &&
                <CreateNotificationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            }

            <Card.Body>
                <Row className={" mb-3 d-flex justify-content-between  px-3 shadow pt-4 pb-2 rounded border w-100"}>

                    <Col md={11}>
                        <h3>
                            Notifications
                        </h3>
                    </Col>
                    <Col md={1}>
                        <Button
                            onClick={() => setIsModalOpen(true)}
                        >
                            {"Create"}
                        </Button>
                    </Col>


                </Row>
                <Row className={"px-2"}>
                    <BTable striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "33%" }}>Id</th>
                                <th style={{ width: "33%" }}>Title</th>
                                <th style={{ width: "33%" }}>Message</th>
                                <th style={{ width: "33%" }}>Created by</th>
                                <th style={{ width: "33%" }}>Created on</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.matches &&
                                data.matches?.map((item: INotification) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id} </td>
                                            <td>{item.title ?? ''}</td>
                                            <td>{item.message ?? ''}</td>
                                            <td>{item.createdBy ?? ''}</td>
                                            <td>{item.createdOn ?? ''}</td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </BTable>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Pager
                            currentPageNumber={search?.pageNumber ?? 1}
                            numberOfPages={data.total ? Math.ceil(
                                data?.total / search?.pageSize!
                            ) : 1}
                            onChange={(page: number) => {
                                dispatch(
                                    setNotificationsRequest({
                                        pageNumber: page,
                                    })
                                );
                                dispatch(SearchNotificationsAsync())
                            }}
                        />
                    </div>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default Notifications
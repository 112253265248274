import React, {useRef, useState} from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Select from "react-select";
import ScanListWithScan from "./shipmentListWithScan";
import ShipmentModel from "../../../models/shipmentModel";
import Form from "react-bootstrap/Form";


interface IProps {
  visible: boolean;
  onHide: () => any;
  onDone: (ids: string[], reason: string, location: string) => void;
  title: string;
  submitButton: string;
}

const HoldShipmentModal = (props: IProps) => {
  const [reason, setReason] = useState<string>("")
  const [location, setLocation] = useState<string>("")
  const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
  const reasons = useSelector((state: AppStateModel) => state.Meta?.statusReasons);
  const ref = useRef<any>(null)
  const [showShipments, setsShowShipments] = useState(false)

  const onSubmit = () => {
    let ids: string[] = [];
    shipments.forEach((s) => {
      ids.push(s.id);
    });
    props.onDone(ids, reason, location);
  };
  const onKeyPressLocation = (e: any) => {
    if (e.keyCode === 13) {
      setsShowShipments(true)
    }
  };
  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: '40%' }}>
          <label>Location</label>
          <Form.Control
            placeholder="Location"
            value={location}
            onChange={(e: any) => setLocation(e.target.value)}
            onKeyDown={onKeyPressLocation}
            autoFocus={true}
            ref={ref}
          />
        </div>
        <view style={{ paddingTop: 13 }}>
          {
            showShipments &&
            <ScanListWithScan
              allowedStatuses={["15", "26", "20", "80"]}
              setShipments={setShipments}
            />
          }
        </view>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: "20%" }}>
          <Select
            isMulti={false}
            value={reasons?.find(x => x.value == reason)}
            className="basic-multi-select"
            escapeClearsValue={true}
            options={reasons}
            placeholder={"Reason"}
            onChange={(e: any) => setReason(e?.value ?? "")}
          />
        </div>

        <Button

          onClick={onSubmit}
        >
          {props.submitButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HoldShipmentModal;

import React from 'react';

import Pagination from 'react-bootstrap/Pagination';


interface IProps {
	currentPageNumber: number;
	numberOfPages: number;
	onChange: (page: number) => any;
}

const Component = (props: IProps) => {
	return (
		<Pagination style={{ color: "#6d7882" }}>
			<Pagination.First
				disabled={props.currentPageNumber === 1}
				onClick={() => props.onChange(1)}
			/>
			<Pagination.Prev
				disabled={props.currentPageNumber === 1}
				onClick={() => props.onChange(props.currentPageNumber - 1)}
			/>
			{props.currentPageNumber !== 1 && (
				<>
					<Pagination.Item onClick={() => props.onChange(1)}>
						{1}
					</Pagination.Item>
					<Pagination.Ellipsis />
				</>
			)}

			{props.currentPageNumber - 2 > 1 && (
				<Pagination.Item
					onClick={() => props.onChange(props.currentPageNumber - 2)}
				>
					{props.currentPageNumber - 2}
				</Pagination.Item>
			)}
			{props.currentPageNumber - 1 > 1 && (
				<Pagination.Item
					onClick={() => props.onChange(props.currentPageNumber - 1)}
				>
					{props.currentPageNumber - 1}
				</Pagination.Item>
			)}
			<Pagination.Item active>{props.currentPageNumber}</Pagination.Item>
			{props.currentPageNumber + 1 < props.numberOfPages && (
				<Pagination.Item
					disabled={props.currentPageNumber + 1 > props.numberOfPages}
					onClick={() => props.onChange(props.currentPageNumber + 1)}
				>
					{props.currentPageNumber + 1}
				</Pagination.Item>
			)}
			{props.currentPageNumber + 2 < props.numberOfPages && (
				<Pagination.Item
					disabled={props.currentPageNumber + 2 > props.numberOfPages}
					onClick={() => props.onChange(props.currentPageNumber + 2)}
				>
					{props.currentPageNumber + 2}
				</Pagination.Item>
			)}

			{props.currentPageNumber !== props.numberOfPages && (
				<Pagination.Ellipsis />
			)}
			{props.currentPageNumber !== props.numberOfPages && (
				<Pagination.Item onClick={() => props.onChange(props.numberOfPages)}>
					{props.numberOfPages}
				</Pagination.Item>
			)}
			<Pagination.Next
				disabled={props.currentPageNumber === props.numberOfPages}
				onClick={() => props.onChange(props.currentPageNumber + 1)}
			/>
			<Pagination.Last
				disabled={props.currentPageNumber === props.numberOfPages}
				onClick={() => props.onChange(props.numberOfPages)}
			/>
		</Pagination>
	);
};

export default React.memo(Component);

import {
    CollectCod,
    CreateBulkShipments,
    CreateShipment,
    GenerateReport,
    GetShipmentDetails,
    GetShipmentDetailsWithReport,
    MapShipmentsFromExcel, MapShipmentsWithoutStoreFromExcel,
    PrintBulkLabelsAsync,
    SearchShipment, SearchShipmentForForcePickup,
    SearchShipmentForPickup,
} from "../api/data";
import printJS from "print-js";
import {
    SET_SELECTED_SHIPMENT,
    SET_SHIPMENTS,
    SET_SHIPMENTS_CRITERIA
} from '../store/types';
import * as DataClient from '../api/operationsClient';

import {SetAlert, setLoading} from "./common";
import AppStateModel, {IGenerateReportModel} from '../models/appStateModel';
import PagedModel from "../models/pagedModel";
import ShipmentModel from "../models/shipmentModel";
import SearchShipmentsCriteria from "../models/searchShipmentsCriteria";
import IUpdateShipmentModel from "../models/updateShipmentModel";
import {AssignToPartner} from "../api/operationsClient";


export const GenerateReportAsync = (reportScheme: IGenerateReportModel, onFinish: (data: any) => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await GenerateReport(reportScheme)
        dispatch(setLoading(false));
        if (res.succeeded) {
            onFinish(res?.data);

            if (res.data?.total === 0) {
                dispatch(SetAlert({
                    visible: true,
                    message: "No matches were found for those search criteria",
                    title: "No matches",
                    kind: "warning",
                }));
            }
            if (res.data?.total !== 0) {
                dispatch(SetAlert({
                    visible: true,
                    message: "Report Generated successfully",
                    title: "Success",
                    kind: "success",
                }));
            }


        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data!,
                title: "Error",
                kind: "error",
            }));
        }
    };
};

export const GenerateExcelReportAsync = (reportScheme: IGenerateReportModel) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        const res = await GenerateReport(reportScheme)
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer");
            dispatch(SetAlert({
                visible: true,
                message: "Report Exported successfully",
                title: "Success",
                kind: "success",
            }));


        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data!,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};

export const CollectCodAsync = (ids: any[], courierId: string, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await CollectCod(ids, courierId);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer");
            dispatch(SetAlert({
                visible: true,
                message: "COD collected successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data!,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};


export const CreateShipmentAsync = (shipment: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await CreateShipment(shipment);

        if (res.succeeded) {

            if (Array.isArray(res.data)) {
                res.data?.forEach((shipment: any) => {
                    window.open(shipment.labelUrl, "_blank", "noreferrer");
                });
            } else {
                window.open(res.data?.labelUrl, "_blank", "noreferrer");
            }

            dispatch(
                SetAlert({
                    visible: true,
                    message: "Shipment is created successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};
export const UpdateShipmentAsync = (shipment: IUpdateShipmentModel, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        //let res = await UpdateShipment(shipment);
        let res = await DataClient.Update(shipment.id, shipment.description, shipment.consignee)
        if (res.succeeded) {
            window.open(res.data.labelUrl, "_blank", "noreferrer");
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Shipment is updated successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const cancelShipmentAsync = (shipment: ShipmentModel, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.Cancel(shipment.id)
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Shipment is canceled successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};

export const ExternalTransitToDeliveredFlowAsync = (numbers: string[], onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.ExternalTransitToDeliveredFlow(numbers)
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Shipments were added successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};


export const AssignToPartnerAsync = (numbers: string[], partnerId: number, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.AssignToPartner(numbers, partnerId)
        if (res.succeeded) {
            dispatch(
                SetAlert({
                    visible: true,
                    message: "Shipments were assigned to a partner successfully",
                    title: "Success",
                    kind: "success",
                })
            );
            onFinish();
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
}


export const AssignToCourierAsync = (
    ids: any,
    courierId: string,
    onFinish: () => any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.AssignForDelivery(ids, courierId)
        if (res.succeeded) {
            onFinish();
            window.open(res.data.reportUrl, "_blank", "noreferrer");
            dispatch(SetAlert({
                visible: true,
                message: "Shipment are assigned to courier",
                title: "Success",
                kind: "success"
            }));
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};

export const CompleteDeliveryCycleAsync = (
    ids: any,
    courierId: string,
    onFinish: () => any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.CompleteDeliveryCycle(ids, courierId)
        if (res.succeeded) {
            onFinish();
            dispatch(SetAlert({
                visible: true,
                message: "Shipment are Delivered",
                title: "Success",
                kind: "success"
            }));
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};

export const ReturnShipmentsToCompanyAsync = (
    ids: any,
    driverId: string,
    onFinish: () => any
) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        let res = await DataClient.AssignForReturn(ids, driverId);
        if (res.succeeded) {
            onFinish();
            dispatch(SetAlert({
                visible: true,
                message: "Shipments are returned to Company",
                title: "Success",
                kind: "success",
            }));
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};


export const AssignToDriverAsync = (ids: any, courierId: string, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        let res = await DataClient.AssignForPickup(ids, courierId);
        if (res.succeeded) {
            dispatch(SetAlert({
                visible: true,
                message: "Shipment is assigned to Driver successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};

export const MarkAsLostAsync = (ids: string[], reason: string, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));

        let res = await DataClient.Lose(ids, reason);
        if (res.succeeded) {
            dispatch(SetAlert({
                visible: true,
                message: "Shipments are marked as lost successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};

export const ReceiveShipmentAsync = (ids: any, station: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.Receive(ids, station);
        if (res.succeeded) {
            window.open(res.data.reportUrl, "_blank", "noreferrer");
            dispatch(SetAlert({
                visible: true,
                message: "Shipment is received successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }))
        }
        dispatch(setLoading(false));
    };
};

export const ForcePickUpShipmentAsync = (ids: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.ForcePickup(ids);
        if (res.succeeded) {
            dispatch(SetAlert({
                visible: true,
                message: "Shipment was picked up successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }))
        }
        dispatch(setLoading(false));
    };
};


export const TransferShipmentAsync = (ids: any, stationId: any, courierId: string, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.AssignForTransfer(ids, courierId, stationId);
        if (res.succeeded) {
            // window.open(res.data.reportUrl, "_blank", "noreferrer");
            dispatch(SetAlert({
                visible: true,
                message: "Shipment is transfered successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }))
        }
        dispatch(setLoading(false));
    };
};

export const StoreShipmentAsync = (id: string, locationId: string | null, onFinish: () => any, onFail: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.StoreShipment(id, locationId);
        if (res.succeeded) {
            dispatch(SetAlert({
                visible: true,
                message: "Shipment stored successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }))
            onFail()
        }
        dispatch(setLoading(false));
    };
};
export const HoldShipmentAsync = (ids: string[], reason: string, location: string, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        let res = await DataClient.HoldShipment(ids, reason, location);
        if (res.succeeded) {
            dispatch(SetAlert({
                visible: true,
                message: "Shipment Held successfully",
                title: "Success",
                kind: "success",
            }));
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }))
        }
        dispatch(setLoading(false));
    };
};

export const ReceiveReturnedShipmentsAsync = (data: any, station: any, onFinish: () => any) => {
    return async (dispatch: any) => {
        dispatch(setLoading(true));
        // let recieveData : receiveShipmentModel={ShipmentIds:data,StationId:station}
        // let res = await ReceiveFailedDelvieryShipments(recieveData);
        let res = await DataClient.Receive(data, station);
        if (res.succeeded) {
            dispatch(SetAlert({
                visible: true,
                message: "Failed Delivery Shipments are received",
                title: "Success",
                kind: "success",
            }));
            window.open(res.data.reportUrl, "_blank", "noreferrer");
            onFinish();
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};


export const PrintLabelAsync = (id: string, print: boolean = false) => {
    return async () => {
        let response = await GetShipmentDetailsWithReport(id, print);
        if (response.succeeded) {
            if (!print) {
                window.open(response.data.labelUrl, "_blank", "noreferrer")
            } else {
                printJS({
                    printable: response.data.labelBase64,
                    type: 'pdf',
                    base64: true
                });
            }
        }
    };
}


export const PrintBulkLabels = (ids: string[]) => {
    return async () => {
        let response = await PrintBulkLabelsAsync(ids);
        if (response.succeeded) {

            printJS({
                printable: response.data.labelBase64,
                type: 'pdf',
                base64: true
            });

        }
    };
}

export const searchShipmentsAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state = getState();
        console.log(state, 'ssssssssssssssssss')
        const partnerId = state.Profile?.partnerId;
        let response = await SearchShipment(state.SearchShipmentsCriteria, false, partnerId);
        //should be side effect
        dispatch(searchShipments(response.data));
    };
};
export const searchPickupShipmentsAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state = getState();
        let response = await SearchShipmentForPickup(state.SearchShipmentsCriteria, false);
        //should be side effect
        dispatch(searchShipments(response.data));
    };
};

export const searchForcePickupShipmentsAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state = getState();
        let response = await SearchShipmentForForcePickup(state.SearchShipmentsCriteria, false);
        //should be side effect
        dispatch(searchShipments(response.data));
    };
};


export const setShipmentsCriteriaAsync = (filters: SearchShipmentsCriteria) => {
    return async (dispatch: any) => {
        dispatch(setShipmentsCriteria(filters));
        dispatch(searchShipmentsAsync());
    };
};
export const setShipmentsForPickupCriteriaAsync = (filters: SearchShipmentsCriteria) => {
    return async (dispatch: any) => {
        dispatch(setShipmentsCriteria(filters));
        dispatch(searchPickupShipmentsAsync());
    };
};

export const setShipmentsForForcePickupCriteriaAsync = (filters: SearchShipmentsCriteria) => {
    return async (dispatch: any) => {
        dispatch(setShipmentsCriteria(filters));
        dispatch(searchForcePickupShipmentsAsync());
    };
};

export const setShipmentDetailsAsync = (id: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        let response = await GetShipmentDetails(id);
        dispatch(setLoading(false));
        dispatch(setShipment(response.data));
    };
};


export const setShipmentsCriteria = (filters: SearchShipmentsCriteria) => {
    return {
        type: SET_SHIPMENTS_CRITERIA,
        payload: {
            filters,
        },
    };
};

const searchShipments = (searchShipmentsResult: PagedModel<ShipmentModel>) => {
    return {
        type: SET_SHIPMENTS,
        payload: {
            searchShipmentsResult,
        },
    };
};


const setShipment = (shipment: ShipmentModel) => {
    return {
        type: SET_SELECTED_SHIPMENT,
        payload: {
            shipment,
        },
    };
};

export const ImportFromExcelAsync = (
    fileUrl: string,
    storeId: string,
    onFinish: () => any,
    singlePdf?: boolean,
    printShipments?: boolean
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        let state: AppStateModel = getState();
        let res = await MapShipmentsFromExcel(fileUrl, storeId);

        if (res.succeeded) {

            let shipments = res.data;
            shipments.forEach((s: any) => {
                s.companyId = state.Profile.id;
                s.singlePagePdf = singlePdf;
            });

            let cres = await CreateBulkShipments(shipments);
            if (cres.succeeded) {
                if (Array.isArray(cres.data) && printShipments) {
                    cres.data?.forEach((shipment: any) => {
                        window.open(shipment.labelUrl, "_blank", "noreferrer");
                    });
                } else if (printShipments) {
                    window.open(cres.data.labelUrl, "_blank", "noreferrer");
                }


                dispatch(
                    SetAlert({
                        visible: true,
                        message: "Shipments are created",
                        title: "Success",
                        kind: "success",
                    })
                );

                onFinish();
            } else {
                dispatch(
                    SetAlert({
                        visible: true,
                        data: res.data,
                        title: "Error",
                        kind: "error",
                    })
                );
            }


        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};


export const ImportShipmentsWithoutStore = (
    fileUrl: string,
    onFinish: () => any,
    singlePdf?: boolean,
    printShipments?: boolean
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        let state: AppStateModel = getState();
        let res = await MapShipmentsWithoutStoreFromExcel(fileUrl);

        if (res.succeeded) {

            let shipments = res.data;
            shipments.forEach((s: any) => {
                s.companyId = state.Profile.id;
                s.singlePagePdf = singlePdf;
            });

            let cres = await CreateBulkShipments(shipments);
            if (cres.succeeded) {
                if (Array.isArray(cres.data) && printShipments) {
                    cres.data?.forEach((shipment: any) => {
                        window.open(shipment.labelUrl, "_blank", "noreferrer");
                    });
                } else if (printShipments) {
                    window.open(cres.data.labelUrl, "_blank", "noreferrer");
                }
                dispatch(
                    SetAlert({
                        visible: true,
                        message: "Shipments are created",
                        title: "Success",
                        kind: "success",
                    })
                );

                onFinish();
            } else {
                dispatch(
                    SetAlert({
                        visible: true,
                        data: res.data,
                        title: "Error",
                        kind: "error",
                    })
                );
            }
        } else {
            dispatch(
                SetAlert({
                    visible: true,
                    data: res.data,
                    title: "Error",
                    kind: "error",
                })
            );
        }
        dispatch(setLoading(false));
    };
};






import React, {useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";

//smart components
import CreateModal from "./components/create";
import UpdateModal from "./components/update";
import AddStoreModal from "./components/addStore";

//components
import BTable from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";

//models
import AppStateModel from "../../models/appStateModel";
import CustomerModel from "../../models/customers/customerModel";

//actions
import {
  ConfirmCustomerAsync,
  SearchCustomersAsync,
  setCustomersCriteriaAsync,
} from "../../actions/customersActions";
import {PrepareMetaDataAsync} from "../../actions/common";
import TextInput from "../components/TextInput";
import Authorized from "../../components/Authorized";
import {
  Admin,
  CustomerService,
  CustomerServiceManager,
  Sales,
} from "../../models/jobRole";
import ConfirmModal from "./components/confirmModal";
import {convertUTCHour} from "../../actions/date";
import Pager from "../components/Pager";
import {AiOutlineCloudDownload} from "react-icons/ai";

type modalNames =
  | "none"
  | "create"
  | "addStore"
  | "viewStores"
  | "update"
  | "confirm"
  | "changePassword";

const Screen = () => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.customers
  );
  const pagedCustomers = useSelector(
    (state: AppStateModel) => state.PagedCustomers
  );
  const profile = useSelector(
    (state: AppStateModel) => state.Profile
  );
  const searchCustomersCriteria = useSelector(
    (state: AppStateModel) => state.SearchCustomersCriteria
  );
  const [
    selectedCustomerForEdit,
    setSelectedCustomerForEdit,
  ] = useState<CustomerModel | null>(null);
  const [modalOn, setModalOn] = useState<modalNames>("none");
  const dispatch = useDispatch();
  React.useEffect(() => {
    batch(()=> {
      dispatch(PrepareMetaDataAsync());
      dispatch(SearchCustomersAsync());
    })
  }, [dispatch]);

  const renderModal = (val: modalNames) => {
    switch (val) {
      case "create":
        return (
          <CreateModal
            visible={true}
            onHide={() => {
              dispatch(SearchCustomersAsync());
              setModalOn("none");
            }}
          />
        );
      case "confirm":
        return (
          <ConfirmModal
            onHide={() => {
              dispatch(SearchCustomersAsync());
              setModalOn("none");
            }}
            onClick={() => {
              dispatch(ConfirmCustomerAsync(selectedCustomerForEdit, () => {
                dispatch(SearchCustomersAsync());
                setModalOn("none");
              }));

            }}
          />
        );
      case "update":
        return selectedCustomerForEdit != null ? (
          <UpdateModal
            addStoreModal={() => {
              setModalOn("addStore");
            }}
            customer={selectedCustomerForEdit}
            visible={true}
            onHide={() => {
              dispatch(SearchCustomersAsync());
              setModalOn("none");
              setSelectedCustomerForEdit(null);
            }}
          />
        ) : (
          <></>
        );

      case "addStore":
        return selectedCustomerForEdit != null ? (
          <AddStoreModal
            customer={selectedCustomerForEdit}
            visible={true}
            onHide={() => {
              dispatch(SearchCustomersAsync());
              setModalOn("none");
              setSelectedCustomerForEdit(null);
            }}
          />
        ) : (
          <></>
        );
    }
  };
  const handleKeyPress = (event: any, callback: () => any) => {
    if (event.key === "Enter") {
      callback();
    }
  };

  const onClear = () => {
    dispatch(
      setCustomersCriteriaAsync({
        pageNumber: 1,
        pageSize: 20,
        emailContains: undefined,
        lookup: false,
        nameContains: undefined,
        phoneContains: undefined,
      })
    );
    dispatch(SearchCustomersAsync());
  };

  return (
    <div style={{ padding: "1vw" }}>
      {renderModal(modalOn)}

      <Card>
        <Card.Header>
          <Row>
            <Col lg={2} className="px-md-1">
              <TextInput
                placeholder={content.nameContains}
                value={searchCustomersCriteria?.nameContains ?? ""}
                onChange={(val) =>
                  dispatch(
                    setCustomersCriteriaAsync({
                      nameContains: val,
                    })
                  )
                }
                onKeyPress={(e: any) =>
                  handleKeyPress(e, () => {
                    dispatch(
                      setCustomersCriteriaAsync({
                        ...searchCustomersCriteria,
                        pageNumber: 1,
                        pageSize: 20,
                      })
                    );
                    dispatch(SearchCustomersAsync());
                  })
                }
              />
            </Col>
            <Col lg={2} className="px-md-1">
              <TextInput
                placeholder={"Mobile"}
                value={searchCustomersCriteria?.phoneContains ?? ""}
                onChange={(val) =>
                  dispatch(
                    setCustomersCriteriaAsync({
                      phoneContains: val,
                    })
                  )
                }
                onKeyPress={(e: any) =>
                  handleKeyPress(e, () => {
                    dispatch(
                      setCustomersCriteriaAsync({
                        ...searchCustomersCriteria,
                        pageNumber: 1,
                        pageSize: 20,
                      })
                    );
                    dispatch(SearchCustomersAsync());
                  })
                }
              />
            </Col>
            <Col lg={2} className="px-md-1">
              <TextInput
                placeholder={content.emailContains}
                value={searchCustomersCriteria?.emailContains ?? ""}
                onChange={(val) =>
                  dispatch(
                    setCustomersCriteriaAsync({
                      emailContains: val,
                    })
                  )
                }
                onKeyPress={(e: any) =>
                  handleKeyPress(e, () => {
                    dispatch(
                      setCustomersCriteriaAsync({
                        ...searchCustomersCriteria,
                        pageNumber: 1,
                        pageSize: 20,
                      })
                    );
                    dispatch(SearchCustomersAsync());
                  })
                }
              />
            </Col>
            <Col lg={0.5} className="px-md-1">
              <Button
                onClick={() => {
                  dispatch(
                    setCustomersCriteriaAsync({
                      ...searchCustomersCriteria,
                      pageNumber: 1,
                      pageSize: 20,
                    })
                  );
                  dispatch(SearchCustomersAsync());
                }}
                variant="primary"
                // className="icon-button"
              >
                {content.searchButtonPlaceholder}
              </Button>
            </Col>

            <Col lg={0.5} className="px-md-1">
              <Button
                variant="danger"
                onClick={() => {
                  onClear();
                }}
              >
                Clear
              </Button>
            </Col>

            <Col lg={2}>
              <Row>
                <Col lg={4}>
                </Col>
                <Col lg={4}>
                </Col>
              </Row>
            </Col>
            <Col>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ButtonGroup>

                  <Button
                    aria-label={content.export}
                    variant="light"
                  >
                    <AiOutlineCloudDownload color={"white"} size={21}/>
                  </Button>
                  <Authorized
                    allowedRoles={[
                      Admin.label,
                      Sales.label,
                      CustomerService.label,
                      CustomerServiceManager.label,
                    ]}
                  >
                    <Button
                      aria-label={content.new}
                      onClick={() => setModalOn("create")}
                      variant="light"
                    >
                      {content.new}
                    </Button>
                  </Authorized>
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <BTable striped responsive bordered hover size="sm">
            <thead>
            <tr>
              <th>{"Id"}</th>
              <th>{content.nameHeader}</th>
              <th
                style={{ maxWidth: 121 }}>{"Maximum Of Delivery Attempts"}</th>

              <th>{"Confirmed"}</th>
              <th>{"Preferred Pickup Time"}</th>
              <Authorized
                allowedRoles={[
                  Admin.label,
                  CustomerServiceManager.label,
                  Sales.label,
                  CustomerService.label,
                ]}
              >
                <th>{content.actions}</th>
              </Authorized>
            </tr>
            </thead>

            <tbody>
            {pagedCustomers?.matches?.map(
              (row: CustomerModel, i: any): any => (
                <tr key={i}>
                  <td>{row.id}<br/><small
                    style={{ color: "grey" }}>{row.reference}</small></td>
                  <td>{row.name}</td>
                  <td style={{ maxWidth: 121 }}> {
                    row
                      .options?.maximumNumberOfAllowedDeliveryAttempts?.toString()
                  }</td>
                  <td>{row.isConfirmed?.toString()}</td>

                  <td>{row.preferredPickupTime ? convertUTCHour(row.preferredPickupTime, profile.currentTenant?.countryCode ?? "SA")?.toString() : "Immediate"}</td>

                  <Authorized
                    allowedRoles={[
                      Admin.label,
                      CustomerServiceManager.label,
                      Sales.label,
                      CustomerService.label,
                    ]}
                  >
                    <td>
                      <Button
                        onClick={() => {
                          setSelectedCustomerForEdit(row);
                          setModalOn("update");
                        }}
                        className="icon-button main-button"
                      >
                        Update
                      </Button>
                      {!row.isConfirmed && <Button
                        onClick={() => {
                          setSelectedCustomerForEdit(row);
                          setModalOn("confirm");
                        }}
                        className="icon-button main-button"
                      >
                        Confirm
                      </Button>}
                    </td>
                  </Authorized>
                </tr>
              )
            )}
            </tbody>
          </BTable>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pager
              currentPageNumber={searchCustomersCriteria?.pageNumber!}
              numberOfPages={Math.ceil(
                pagedCustomers?.total! / searchCustomersCriteria?.pageSize!
              )}
              onChange={(page: number) => {
                dispatch(
                  setCustomersCriteriaAsync({
                    pageNumber: page,
                  })
                );
                dispatch(SearchCustomersAsync());
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default React.memo(Screen);

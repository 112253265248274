import React, {FunctionComponent} from "react";
import MemberModel from "../../models/members/memberModel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface IProps {
    member: MemberModel
}

const AccountInfo: FunctionComponent<IProps> = ({member}) => {

    return <div className="px-5 mx-5 shadow rounded border" style={{minHeight: '212px'}}>
        <Row className={"d-flex py-3  flex-wrap"}>
            <Col md={12}>
                <Row>
                    <Col>
                        <p  className="h4">{"Id Number:"}<span
                            className={"px-3 font-weight-bold"}>{member.identificationNumber}</span></p>
                    </Col>
                    <Col>
                        <p className="h4">{"Name:"}<span className={"px-3 font-weight-bold"}>{member.name}</span></p>
                    </Col>
                    <Col>
                        <p className="h4">{"personalPhone:"}<span
                            className={"px-3 font-weight-bold"}>{member.phone}</span>
                        </p>
                    </Col>
                    <Col>
                        <p className="h4">{"JobRole:"}<span className={"px-3 font-weight-bold"}>{member.jobRole}</span>
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Row className={"justify-content-between pt-3 d-flex"}>
                    <Col md={12} className={"border rounded p-1 px-2 mb-2"}>
                        <p className="h4">{"Card Receipt Worth:"}<span
                            className={"px-3 font-weight-bold"}>{member.balance?.cardReceiptWorth.amount ?? 0}</span>
                        </p>
                    </Col>
                    <Col md={12} className={"border rounded p-1 px-2 mb-2"}>
                        <p className="h4">{"Cash Worth:"}<span
                            className={"px-3 font-weight-bold"}>{member.balance?.cash.amount ?? 0}</span></p>
                    </Col>
                    <Col md={12} className={"border rounded p-1 px-2"}>
                        <p className="h4">{"Shipments Worth:"}<span
                            className={"px-3 font-weight-bold"}>{member.balance?.shipmentsWorth.amount ?? 0}</span>
                        </p>
                    </Col>
                    <Col md={12} className={"border rounded p-1 px-2"}>
                        <p className="h4">{"Total Shipments Worth:"}<span
                            className={"px-3 font-weight-bold"}>{member.totalShipmentsWorth ?? 0}</span>
                        </p>
                    </Col>
                    <Col md={12} className={"border rounded p-1 px-2"}>
                        <p className="h4">{"Total Cash Worth:"}<span
                            className={"px-3 font-weight-bold"}>{member.totalCashWorth ?? 0}</span>
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}


export default React.memo(AccountInfo)
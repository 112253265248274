import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import MemberModel from "../../../models/members/memberModel";
import assignStationModel from "../../../models/members/assignStationModel";
import SelectInput from "../../components/SelectInput";
import { AssignStationAsync } from "../../../actions/membersActions";

interface IProps {
  member: MemberModel;
  visible: boolean;
  onHide: () => any;
}
const Component = (props: IProps) => {
  const stationsOptions = useSelector(
    (state: AppStateModel) => state.Meta?.stations
  );
  const memberContent = useSelector(
    (state: AppStateModel) => state.AppContent.members
  );

  const dispatch = useDispatch();

  const [member, setMember] = useState<assignStationModel>({
    AccountId: props.member.id!,
    StationId: props.member.stationId ?? "",
  });
  const [err, setErr] = useState<boolean>(false)
  const onSubmit = () => {
    if (member.StationId) {
      setErr(false)
      dispatch(AssignStationAsync(member, () => props.onHide()));
    }
    else
      setErr(true)
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {memberContent.assignStation}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <SelectInput
                value={member.StationId}
                options={stationsOptions!}
                placeholder={memberContent.assignStation}
                onChange={(val) => setMember({ ...member, StationId: val })}
              />
            </Col>
          </Form.Row>
          {err && <Form.Row>
            <Col>
              <div>
                <p className={"font-weight-bold text-danger"}>Choose Station Please</p>
              </div>
            </Col>
          </Form.Row>}
          <Form.Row>
            <Col></Col>

            <Col>
              <div
                style={{
                  paddingTop: 30,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={onSubmit} variant="light">
                  {memberContent.newMember.submit}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

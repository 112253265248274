import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import { useSelector } from "react-redux";
import ShipmentModel from "../../../models/shipmentModel";
import ShipmentList from "./shipmentsList";
import ReactSelect from "react-select";

import { CourierRunSheet } from "../../../api/data";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BTable from "react-bootstrap/Table";

interface IProps {
  visible: boolean;
  onHide: () => any;
  title: string;
}
const Component = (props: IProps) => {
  const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [courier, setCourier] = React.useState<any>(null);

  const couriers = useSelector((state: AppStateModel) => state.Meta?.couriers);
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.shipments
  );
  const [totalCod, setTotalCod] = React.useState(0);

  const [totalPieces, setTotalPieces] = React.useState(0);

  const ChangeCourier = async (courier: any) => {
    if (courier != null) {
      let shipmentsResponse = await CourierRunSheet(courier.value, false);

      if (shipmentsResponse.succeeded) {
        shipmentsResponse.data?.forEach((s: ShipmentModel) => {
          setTotalPieces(
            (i) => i + Number.parseInt(s.commodity?.numberOfPieces ?? 1, 0)
          );
          setTotalCod((i) => i + Number.parseInt(s.commodity?.cod ?? 0, 0));
        });
        setShipments(shipmentsResponse.data);
      }
    } else {
      setShipments([]);
    }
    setCourier(courier);
  };

  const modalContent = content.runsheetModal;

  const meta = useSelector((state: AppStateModel) => state.Meta);

  const resolveStatus = (status: any) => {
    let st = meta?.shipmentStatuses?.find((s: any) => s.value == status);
    return st?.label;
  };

  const onExport = async () => {
    setLoading(true);
    let res = await CourierRunSheet(courier?.value!, true); //todo
    if (res.succeeded) {
      window.open(res.data.location, "_blank", "noreferrer");
    }
    setLoading(true);
  };

  const rowColor = (row: ShipmentModel) => {
    const statusNumber = Number(row.status);
    switch (statusNumber) {
      case 35:
        return "#008000";
      case 40:
        return "#FF0000";
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
          {courier === null ? (
            <ReactSelect
              value={courier}
              onChange={(v) => ChangeCourier(v)}
              options={couriers}
            />
          ) : (
            <InputGroup className="mb-3">
              <Form.Control value={courier.label} disabled={true} />
              <InputGroup.Append>
                <Button
                  onClick={() => ChangeCourier(null)}
                  className="icon-button"
                >
                  Change
                </Button>
              </InputGroup.Append>
            </InputGroup>
          )}
        </div>

        {courier != null && (
          <>
            <BTable striped responsive bordered hover size="sm">
              <thead>
                <tr>
                  <td style={{ border: 0 }}>
                    {
                      <>
                        <span>{"Shipments : "}</span>
                        <strong>{shipments.length}</strong>
                      </>
                    }
                  </td>
                  <td style={{ border: 0 }}>
                    {
                      <>
                        {" "}
                        <span>{"Pieces: "}</span>
                        <strong>{totalPieces}</strong>
                      </>
                    }
                  </td>
                  <td style={{ border: 0 }}></td>
                  <td style={{ border: 0 }}></td>
                  <td style={{ border: 0 }}>Total COD</td>
                  <td style={{ border: 0 }}>
                    {" "}
                    <strong>{totalCod}</strong>
                  </td>
                </tr>
                <tr>
                  <th>{content.referencesHeader}</th>
                  <th>{content.commodityDetailsheader}</th>
                  <th>{content.fromHeader}</th>
                  <th>{content.toHeader}</th>
                  <th>{content.customerHeader}</th>
                  <th>{content.codHeader}</th>
                  <th>{"Number Of Pieces"}</th>

                  <th>{content.statusHeader}</th>
                </tr>
              </thead>

              {shipments && (
                <tbody>
                  {shipments?.map((row: ShipmentModel, i: any): any => (
                    <tr key={i} style={{ backgroundColor: rowColor(row) }}>
                      <td>
                        <Col>
                          <Row>
                            {content.model.number}: {row.number}
                          </Row>
                          <Row>
                            {content.model.uniqueReference}:{" "}
                            {row.uniqueReference}
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>
                            {content.model.commodity.weight}:{" "}
                            {row.commodity.weight}
                          </Row>
                          <Row>
                            {content.model.commodity.dimensions}:{" "}
                            {row.commodity.dimensions ?? "N/A"}
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>
                            {row.shipper.address?.city +
                              ", " +
                              row.shipper.address?.addressLine1 ?? ""}
                          </Row>
                          <Row>
                            {row.shipper.address?.addressLine2 ??
                              "" + ", " + row.shipper.address?.addressLine3 ??
                              ""}
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>
                            {row.consignee.address?.city +
                              ", " +
                              row.consignee.address?.addressLine1 ?? ""}
                          </Row>
                          <Row>
                            {row.consignee.address?.addressLine2 ??
                              "" + ", " + row.consignee.address?.addressLine3 ??
                              ""}
                          </Row>
                        </Col>
                      </td>
                      <td>{row.customerName}</td>
                      <td>{row.commodity.cod}</td>
                      <td>{row.commodity.numberOfPieces}</td>

                      <td>{resolveStatus(row.status)}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </BTable>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={shipments?.length === 0 || loading}
          onClick={onExport}
        >
          {modalContent.export}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Component;

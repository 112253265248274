import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import ICreateMemberModel from "../../../models/members/createMemberModel";
import Button from "react-bootstrap/Button";
import {CreateMemberAsync} from "../../../actions/membersActions";

interface IProps {
    visible: boolean;
    onHide: () => any;
    customerId: string
}

const Component = (props: IProps) => {
    const MemberContent = useSelector(
        (state: AppStateModel) => state.AppContent.members
    );
    const content = MemberContent.newMember;

    const [newMember, setNewMember] = useState<ICreateMemberModel>({});
    const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
    const dispatch = useDispatch();
    const onSubmit = () => {
        let validations: string[] = [];
        !newMember.name && newMember.name == null && validations.push("name");
        !newMember.phone && newMember.phone == null && validations.push("phone");
        !newMember.email && newMember.email == null && validations.push("email");
        !newMember.password &&
        newMember.password == null &&
        validations.push("password");

        if (validations.length > 0) {
            setValidationErrors(validations);
        } else {
            dispatch(CreateMemberAsync({
                ...newMember,
                customerId:props.customerId,
                jobRole:301
            }, () => props.onHide()));
        }
    };


    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {content.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Col>
                            <TextInput
                                value={newMember.name!}
                                onChange={(val) => setNewMember({...newMember, name: val})}
                                placeholder={content.name.placeHolder}
                                required={true}
                                invalidMessage={content.name.requiredMessage}
                                isInvalid={
                                    !!validationErrors.find((e) => e === "name")
                                }
                            />
                        </Col>

                        <Col>
                            <TextInput
                                value={newMember.email!}
                                onChange={(val) => setNewMember({...newMember, email: val})}
                                placeholder={content.email.placeHolder}
                                required={true}
                                invalidMessage={content.email.requiredMessage}
                                isInvalid={
                                    !!validationErrors.find((e) => e === "email")
                                }
                            />
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col>
                            <TextInput
                                value={newMember.phone!}
                                onChange={(val) => setNewMember({...newMember, phone: val})}
                                placeholder={content.mobile.placeHolder}
                                required={true}
                                invalidMessage={content.mobile.requiredMessage}
                                isInvalid={
                                    !!validationErrors.find((e) => e === "mobile")
                                }
                            />
                        </Col>

                        <Col>

                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <TextInput
                                value={newMember.password!}
                                onChange={(val) =>
                                    setNewMember({...newMember, password: val})
                                }
                                placeholder={content.password.placeHolder}
                                required={true}
                                invalidMessage={content.password.requiredMessage}
                                isInvalid={
                                    !!validationErrors.find((e) => e === "password")
                                }
                            />
                        </Col>

                        <Col>
                            <div
                                style={{
                                    paddingTop: 80,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                }}
                            >
                                <Button size="lg" onClick={onSubmit} variant="light">
                                    {content.submit}
                                </Button>
                            </div>
                        </Col>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Component;

import {ApiResponse} from "@simplify9/simplyapiclient";
import {PartyModel} from "../models/common/commonModels";
import {client} from "./index";


export const AssignForDelivery = async (ids: string[], toId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/AssignDeliver`, {
    ShipmentIds: ids,
    ToId: toId,
  });
  return res;
};

export const CompleteDeliveryCycle = async (ids: string[], toId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/CompleteDelivery`, {
    ShipmentIds: ids,
    ToId: toId,
  });
  return res;
};
export const AssignForTransfer = async (ids: string[], toId: string, stationId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/AssignTransfer`, {
    ShipmentIds: ids,
    ToId: toId,
    StationId: stationId
  });
  return res;
};
export const StoreShipment = async (id: string, locationId: string | null): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/Store`, {
    shipmentId: id,
    locationId: locationId,

  });
  return res;
};
export const StoreBulkShipment = async (ids: string[], locationId: string | null): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/Store`, {
    shipmentIds: ids,
    locationId: locationId,

  });
  return res;
};

export const ReceiveStore = async (ids: string[], locationId: string | null, stationId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/ReceiveStore`, {
    shipmentIds: ids,
    locationId: locationId,
    stationId
  });
  return res;
};

export const HoldShipment = async (ids: string[], reason: string, location: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/hold`, {
    shipmentIds: ids,
    reason,
    LocationId: location

  });
  return res;
};

export const AssignForReturn = async (ids: string[], toId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/AssignReturn`, {
    ShipmentIds: ids,
    ToId: toId,
  });
  return res;
};
export const AssignForPickup = async (ids: string[], toId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/AssignPickup`, {
    ShipmentIds: ids,
    ToId: toId,
  });
  return res;
};


export const Receive = async (ids: string[], stationId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/receive`, {
    ShipmentIds: ids,
    StationId: stationId
  });
  return res;
};

export const Store = async (ids: string[], locationId: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/store`, {
    ShipmentIds: ids,
    LocationId: locationId
  });
  return res;
};

export const Update = async (id: string, description: string, consignee: PartyModel): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/update`, {
    ShipmentId: id,
    Description: description,
    Consignee: consignee
  });
  return res;
};

export const Hold = async (id: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/hold`, {
    ShipmentId: id
  });
  return res;
};


export const Lose = async (ids: string[], reason: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/lose`, {
    ShipmentIds: ids,
    reason
  });
  return res;
};

export const Own = async (id: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/own`, {
    ShipmentId: id
  });
  return res;
};

export const Release = async (id: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/release`, {
    ShipmentId: id
  });
  return res;
};

export const Cancel = async (id: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/cancel`, {
    ShipmentId: id
  });
  return res;
};

export const ForcePickup = async (ids: string): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(`operations/forcePickup`, {
    ShipmentIds: ids,
  });
  return res;
};

export const ExternalTransitToDeliveredFlow = async (numbers: string[]): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(
      `Operations/ExternalTransitToDeliveredFlow`,
      { numbers }
  );
  return res;
};


export const AssignToPartner = async (numbers: string[], assignTo: number): Promise<ApiResponse> => {
  const res: ApiResponse = await client.SimplyPostAsync(
      `Operations/AssignForPartner`,
      { ShipmentIds: numbers, toId: assignTo }
  );
  return res;
}



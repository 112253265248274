import React, {useState} from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import {useDispatch} from "react-redux";
//@ts-ignore
import SuccessBeep from "../../../res/beepsuccess.mp3";
//@ts-ignore
import FailBee from "../../../res/beepfail.mp3";
import {ExternalTransitToDeliveredFlowAsync} from "../../../actions/shipmentsActions";

interface IProps {
    visible: boolean;

    onDone: () => any;


}

const Component = (props: IProps) => {
    const [shipmentNumber, setShipmentNumbers] = useState('')
    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(ExternalTransitToDeliveredFlowAsync(shipmentNumber?.split("\n")?.filter(i=> Boolean(i))?.map(i=> i.trim()), () => props.onDone()))
    }
    return (
        <Modal
            show={props.visible}
            onHide={() => props.onDone()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Third Party Shipments
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Shipments</label>
                        <textarea onChange={(e) => setShipmentNumbers(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows={5}></textarea>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={shipmentNumber === ''} onClick={onSubmit}>
                    Submit
                </Button>
            </Modal.Footer>

        </Modal>
    );
};

export default Component;

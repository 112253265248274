import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import BTable from "react-bootstrap/Table";
import Pager from "../components/Pager";
import Card from "react-bootstrap/Card";
import AppStateModel from "../../models/appStateModel";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Row} from "react-bootstrap";
import {BsSearch} from "react-icons/bs";
import ToLocalDateTime from "../../actions/date";
import {SearchPartnersAsync, setPartnerCriteria} from "../../actions/partnerActions";
import {PartnersResponseModel} from "../../models/partner";
import {GenerateCustomerApiKey} from "../../api/data";
import CreatePartnerModal from "./createPartnerModal";

const initialState = {apiKey: "", accountId: ""};
const Partners = () => {
    const pagedPartner = useSelector(
        (state: AppStateModel) => state.PagedPartner
    );
    const searchPartnerCriteria = useSelector(
        (state: AppStateModel) => state.SearchPartnerCriteria
    );
    const countryCode = useSelector(
        (state: AppStateModel) => state.Profile.currentTenant?.countryCode
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(SearchPartnersAsync());
    }, [dispatch]);
    const [isLoading, setIsLoading] = useState(false);
    const [apiKey, setApiKey] = useState(initialState);
    const [showModal, setShowModal] = useState(false);
    const onClear = () => {
        dispatch(setPartnerCriteria({...searchPartnerCriteria, email: '', name: ''}))
        dispatch(
            SearchPartnersAsync()
        )
    }

    const generateKey = async (id: string) => {
        setIsLoading(true);
        let res = await GenerateCustomerApiKey(id);
        if (res.succeeded) {
            setApiKey({apiKey: res.data.key, accountId: id});
            setTimeout(() => {
                setApiKey(initialState);
            }, 10000);
        } else {
            setApiKey(initialState);
        }
        setIsLoading(false)
    };

    return (
        <div style={{padding: "1vw"}}>
            {showModal && <CreatePartnerModal onHide={() => setShowModal(false)}/>}
            <Card>
                <Card.Header>
                    <Row>
                        <Col lg={2} className="px-md-4">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    style={{height: 35}}
                                    // size="sm"
                                    placeholder={"Email"}
                                    value={searchPartnerCriteria?.email ?? ""}
                                    onChange={(e) => {
                                        dispatch(setPartnerCriteria({...searchPartnerCriteria, email: e.target.value}))
                                    }}
                                />
                                <InputGroup.Append style={{zIndex: 0}}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                SearchPartnersAsync()
                                            )
                                        }
                                        className="icon-button"
                                    >
                                        <BsSearch name={"search"} color={"white"} size={21}/>
                                    </Button>

                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col lg={1} className="px-md-2 ">
                            <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
                                <Button
                                    variant="danger"
                                    onClick={() => {
                                        onClear()
                                    }}
                                >
                                    Clear
                                </Button>
                            </div>
                        </Col>
                        <Col lg={1} className="px-md-2 ">
                            <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}}>
                                <Button
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                >
                                    Create
                                </Button>
                            </div>
                        </Col>



                    </Row>
                </Card.Header>
                <Card.Body>
                    <BTable striped responsive bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Created On</th>
                            <th>Account Id</th>
                        </tr>
                        </thead>

                        <tbody>
                        {pagedPartner?.matches?.map((row: PartnersResponseModel, i: any): any => (
                            <tr key={i}>
                                <td>{row.id}</td>
                                <td>{row.name}</td>
                                <td>{row.email}</td>
                                <td>{ToLocalDateTime(row.createdOn, countryCode!)}</td>
                                <td>{row.accountId}</td>
                                <td>{row.type}</td>
                                <td>
                                    <Button
                                        disabled={isLoading}
                                        onClick={() => generateKey(row.accountId!)}
                                    >
                                        Generate Key
                                    </Button>
                                    {"  "}
                                    {(apiKey.accountId === row.accountId && apiKey.apiKey != "") ? apiKey.apiKey : ""}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </BTable>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Pager
                            currentPageNumber={searchPartnerCriteria?.pageNumber!}
                            numberOfPages={Math.ceil(
                                pagedPartner?.total! / searchPartnerCriteria?.pageSize!
                            )}
                            onChange={(page: number) => {
                                dispatch(
                                    setPartnerCriteria({
                                        pageNumber: page,
                                    })
                                );
                                dispatch(SearchPartnersAsync());
                            }}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Partners;

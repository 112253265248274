import AppContentModel from "../models/appContentModel";

const Content: AppContentModel = {
  stations:{
    namePlaceholder : "الاسم",
    cityPlaceholder: "المدينة",
    countryPlaceholder:"الدولة",
    nameRequiredMessage:"الاسم مطلوب",
    cityRequiredMessage : "المدينة مطلوبة",
    countryRequiredMessage : "الدولة مطلوبة"
  },
  cityTier:{
    descriptionPlaceholder: 'الوصف',
    namePlaceHolder: 'الاسم',
    nameRequiredMessage: "الاسم مطلوب",
    descriptionRequiredMessage: "الوصف مطلوب",

  },

  navigation: {
    companies: "الشركات",
    shipments: "الشحنات",
    couriers: "الساعيين",
    customers: "Customers",
    members: "Members",
    stations : "Stations",
    switchLanguage: "English",
    logout: "خروج",
    arabic: "العربية",
    draft:'Draft',
    dashboard:"Dashboard",
    english: "English",
    reports:"Reports",
    cityTiers:'مستويات المدينة'
  },
  login: {
    title: "الدخول",
    username: "اسم المستخدم",
    usernamePlaceholder: "ادخل اسم المستخدم",
    password: "كلمة المرور",
    passwordPlaceholder: "ادخل كلمة المرورر",
    forgot1: "نسيت",
    forgot2: "كلمة المرور",
    submit: "ادخل",
  },
  shipments: {
    completeDeliveryCycle: "Complete Delivery",
    markAsLost : "Mark As Lost",
    transferButton:"Transfer",
    referencesHeader: "References",
    customerHeader: "Customer",
    forcePickupButton: 'Force Pickup',
    fromHeader: "Shipper",
    toHeader: "Consignee",
    statusHeader: "Status",
    courierHeader: "Courier",
    codHeader: "COD",
    courierRunSheetButton: "Courier RunSheet",
    creationDateHeader: "Created On",
    commodityDetailsheader: "Commodity",
    actions: "Actions",
    add: "Add Shipments",
    new: "New Shipment",
    importWithoutStore: "Import Excel Without Store",
    import: "Import Excel",
    export: "Export to Excel",
    search: "Search",
    runsheetModal: {
      export: "Export",
      title: "Courier Run Sheet",
    },
    consigneeNameSearch: "Consignee Name",
    consigneeNumberSearch: "Consignee Number",
    clear: "Clear",
    receiveButton: "Receive",
    assignDriverButton: "Assign To Driver",
    assignCourierButton: "Assign To Courier",
    receiveReturnedButton: "Receive Returned",
    returnToCustomerButton: "Return to customer",
    collectCodButton: "Collect COD",
    consigneeCity: "Consignee City",
    total: "Total",
    deliveryDateHeader: "Delivery Date",
    updateShipment: {
      title: "Update Shipment",
      submit: "Save",
    },
    codCollect: {
      title: "Collect COD",
      submit: "Collect",
      change: "Change",
    },
    model: {
      stationId:"المحطة",
      id: "رقم التعريف",
      number: "رقم الشحنة",
      uniqueReference: "Reference",
      status: "الحالة",
      customerId: "الشركة",
      customerName: "الشركة",
      courierId: "Courier",
      paymentMethod:"Payment Method",
      commodity: {
        numberOfPieces:"عدد القطع",
        cod: "COD",
        description: "الشرح",
        dimensions: "الابعاد",
        reference: "Commodity Reference",
        weight: "الوزن",
      },
      consignee: {
        address: {
          country: "To Country",
          city: "To City",
          state: "To State",
          reference: "To Reference",
          postCode: "To Post Code",
          addressLine1: "To Line 1",
          addressLine2: "To Line 2",
          addressLine3: "To Line 3",
        },
        contact: {
          name: "To Name",
          email: "To Email",
          phone: "To Phone",
          secondPhone: "To Second Phone",
        },
        reference: "To Reference",
      },
      shipper: {
        address: {
          country: "From Country",
          city: "From City",
          state: "From State",
          reference: "From Reference",
          postCode: "From Post Code",
          addressLine1: "From Line 1",
          addressLine2: "From Line 2",
          addressLine3: "From Line 3",
        },
        contact: {
          name: "From Name",
          email: "From Email",
          phone: "From Phone",
          secondPhone: "From Second Phone",
        },
        reference: "From Reference",
      },
    },
    shipmentTrackModel: {
      category: 'Category',
      deliveryService: 'Service Type',
      stationId:"Station",
      title: "Shipment Track",
      uniqueReferenceLabel: "Unique Reference",
      customerLabel: "Customer",
      shipmentNumberLabel: "Number",
      trace: {
        index: "#",
        activity: "Activity",
        by: "By",
        additionalData: "Additional Data",
        comment: "Comment",
        date: "Date",
      },
      consigneeTitle: "Consignee",
      shipperTitle: "Shipper",
      commodityTitle: "Commodity",
      commodity: {
        numberOfPieces: "Number of pieces",
        cod: "COD",
        description: "Description",
        dimensions: "Dimensions",
        weight: "Weight",
      },
      address: {
        country: "Country",
        city: "City",
        postCode: "Post Code",
        state: "State",
        addressLine1: "Address Line 1",
        addressLine2: "Address Line 2",
        addressLine3: "Address Line 3",
      },
      contact: {
        email: "Email",
        name: "Name",
        phone: "Phone",
        secondPhone: "Phone",
      },
    },
    newShipment: {
      title: "New Shipment",
      fromStore: "From Store",
      toStore: "To Store",
      numberOfPiecesField: { placeHolder: "عدد القطع" , requiredMessage:"عدد القطع مطلوب" ,minimum:"1 الحد الادنى)"},
      customerField: {
        placeHolder: "Customer",
        requiredMessage: "",
      },
      customerStoreField: {
        placeHolder: "Store",
        requiredMessage: "",
      },
      commodityDescriptionField: {
        placeHolder: "Description",
        requiredMessage: "",
      },
      uniqueReferenceField: {
        placeHolder: "Unique Reference",
        requiredMessage: "Unique reference is required",
      },
      cityField: {
        placeHolder: "City",
        requiredMessage: "",
      },
      addressLine1Field: {
        placeHolder: "Address Line 1",
        requiredMessage: "Address Line 1 is required",
      },
      addressLine2Field: {
        placeHolder: "Address Line 2",
        requiredMessage: "Address Line 2 is required",
      },
      addressLine3Field: {
        placeHolder: "Address Line 3",
        requiredMessage: "Address Line 3 is required",
      },
      codField: {
        placeHolder: "COD",
        requiredMessage: "",
      },
      weightField: {
        placeHolder: "Weight",
        requiredMessage: "Weight is required",
      },
      dimensionsField: {
        placeHolder: "Dimensions",
        requiredMessage: "",
      },
      nameField: {
        placeHolder: "Name",
        requiredMessage: " Name must not be empty",
      },
      emailField: {
        placeHolder: "Email",
        requiredMessage: "Email must not be empty",
      },
      phoneField: {
        placeHolder: "Phone",
        requiredMessage: "Phone Must not be empty",
      },
      secondPhoneField: {
        placeHolder: "Second Phone",
        requiredMessage: "",
      },
      commoditySectionTitle: "Commodity",
      shipperSectionTitle: "Shipper",
      consigneeSectionTitle: "Consignee",
      submit: "Submit",
      itemName: "اسم الشحنة",
      consigneeAdressTitle : "Consignee Address"
    },
    receive: {
      title: "Receive Shipments",
      scanPlaceholder: "Scan Shipment",
      addButton: "Add",
      clearAllButton: "Clear All",
      clearButton: "Clear",
      receiveButton: "Receive",
    },
    selectCourier: {
      title: "Assign A Courier",
      button: "Assign",
      placeholder: "Select a courier",
    },
    returnToCustomer: {
      title: "Return to customer",
      button: "Assign",
      placeholder: "Select a driver",
    },
  },
  members: {
    Station:"Station",
    assignStation: "Assign Station",
    changeRole : "Change Role",
    nameContainsPlaceholder: "Name",
    mobileContainsPlaceholder: "Mobile",
    searchButtonPlaceholder: "Search",
    actionsHeader: "Actions",
    emailHeader: "Email",
    mobileHeader: "Mobile",
    nameHeader: "Name",
    newButton: "Add Member",
    update: "Update",
    jobRoleHeader: "Role",
    newMember: {
      title: "Add new Member",
      submit: "Save",
      email: {
        placeHolder: "Email",
        requiredMessage: "Email is required",
      },
      mobile: {
        placeHolder: "Mobile",
        requiredMessage: "Mobile is required",
      },
      name: {
        placeHolder: "Name",
        requiredMessage: "Name is required",
      },
      password: {
        placeHolder: "Password",
        requiredMessage: "Password is required",
      },
      jobRole: {
        placeHolder: "Role",
        requiredMessage: "Role is required",
      },
    },
  },
  customers: {
    addStoreContent: {
      storeName:{
        placeHolder: "Store name",
        requiredMessage: "Store name Must not be empty",

    },
      passwordField: {
        placeHolder: "Password",
        requiredMessage: "Password is required",
      },
      secondePhoneField: {
        placeHolder: "Second Phone",
        requiredMessage: "Second Phone Must not be empty",
      },
      phoneField: {
        placeHolder: "Phone",
        requiredMessage: "Phone Must not be empty",
      },
      storeSectionTitle: "Add store",

      titleField: {
        placeHolder: "Title",
        requiredMessage: "Title Must not be empty",
      },

      submit: "Submit",
      storeEmailField: {
        placeHolder: "Email",
        requiredMessage: "Email is a required field",
      },

      cityField: {
        placeHolder: "City",
        requiredMessage: "City is required",
      },

      addressLine1Field: {
        placeHolder: "Address Line 1",
        requiredMessage: "Address Line 1 is required",
      },
      addressLine2Field: {
        placeHolder: "Address Line 2",
        requiredMessage: "Address Line 2 is required",
      },

      addressLine3Field: {
        placeHolder: "Address Line 3",
        requiredMessage: "Address Line 3 is required",
      },
    },
    actions: "Actions",
    add: "Add Customer",
    new: "New Customer",
    export: "Export to Excel",
    nameContains: "Name",
    emailContains: "Email",
    searchButtonPlaceholder: "Search",
    clear: "Clear",
    nameHeader: "Name",
    newCustomer: {
      title: "Add a new Customer",
      submit: "Submit",
      passwordSection: "Customer Credentials",
      password: {
        placeHolder: "Password",
        requiredMessage: "Password is required",
      },
      name: {
        placeHolder: "Arabic title",
        requiredMessage: "Arabic title is a required field",
      },
      email: {
        placeHolder: "Email",
        requiredMessage: "Email is a required field",
      },
    },
    editStore:{
      title: "تعديل متجر",
      name: "اسم المتجر",
      submit: "تأكيد"
    },
  },
  reports:{

  },
  cities:{
    idPlaceholder:"ID",
    namePlaceholder:"Name",
    stationPlaceholder:"Station",
    isServicedPlaceholder:"Is Serviced",
    tierName: 'اسم الطبقة',
    tierUpdate: 'تحديث'

  }
};

export default Content;

import React from "react";
import Form from "react-bootstrap/Form";
import Select from 'react-select';


interface IProps {
	value?:string | number
	options: any[];
	isInvalid?: boolean;
	invalidMessage?: string;
	placeholder?: string;
	onChange: (id: any) => any;
	disabled?:boolean
}

const Component = (props: IProps) => {

    const onSelect = (selectedOptions:any) => {
        if (Array.isArray(selectedOptions)) {
					throw new Error(
						"Unexpected type passed to ReactSelect onChange handler"
					);
        }

        props.onChange(selectedOptions?.value);
    }
	return (
		<Form.Group>
			<Select
				value={props.options?.find(o => o.value == props.value)||""}
                onChange={onSelect}
                options={props.options}
                isClearable
                placeholder={props.placeholder}
                isDisabled={props.disabled}
            />
			<Form.Control.Feedback type="invalid">
				{props.invalidMessage}
			</Form.Control.Feedback>
		</Form.Group>
	);
};

export default React.memo(Component);

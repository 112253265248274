import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap"
import Select from "react-select";
import LookupModel from "../../models/lookupModel";
import {SearchCitiesAsync} from "../../actions/citiesActions";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel, {CreateCityModel} from "../../models/appStateModel";
import {CityTiers, CreateCity} from "../../api/data";
import TextInput from "../components/TextInput";
import {CityTiersAsync, setCityTier} from "../../actions/cityTiersAction";
import {CityTiersModel} from "../../models/cityTiersModel ";
import pagedModel from "../../models/pagedModel";

interface IProps {
    onHide: () => void
    cityTiers:pagedModel<CityTiersModel>
}

const CreateModal = (props: IProps) => {
    const customControlStyles = (base: any) => ({
        ...base,
        height: 21!,
    });
    const [selectedService, setSelectedService] = React.useState<LookupModel | undefined>();
    const [selectedStation, setSelectedStation] = React.useState<LookupModel | undefined>();
    const [selectedTier, setSelectedTier] = React.useState<{label:string,value:number| string}>();
    const [create, setCreate] = useState<CreateCityModel>({name:'',code:'',tierId:0,stationId:0,isServiced:false})
    const stations = useSelector((state: AppStateModel) => state.Meta?.stations);

    const isServiceResolver = (value?: string) => {
        if (value == "yes") {
            setSelectedService({value: "yes", label: "Yes"})
            setCreate({...create, isServiced: true})
        } else if (value == "no") {
            setSelectedService({value: "no", label: "No"})
            setCreate({...create, isServiced: false})
        }
    }
    const dispatch=useDispatch()

    const onSubmit = async () => {
        let res;
        if(create){
     res =await CreateCity(create)
        if(res.succeeded)
        {
            dispatch(SearchCitiesAsync());
            props.onHide()
        }
    }
    }


    return (
        <Modal
            show={true}
            onHide={() => props.onHide()}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Create City
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextInput
                value={create.name}
                placeholder={'Name'}
                onChange={(e)=>setCreate({...create,name:e})}
                />
                <TextInput
                    value={create?.code}
                    placeholder={'Code'}
                    onChange={(e)=>setCreate({...create,code:e})}
                />
                <Select
                    value={selectedService ?? {label: "Is Serviced ?", value: "Is Serviced?"}}
                    styles={{control: customControlStyles}}
                    options={[{label: "Yes", value: "yes"}, {label: "No", value: "no"}]}
                    isClearable
                    placeholder={"Is Serviced ?"}
                    onChange={(selectedOption) => {
                        if (Array.isArray(selectedOption)) {
                            throw new Error(
                                "Unexpected type passed to ReactSelect onChange handler"
                            );
                        }

                        isServiceResolver(selectedOption?.value)
                    }}
                />
                <div style={{height: "1vh"}}/>
                <Select
                    value={selectedTier}
                    styles={{control: customControlStyles}}
                    options={props.cityTiers?.matches?.map(tier => ({label: tier.name, value: tier.id as any}))}
                    isClearable
                    placeholder={"Tier"}
                    onChange={(selectedOption) => {
                        if (Array.isArray(selectedOption)) {
                            throw new Error(
                                "Unexpected type passed to ReactSelect onChange handler"
                            );
                        }
                        setCreate({...create,tierId:Number(selectedOption?.value)})
                        setSelectedTier({
                            label: selectedOption?.label!,
                            value: selectedOption?.value!,
                        });

                    }}
                />
                <div style={{height: "1vh"}}/>
                <Select
                    key={`selectedStation__${create?.stationId}`}
                    value={selectedStation}
                    styles={{control: customControlStyles}}
                    options={stations}
                    isClearable
                    placeholder={"Stations"}
                    onChange={(selectedOption) => {
                        if (Array.isArray(selectedOption)) {
                            throw new Error(
                                "Unexpected type passed to ReactSelect onChange handler"
                            );
                        }
                        setCreate({...create,stationId:Number(selectedOption?.value)})
                        setSelectedStation({
                            label: selectedOption?.label!,
                            value: selectedOption?.value!,
                        });

                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={()=>onSubmit()}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateModal

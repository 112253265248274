import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import IUpdateMemberModel from "../../../models/members/updateMemberModel";
import Button from "react-bootstrap/Button";
import {UpdateMemberAsync} from "../../../actions/membersActions";
import MemberModel from "../../../models/members/memberModel";

interface IProps {
    member: MemberModel;
    visible: boolean;
    onHide: () => any;
}

const Component = (props: IProps) => {
    const memberContent = useSelector(
        (state: AppStateModel) => state.AppContent.members
    );
    const content = memberContent.newMember;
    const Meta = useSelector((state: AppStateModel) => state.Meta);

    const [member, setMember] = useState<IUpdateMemberModel>({
        id: props.member.id!,
        name: props.member.name,
        email: props.member.email,
        identificationNumber: props.member.identificationNumber,
        personalPhone: props.member.personalPhone,
        jobRole: Meta?.jobRoles?.find((x) => x.label == props.member.jobRole)
            ?.value!,
    });

    const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
    const dispatch = useDispatch();
    const onSubmit = () => {
        let validations: string[] = [];

        !member.name && member.name == null && validations.push("name");

        if (validations.length > 0) {
            setValidationErrors(validations);
        } else {
            dispatch(UpdateMemberAsync(member, () => props.onHide()));
        }
    };


    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {memberContent.update}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Col>
                            <Form.Label>Email</Form.Label>
                            <TextInput
                                value={member.email!}
                                onChange={(val) => setMember({...member, email: val})}
                                placeholder={"Email"}
                                required={true}
                                invalidMessage={"Must enter email"}
                                isInvalid={
                                    !!validationErrors.find((e) => e == "email")
                                }
                            />
                        </Col>
                        <Col>
                            <Form.Label>New name : </Form.Label>
                            <TextInput
                                value={member.name!}
                                onChange={(val) => setMember({...member, name: val})}
                                placeholder={content.name.placeHolder}
                                required={true}
                                invalidMessage={content.name.requiredMessage}
                                isInvalid={
                                    !!validationErrors.find((e) => e == "name")
                                }
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Label>Id Number: </Form.Label>
                            <TextInput
                                value={member.identificationNumber!}
                                onChange={(val) => setMember({...member, identificationNumber: val})}
                                placeholder={"Id Number"}

                            />
                        </Col>
                    </Form.Row> <Form.Row>
                    <Col>
                        <Form.Label>Personal Phone: </Form.Label>
                        <TextInput
                            value={member.personalPhone!}
                            onChange={(val) => setMember({...member, personalPhone: val})}
                            placeholder={"Persnaol Phone"}

                        />
                    </Col>
                </Form.Row>
                    <Form.Row>
                        <Col></Col>

                        <Col>
                            <div
                                style={{
                                    paddingTop: 30,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                }}
                            >
                                <Button size="lg" onClick={onSubmit} variant="light">
                                    {content.submit}
                                </Button>
                            </div>
                        </Col>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Component;

import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import AppStateModel from '../../models/appStateModel';
import SelectInput from "./SelectInput";
import Form from "react-bootstrap/Form";

interface IProps {
    value: string
    isInvalid?: boolean;
    invalidMessage?: string;
    placeholder?: string;
    onChange: (title: any) => any;
    disabled?: boolean;
}

const AMSCitiesSelect = (props: IProps) => {
    const cities = useSelector((state: AppStateModel) => state.AMS?.Cities)
    if (!cities) return <></>
    return (
        <Fragment>
            <Form.Label>{props.placeholder}</Form.Label>
            <SelectInput
                key={`citykeyx${props.value}`}
                value={props.value ? cities.find((o) => o.value == props.value)?.value : undefined}
                isInvalid={props.isInvalid}
                invalidMessage={props.invalidMessage}
                options={cities}
                onChange={(id: string) =>
                    props.onChange(id)
                }
                placeholder={props.placeholder}
                disabled={props.disabled}
            />
        </Fragment>

    );
};

export default React.memo(AMSCitiesSelect);

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import Button from "react-bootstrap/Button";
import { StationsModel } from "../../../models/StationsModel";
import { CreateStationshAsync } from "../../../actions/stationsActions";
import CitiesSelect from "../../components/CitiesSelect";

interface IProps {
  visible: boolean;
  onHide: () => any;
  submitButton: string;
  title: string;
}
const Component = (props: IProps) => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.stations
  );

  const [newStation, setNewStation] = useState<StationsModel>({});
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const onSubmit = () => {
    let validations: string[] = [];

    !newStation.name && newStation.name == null && validations.push("name");
    !newStation.city && newStation.city == null && validations.push("city");
    if (validations.length > 0) {
      setValidationErrors(validations);
    } else {
      dispatch(CreateStationshAsync(newStation, () => props.onHide()));
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <TextInput
                value={newStation.name!}
                onChange={(val) => setNewStation({ ...newStation, name: val })}
                placeholder={content.namePlaceholder}
                required={true}
                invalidMessage={content.nameRequiredMessage}
                isInvalid={
                  validationErrors.find((e) => e === "name") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <CitiesSelect
                value={newStation.city!}
                onChange={(val) => setNewStation({ ...newStation, city: val })}
                placeholder={content.cityPlaceholder}
                invalidMessage={content.cityRequiredMessage}
                isInvalid={
                  validationErrors.find((e) => e == "city") ? true : false
                }
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={onSubmit} variant="light">
                  {props.submitButton}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

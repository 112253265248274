import React, {useState} from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {AssignToPartnerAsync} from "../../../actions/shipmentsActions";
import AppStateModel from "../../../models/appStateModel";
import ReactSelect from "react-select";

interface IProps {
    visible: boolean;
    onDone: () => any;
}

const Component = (props: IProps) => {
    const [shipmentNumber, setShipmentNumbers] = useState('')
    const dispatch = useDispatch();
    const [partner, setPartner] = React.useState<any>(null);
    const partners = useSelector((state: AppStateModel) => state.Meta?.partners);
    const onSubmit = () => {
        dispatch(AssignToPartnerAsync(shipmentNumber?.split("\n")?.filter(i=> Boolean(i))?.map(i=> i.trim()), partner?.value,() => props.onDone()))
    }

    return (
        <Modal
            show={props.visible}
            onHide={() => props.onDone()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Assign To Partner
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Shipments</label>
                        <textarea onChange={(e) => setShipmentNumbers(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows={5}></textarea>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '20%' }}>
                    <ReactSelect
                        value={partner}
                        onChange={(v) => setPartner(v)}
                        options={partners}
                    />
                </div>
                <Button disabled={shipmentNumber === ''} onClick={onSubmit}>
                    Submit
                </Button>
            </Modal.Footer>

        </Modal>
    );
};

export default Component;


import React from "react";
import BTable from "react-bootstrap/Table";
import {CityTiersModel} from "../../../models/cityTiersModel ";


interface IProps {
  data: CityTiersModel [];

}

const CityTiersTable = (props: IProps) => {

  return (<BTable striped bordered hover size="xs">
    <thead>

    <tr>
      <th>{"Id"}</th>
      <th>{"Name"}</th>
      <th>{"Description"}</th>
    </tr>
    </thead>

    <tbody className={"px-2"}>

    {props?.data?.map((row:CityTiersModel, key:number) => (
      <tr key={key}>
        <td>
          {row?.id}
        </td>

        <td>
          {row?.name}
        </td>
        <td>
          {row?.description}
        </td>

      </tr>))}
    </tbody>
  </BTable>)
}
export default CityTiersTable;

import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";

interface IProps {
    visible: boolean;
    onHide: () => any;
    onDone: (ids: any, courierId: any) => any;
}

const CompleteDeliveryModal = (props: IProps) => {
    const [shipmentsNumbers, setShipmentsNumbers] = React.useState<string>('');
    const [courier, setCourier] = React.useState<any>(null);
    const couriers = useSelector((state: AppStateModel) => state.Meta?.couriers);
    const content = useSelector(
        (state: AppStateModel) => state.AppContent.shipments
    );

    return (
        <Modal
            show={props.visible}
            onHide={() => props.onHide()}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {content.completeDeliveryCycle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Shipments</label>
                    <textarea onChange={(e) => setShipmentsNumbers(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows={5}></textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '20%' }}>
                    <ReactSelect
                        value={courier}
                        onChange={(v) => setCourier(v)}
                        options={couriers}

                    />
                </div>
                <Button
                    disabled={
                        !shipmentsNumbers?.trim() || !courier || courier == null
                    }
                    onClick={() => props.onDone(shipmentsNumbers?.split("\n")?.filter(i => Boolean(i))?.map(i => i.trim()) ?? [], courier!.value!)}
                >
                    {content.completeDeliveryCycle}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CompleteDeliveryModal;
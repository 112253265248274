import * as React from "react";
import Dashboard from '../pages/dashboard';

import {Switch} from "react-router-dom";
import Login from '../pages/auth/login';
import Shipments from '../pages/shipments/shipments';
import LoggedInRoute from "./loggedInRoute";
import LoggedOutRoute from "./loggedOutRoute";
import Members from '../pages/members/members';
import Customers from "../pages/customers/customers";
import Draft from "../pages/draft/drafts";
import Stations from "../pages/stations/stations";
import Reports from "../pages/reports/reports";
import SystemActions from "../pages/Actions";
import Cities from "../pages/cities/search";
import CodTab from "../pages/Cod/CodTab";
import CodReportTab from "../pages/codReport/CodReportTab";
import PickupDispatchScreen from "../pages/pickupDisptach";
import Containers from "../pages/Containers";
import RoutesScreen from "../pages/Routes";
import Ams from "../pages/ams";
import CityTiers from "../pages/CityTiers/cityTiers";
import Otp from "../pages/otp";
import Notifications from "../pages/notifications";
import Partners from "../pages/partners";

const Pages = () => {

    return (
        <Switch>
            <LoggedOutRoute path="/login" exact={true} component={Login}/>
            <LoggedInRoute path="/" exact={true} component={Shipments}/>
            <LoggedInRoute path="/shipments" component={Shipments}/>
            <LoggedInRoute path="/pickup-Dispatch"
                           component={PickupDispatchScreen}/>
            <LoggedInRoute path="/containers" component={Containers}/>
            <LoggedInRoute path="/customers" exact={true} component={Customers}/>
            <LoggedInRoute path="/members" exact={true} component={Members}/>
            <LoggedInRoute path="/draft" exact={true} component={Draft}/>
            <LoggedInRoute path="/stations" exact={true} component={Stations}/>
            <LoggedInRoute path="/dashboard" exact={true} component={Dashboard}/>
            <LoggedInRoute path="/reports" exact={true} component={Reports}/>
            <LoggedInRoute path="/systemaction" exact={true}
                           component={SystemActions}/>
            <LoggedInRoute path="/cities" exact={true} component={Cities}/>
            <LoggedInRoute path="/cod" exact={true} component={CodTab}/>
            <LoggedInRoute path="/codReport" exact={true} component={CodReportTab}/>
            <LoggedInRoute path={"/routes"} exact={true} component={RoutesScreen}/>
            <LoggedInRoute path={"/ams"} exact={true} component={Ams}/>
            <LoggedInRoute path={"/cityTiers"} exact={true} component={CityTiers}/>
            <LoggedInRoute path={"/otp"} exact={true} component={Otp}/>
            <LoggedInRoute path={"/notifications"} exact={true} component={Notifications}/>
            <LoggedInRoute path={"/partners"} exact={true} component={Partners}/>
        </Switch>
    );
};
export default Pages;

import React, {useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";

//Smart Components
import UpdateModal from "./components/update";
import ChangePasswordModal from "./components/changePassword";
import CreateModal from "./components/create";
import AssignStationModal from "./components/assignStation";
import ChangeRoleModal from "../members/components/changeRole";

//Components
import BTable from "react-bootstrap/Table";
import Pager from "../components/Pager";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import TextInput from "../components/TextInput";
//Models
import AppStateModel from "../../models/appStateModel";
import MemberModel from "../../models/members/memberModel";

//State And actions
import {
  ActivateMemberAsync,
  DeactivateMemberAsync,
  SearchMembersAsync,
  setMemberCriteriaAsync,
  SetMembersCriteria,
} from "../../actions/membersActions";
import {PrepareMetaDataAsync} from "../../actions/common";
import Authorized from "../../components/Authorized";
import {
  Admin,
  CustomerServiceManager,
  OpsManager,
  OpsSupervisor,
} from "../../models/jobRole";
import {allRoles} from "../../models/allRoles";
import Select from "react-select";
import LookupModel from "../../models/lookupModel";
import SelectInput from "../components/SelectInput";

type modalNames =
  | "none"
  | "create"
  | "update"
  | "changePassword"
  | "changeRole"
  | "assignStation";

const Screen = () => {
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.members
  );
  const pagedMembers = useSelector(
    (state: AppStateModel) => state.PagedMembers
  );
  const searchMembersCriteria = useSelector(
    (state: AppStateModel) => state.SearchMembersCriteria
  );
  const [
    selectedMemberForEdit,
    setSelectedMemberForEdit,
  ] = useState<MemberModel | null>(null);

  const [modalOn, setModalOn] = useState<modalNames>("none");
  const dispatch = useDispatch();
  React.useEffect(() => {
    batch(() => {
      dispatch(PrepareMetaDataAsync());
      dispatch(SearchMembersAsync());
    })
  }, [dispatch]);
  const handleKeyPress = (event: any, callback: () => any) => {
    if (event.key === "Enter") {
      callback();
    }
  };

  const MemberContent = useSelector(
    (state: AppStateModel) => state.AppContent.members
  );
  const memberContent = MemberContent.newMember;

  const jobRole = useSelector((state: AppStateModel) => state.Profile).jobRole;
  const [selectedStatuses, setSelectedStatuses] = React.useState<LookupModel[] | undefined>([]);

  const renderModal = (val: modalNames) => {
    switch (val) {
      case "create":
        return (
          <CreateModal
            visible={true}
            onHide={() => {
              dispatch(SearchMembersAsync());
              setModalOn("none");
            }}
          />
        );
      case "changePassword":
        return selectedMemberForEdit != null ? (
          <ChangePasswordModal
            member={selectedMemberForEdit}
            visible={true}
            onHide={() => {
              setModalOn("none");
              setSelectedMemberForEdit(null);
              dispatch(SearchMembersAsync());
            }}
          />
        ) : (
          <></>
        );

      case "changeRole":
        return selectedMemberForEdit != null ? (
          <ChangeRoleModal
            member={selectedMemberForEdit}
            visible={true}
            onHide={() => {
              setModalOn("none");
              setSelectedMemberForEdit(null);
              dispatch(SearchMembersAsync());
            }}
          />
        ) : (
          <></>
        );

      case "assignStation":
        return selectedMemberForEdit != null ? (
          <AssignStationModal
            member={selectedMemberForEdit}
            visible={true}
            onHide={() => {
              setModalOn("none");
              setSelectedMemberForEdit(null);
              dispatch(SearchMembersAsync());
            }}
          />
        ) : (
          <></>
        );

      case "update":
        return selectedMemberForEdit != null ? (
          <UpdateModal
            member={selectedMemberForEdit}
            visible={true}
            onHide={() => {
              setModalOn("none");
              setSelectedMemberForEdit(null);
              dispatch(SearchMembersAsync());
            }}
          />
        ) : (
          <></>
        );
    }
  };

  const onClear = () => {
    dispatch(
      SetMembersCriteria({
        pageNumber: 1,
        pageSize: 20,
        emailContains: undefined,
        lookup: false,
        nameContains: undefined,
        phoneContains: undefined,
        deactivated: undefined,
        jobRole: undefined,
      })
    );
    setSelectedStatuses(undefined)
    dispatch(SearchMembersAsync());
  };

  const getRoleNumber = (role?: string) => {
    return allRoles.find((x) => x.label == role)?.value;
  };

  const rolesButtons = (row: MemberModel) => {
    return (
      <>
        <Button
          onClick={() => {
            setSelectedMemberForEdit(row);
            setModalOn("update");
          }}
          className="icon-button main-button"
        >
          {content.update}
        </Button>
        <Button
          onClick={() => {
            setSelectedMemberForEdit(row);
            setModalOn("changePassword");
          }}
          className="icon-button main-button"
        >
          Change Password
        </Button>
        <Button
          onClick={() => {
            setSelectedMemberForEdit(row);
            setModalOn("changeRole");
          }}
          className="icon-button main-button"
        >
          Change Role
        </Button>
        <Button
          onClick={() => {
            setSelectedMemberForEdit(row);
            setModalOn("assignStation");
          }}
          className="icon-button main-button"
        >
          Assign station
        </Button>
      </>
    );
  };

  const deActivateButton = (row: MemberModel) => {
    return (
      <Button
        onClick={() =>
          dispatch(
            DeactivateMemberAsync(row.id!, false, () =>
              dispatch(SearchMembersAsync())
            )
          )
        }
        style={{ minWidth: 90 }}
        className="icon-button main-button"
        variant="danger"
      >
        {"Deactivate"}
      </Button>
    );
  };

  const activateButton = (row: MemberModel) => {
    return (
      <Button
        onClick={() =>
          dispatch(
            ActivateMemberAsync(row.id!, false, () =>
              dispatch(SearchMembersAsync())
            )
          )
        }
        className="icon-button main-button"
        variant="success"
        style={{ minWidth: 90 }}
      >
        {"Activate"}
      </Button>
    );
  };

  const stations = useSelector((state: AppStateModel) => state.Meta?.stations);
  const meta = useSelector((state: AppStateModel) => state.Meta);

  const getStationName = (stationId: string) => {
    if (stationId != null)
      return stations?.find((x) => x.value == stationId)?.label;
    else
      return "All"
  };

  return (
    <div style={{ padding: "1vw" }}>
      {renderModal(modalOn)}
      <Card>
        <Card.Header>
          <Row>
            <Col lg={2} className="px-md-1">
              <TextInput
                placeholder={content.nameContainsPlaceholder}
                value={searchMembersCriteria?.nameContains ?? ""}
                onChange={(val) =>
                  dispatch(
                    SetMembersCriteria({
                      nameContains: val,
                    })
                  )
                }
                onKeyPress={(e: any) =>
                  handleKeyPress(e, () => {
                    dispatch(
                      SetMembersCriteria({
                        ...searchMembersCriteria,
                        pageNumber: 1,
                        pageSize: 20,
                      })
                    );
                    dispatch(SearchMembersAsync());
                  })
                }
              />
            </Col>
            <Col lg={2} className="px-md-1">
              <TextInput
                placeholder={content.mobileContainsPlaceholder}
                value={searchMembersCriteria?.phoneContains ?? ""}
                onChange={(val) =>
                  dispatch(
                    SetMembersCriteria({
                      phoneContains: val,
                    })
                  )
                }
                onKeyPress={(e: any) =>
                  handleKeyPress(e, () => {
                    dispatch(
                      SetMembersCriteria({
                        ...searchMembersCriteria,
                        pageNumber: 1,
                        pageSize: 20,
                      })
                    );
                    dispatch(SearchMembersAsync());
                  })
                }
              />
            </Col>
            <Col lg={2} className="px-md-1">
              <TextInput
                placeholder={content.emailHeader}
                value={searchMembersCriteria?.emailContains ?? ""}
                onChange={(val) =>
                  dispatch(
                    SetMembersCriteria({
                      emailContains: val,
                    })
                  )
                }
                onKeyPress={(e: any) =>
                  handleKeyPress(e, () => {
                    dispatch(
                      SetMembersCriteria({
                        ...searchMembersCriteria,
                        pageNumber: 1,
                        pageSize: 20,
                      })
                    );
                    dispatch(SearchMembersAsync());
                  })
                }
              />
            </Col>
            <Col lg={0.5} className="px-md-1">
              <Button
                onClick={() => {
                  dispatch(
                    SetMembersCriteria({
                      ...searchMembersCriteria,
                      pageNumber: 1,
                      pageSize: 20,
                    })
                  );
                  dispatch(SearchMembersAsync());
                }}
                variant="primary"
                // className="icon-button"
              >
                {content.searchButtonPlaceholder}
              </Button>
            </Col>

            <Col lg={0.5} className="px-md-1">
              <Button
                variant="danger"
                onClick={() => {
                  onClear();
                }}
              >
                Clear
              </Button>
            </Col>

            <Col lg={2}>
              <Row>
                <Col lg={4}/>
                <Col lg={4}/>
              </Row>
            </Col>
            <Col>
              <Authorized
                allowedRoles={[
                  Admin.label,
                  OpsManager.label,
                  OpsSupervisor.label,
                  CustomerServiceManager.label,
                ]}
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    aria-label={content.newButton}
                    onClick={() => setModalOn("create")}
                    variant="light"
                  >
                    {content.newButton}
                  </Button>
                </div>
              </Authorized>
            </Col>
          </Row>
          <Row>
            <Col lg={2} className="px-md-4">
              <SelectInput
                value={searchMembersCriteria?.jobRole?.toString()}
                options={
                  meta?.jobRoles ?? []
                }
                placeholder={memberContent.jobRole.placeHolder}
                invalidMessage={memberContent.jobRole.requiredMessage}
                // required={true}
                onChange={(val) => {
                  dispatch(
                    setMemberCriteriaAsync({
                      ...searchMembersCriteria,
                      jobRole: val,
                    })
                  );
                }}
              />

            </Col>
            <Col lg={2} className="px-md-4">
              <Select
                isMulti
                value={selectedStatuses}
                key={`selectedStatuses${selectedStatuses}`}
                className="basic-multi-select"
                escapeClearsValue={true}
                options={[{
                  label: "Activated",
                  value: "false"
                }, { label: "DeActivated", value: "true" }]}
                placeholder={"Status"}
                onChange={(selectedOptions) => {
                  let status: string = "";
                  let statusModel: LookupModel[] = [];
                  if (Array.isArray(selectedOptions)) {
                    selectedOptions?.forEach((element: any) => {
                      status = element.value;

                      statusModel.push({
                        value: element.value,
                        label: element.label,
                      });
                    });
                  }
                  // else {
                  //     //@ts-ignore
                  //     statuses.push(selectedOptions?.value);
                  //     statusModel.push({value:selectedOptions?.value!,label:selectedOptions?.label!})
                  //
                  // }
                  setSelectedStatuses(statusModel);
                  dispatch(
                    setMemberCriteriaAsync({
                      ...searchMembersCriteria,
                      deactivated: status ? JSON.parse(status) : undefined,
                    })
                  );
                }}
              />
            </Col>
            <Col>
              <Button size={"lg"} onClick={() => dispatch(
                setMemberCriteriaAsync({
                  ...searchMembersCriteria,
                  exportToExcel: true,
                })
              )}>Export to Excel</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <BTable striped responsive bordered hover size="sm">
            <thead>
            <tr>
              <th>{content.nameHeader}</th>
              <th>{content.emailHeader}</th>
              <th>{content.mobileHeader}</th>

              <th>{content.jobRoleHeader}</th>
              <th>{content.Station}</th>
              <th>{"Personal Phone"}</th>
              <th>{"Id Number"}</th>

              <Authorized
                allowedRoles={[
                  Admin.label,
                  OpsManager.label,
                  OpsSupervisor.label,
                  CustomerServiceManager.label,
                ]}
              >
                {" "}
                <th>{content.actionsHeader}</th>
              </Authorized>
              <Authorized
                allowedRoles={[
                  Admin.label,
                  OpsManager.label,
                  OpsSupervisor.label,
                  CustomerServiceManager.label,
                ]}
              >
                <th/>
              </Authorized>
            </tr>
            </thead>

            <tbody>
            {pagedMembers?.matches?.map((row: MemberModel, i: any): any => (
              <tr key={i}>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                <td>{row.jobRole}</td>
                <td>{getStationName(row.stationId ?? "")}</td>
                <td>{row.personalPhone}</td>
                <td>{row.identificationNumber} </td>

                <Authorized
                  allowedRoles={[
                    Admin.label,
                    OpsManager.label,
                    OpsSupervisor.label,
                    CustomerServiceManager.label,
                  ]}
                >
                  <td>
                    {getRoleNumber(row.jobRole)! > getRoleNumber(jobRole)! &&
                      rolesButtons(row)}
                  </td>
                </Authorized>
                <Authorized
                  allowedRoles={[
                    Admin.label,
                    OpsManager.label,
                    OpsSupervisor.label,
                    CustomerServiceManager.label,
                  ]}
                >
                  <td>

                    {getRoleNumber(row.jobRole)! > getRoleNumber(jobRole)! &&
                      row.active &&
                      deActivateButton(row)}

                    {getRoleNumber(row.jobRole)! > getRoleNumber(jobRole)! &&
                      !row.active &&
                      activateButton(row)}
                  </td>
                </Authorized>
              </tr>
            ))}
            </tbody>
          </BTable>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pager
              currentPageNumber={searchMembersCriteria?.pageNumber!}
              numberOfPages={Math.ceil(
                pagedMembers?.total! / searchMembersCriteria?.pageSize!
              )}
              onChange={(page: number) => {
                dispatch(
                  SetMembersCriteria({
                    pageNumber: page,
                  })
                );
                dispatch(SearchMembersAsync());
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default React.memo(Screen);

import React, {useEffect, useState} from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AppStateModel from "../../../models/appStateModel";
import {useSelector} from "react-redux";
import ShipmentList from "./shipmentsList";
import ShipmentModel from "../../../models/shipmentModel";
import {SearchShipment} from "../../../api/data";
import useSound from "use-sound";
//@ts-ignore
import SuccessBeep from '../../../res/beepsuccess.mp3';
//@ts-ignore
import FailBee from '../../../res/beepfail.mp3';
//@ts-ignore
import MultiP from '../../../res/multip.mp3';

interface IProps {
    allowedStatuses?: string[];
    setShipments: (ids: ShipmentModel[]) => any
    validate?: (props: ShipmentModel) => boolean | Promise<boolean>
    customValidateError?: string
    disabled?: boolean
    destinationStation?: { label: string, value: string }
}

const Component = (props: IProps) => {
    const content = useSelector(
      (state: AppStateModel) => state.AppContent.shipments.receive
    );
    const textInput = React.useRef<any>(null);
    const [pieceOf, setPieceOf] = useState(1)
    const [lastShipment, setLastShipment] = useState("")
    const [shipments, setShipments] = React.useState<ShipmentModel[]>([]);
    const [scannedId, setScannedId] = React.useState("");
    const [error, setError] = useState<string>("")
    const [loading, setLoading] = React.useState(false);
    const [playFail] = useSound(FailBee);
    const [playSuccess] = useSound(SuccessBeep);
    const [playMultiP] = useSound(MultiP);

    React.useEffect(() => {
        textInput.current!.focus();
    }, [])

    const resetForm = () => {
        setScannedId("");
        setLastShipment("")
        setPieceOf(1)
        setLoading(false);
    }
    const onScan = async () => {
        setLastShipment(scannedId)
        setError("")
        setLoading(true);
        let res = await SearchShipment({
            numberContains: scannedId,
            bypassStationFilter: true
        }, false);
        const shipment: ShipmentModel = res.data?.matches?.[0];

        if (shipment) {
            console.log("succeeded")
            setScannedId("");


            if (
              !(shipment &&
                shipment.number == scannedId &&
                !shipments.find((s) => s.id == shipment.id))
            ) {
                console.log("not found")

                setError("Shipment not found")
                playFail();
                resetForm()
                textInput.current!.focus();
                return;
            }
            if (
              !(shipment &&
                (!props.allowedStatuses ||
                  (props.allowedStatuses.find((a) => a == shipment.status))
                ))) {
                console.log("Invalid Status")
                resetForm()
                setError(`Invalid Status ${shipment.statusLabel}`)
                playFail();

                textInput.current!.focus();
                return;
            }


            if (!(!Boolean(props.validate) || await props?.validate?.(shipment))) {
                setError(props.customValidateError ?? "Failed validation")
                playFail();
                setScannedId("");
                setLastShipment("")
                setPieceOf(1)
                setLoading(false);
                textInput.current!.focus();
                return;
            }
            const num = shipment.commodity.numberOfPieces

            if (lastShipment == scannedId || lastShipment == '') {
                if (num && Number(num) - 1 >= pieceOf) {
                    if (pieceOf == 1) {
                        playMultiP()
                    }
                    playSuccess()
                    setPieceOf((e) => e + 1)
                    setError(`You need to scan all ${num} pieces,  ${pieceOf} of ${num} scanned`)
                    setScannedId("");
                    setLoading(false);
                    textInput.current!.focus();
                    return
                }
            }
            shipments.push(shipment);
            props.setShipments(shipments);
            setLastShipment("")
            setPieceOf(1)
            setScannedId("");
            playSuccess();
            playSuccess();
            textInput.current!.focus();
        } else {
            resetForm()
            setError("Something went wrong")
        }

        setScannedId("");
        setLoading(false);
        textInput.current!.focus();
    };

    const OnRemove = (id: string) => {
        let newList: ShipmentModel[] = [];
        shipments.forEach(s => {
            if (s.id != id) newList.push(s);
        });
        setShipments(newList);

        props.setShipments(newList);
    }

    const onKeyPress = (e: any) => {
        if (props.disabled) {
            return
        }
        if (e.keyCode === 13) {
            e.preventDefault();
            onScan().then();

        }
    };
    useEffect(() => {
        textInput.current!.focus()

    })


    return (
      <>
          <InputGroup className="mb-3">
              <Form.Control
                ref={textInput}
                placeholder={content.scanPlaceholder}
                value={scannedId}
                onChange={(e: any) => setScannedId(e.target.value)}
                disabled={loading}
                onKeyDown={onKeyPress}
                autoFocus={true}
              />

              <InputGroup.Append>
                  <Button disabled={scannedId === "" || props.disabled}
                          onClick={onScan}>
                      +
                  </Button>
              </InputGroup.Append>
          </InputGroup>
          <div>
              <p className={"font-weight-bold text-danger"}>{error}</p>
          </div>
          <ShipmentList isLoading={loading} shipments={shipments}
                        onRemovePressed={OnRemove}/>
      </>
    );
};

export default React.memo(Component);

import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import CreateStation from "./components/createCityTiers";
import CityTiersTable from "./components/cityTiersTable";
import AppStateModel from "../../models/appStateModel";
import CreateCityTiers from "./components/createCityTiers";
import {PrepareMetaDataAsync} from "../../actions/common";
import {SearchStationsAsync} from "../../actions/stationsActions";
import {CityTiersAsync} from "../../actions/cityTiersAction";

 const CityTiers = () => {
  const dispatch = useDispatch()

  const data = useSelector((state: AppStateModel) => state.CityTiers);
  const [modalOn, setModalOn] = useState<modalNames>("none")
  const content = useSelector(
    (state: AppStateModel) => state.AppContent.stations
  );
  type modalNames =
    | "create"
    | "none";
  const renderModal = (val: modalNames) => {
    switch (val) {
      case "create":
        return (
          <CreateCityTiers
            submitButton={"Submit"}
            visible={true}
            title={"Create City Tier "}
            onHide={() => {
              dispatch(CityTiersAsync());
              setModalOn("none");
            }}
          />
        );
    }
  }
  useEffect(() => {
    // dispatch(PrepareMetaDataAsync());
    dispatch(CityTiersAsync())
  }, []);

  return (
    <div style={{ padding: "1vw" }}>
      {renderModal(modalOn)}
      <Card>
        <Card.Header>

          <Row className="px-3 p-2 d-flex justify-content-between">
            <Button onClick={() => setModalOn("create")}>Create</Button>

          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form.Group as={Col}>
              <Row/>
            </Form.Group>
          </Row>
          <Row className={"px-4"}>
            <Col md={5}>
              <Row> <CityTiersTable data={data.matches}/>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </div>
  )
}

export default CityTiers

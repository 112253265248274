import {SearchCities} from "../api/data";
import PagedModel from "../models/pagedModel";
import {SET_CITIES, SET_CITIES_CRITERIA} from "../store/types";
import {SetAlert, setLoading} from "./common";
import {ICityModel, ISearchCitiesModel} from "../models/cities";

export const SearchCitiesAsync = (isService?: boolean, onClear?: boolean) => {
    return async (dispatch: any, getState: any) => {
        const state = getState()
        dispatch(setLoading(true));
        let res;
        if (onClear) {
            res = await SearchCities();
        } else res = await SearchCities({...state.SearchCitiesCriteria, isServiced: isService});
        if (res.succeeded) {
            dispatch(searchCities(res.data))
        } else {
            dispatch(SetAlert({
                visible: true,
                data: res.data,
                title: "Error",
                kind: "error",
            }));
        }
        dispatch(setLoading(false));
    };
};


export const searchCities = (searchCitiesResult: PagedModel<ICityModel>) => {
    return {
        type: SET_CITIES,
        payload: {
            searchCitiesResult,
        },
    };
};

export const setCitiesCriteria = (filters: ISearchCitiesModel) => {
    return {
        type: SET_CITIES_CRITERIA,
        payload: {
            filters,
        },
    };
};

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import AppStateModel from "../../../models/appStateModel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/TextInput";
import IUpdateMemberModel from "../../../models/members/updateMemberModel";
import Button from "react-bootstrap/Button";
import {
  UpdateMemberAsync,
  UpdateRoleAsync,
} from "../../../actions/membersActions";
import MemberModel from "../../../models/members/memberModel";
import SelectInput from "../../components/SelectInput";
import IUpdateMemberRoleModel from "../../../models/members/updateMemberRoleModel";
import { allRoles } from "../../../models/allRoles";

interface IProps {
  member: MemberModel;
  visible: boolean;
  onHide: () => any;
}
const Component = (props: IProps) => {
  const memberContent = useSelector(
    (state: AppStateModel) => state.AppContent.members
  );
  const content = memberContent.newMember;
  const Meta = useSelector((state: AppStateModel) => state.Meta);

  const [member, setMember] = useState<IUpdateMemberRoleModel>({
    accountId: props.member.id!,
    jobRole: Meta?.jobRoles?.find((x) => x.label == props.member.jobRole)
      ?.value!,
  });
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(UpdateRoleAsync(member, () => props.onHide()));
  };

  const jobRole = useSelector((state: AppStateModel) => state.Profile).jobRole;

  const getRoleNumber = () => {
    return allRoles.find((x) => x.label == jobRole)?.value;
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => props.onHide()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {memberContent.changeRole}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <SelectInput
                value={member.jobRole}
                options={
                  Meta?.jobRoles?.filter(
                    (x) =>
                      Number(x.value) > getRoleNumber()! && x.value != "301"
                  ) ?? []
                }
                placeholder={content.jobRole.placeHolder}
                invalidMessage={content.jobRole.requiredMessage}
                // isInvalid={
                //   validationErrors.find((e) => e === "Job Role") ? true : false
                // }
                // required={true}
                onChange={(val) => setMember({ ...member, jobRole: val })}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col></Col>

            <Col>
              <div
                style={{
                  paddingTop: 30,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button size="lg" onClick={onSubmit} variant="light">
                  {content.submit}
                </Button>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Component;

import React, {FunctionComponent} from "react";
import {Line} from "react-chartjs-2";
import {chartExample1} from "../dashboard/variables";

interface IProps {
    graphData: any
}

const Graph: FunctionComponent<IProps> = ({graphData}) => {
    const extractData = (data: any[]) => {

        let numbers = data.map(d => d.count);

        return (canvas: any) => {
            let ctx = canvas.getContext("2d");

            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

            return {
                labels: [
                    "JAN",
                    "FEB",
                    "MAR",
                    "APR",
                    "MAY",
                    "JUN",
                    "JUL",
                    "AUG",
                    "SEP",
                    "OCT",
                    "NOV",
                    "DEC",
                ],
                datasets: [
                    {
                        label: "Actions Count",
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: numbers,
                    },
                ],
            };
        }
    }

    return (<div className="chart-area">
        {graphData &&
        <Line
            height={200}
            data={extractData(graphData)}
            options={chartExample1.options}
        />}

    </div>)
}
export default React.memo(Graph)
